import { renderDate, toFixedHours, useToggleState } from '@app/utils';
import { Switch } from '@app/components/core';
import { Chapter, ReportEntity, WorkDayProduct, SprintProductEntity, SprintProductStatus } from '@data';
import React, { Dispatch, ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Loader } from '@ui';
import { format } from 'date-fns';
import { Roles } from '@app/utils/types';
import { useStore } from '@data/storages';
import Button from '@mui/lab/LoadingButton';
import Add from '@mui/icons-material/Add';
import Storage from '@mui/icons-material/Storage';
import IconButton from '@mui/material/IconButton';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import s from './styles.module.scss';
import { ProductReportItem } from './product-report-item';

export interface DetailedChaptersProps extends React.HTMLAttributes<HTMLDivElement> {
  hideWeekend: boolean;
  setWeekend: Dispatch<boolean>;
  setChapters: Dispatch<string[] | undefined>;
  chapters?: Chapter[];
  detailedReports?: WorkDayProduct[] | null;
  showAddForm?: (workDay: string) => void;
  showEditForm?: (report: ReportEntity) => void;
  showDeleteForm?: (report: ReportEntity) => void;
  selectedChapters?: string[];
  sprintProduct?: SprintProductEntity;
  isLoading?: boolean;
}
export const DetailedChapters = ({
  hideWeekend,
  setWeekend,
  detailedReports,
  chapters,
  showAddForm,
  showEditForm,
  showDeleteForm,
  setChapters,
  sprintProduct,
  selectedChapters,
  isLoading,
}: DetailedChaptersProps): ReactElement => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { organization } = useStore();
  const chapterOptions = [
    { value: undefined, label: t('allChapters') },
    ...(chapters || []).map((x) => ({ value: x.role, label: x.text })),
  ];
  const handleChapterChange = (option) => {
    setChapters(option.value && [option.value]);
  };
  const toggleWeekend = () => {
    setWeekend(!hideWeekend);
  };
  const [expandAll, toggleExpandAll] = useToggleState(false);
  const filterValue = useMemo(
    () => chapterOptions.find((x) => x.value && selectedChapters?.includes(x.value)) ?? chapterOptions[0],
    [chapterOptions, selectedChapters],
  );
  const filters = (
    <div className={s.tabHeader}>
      <div className={s.filterItems}>
        <Autocomplete
          className={s.filterItemsSelect}
          options={chapterOptions}
          onChange={(_e, value) => handleChapterChange(value)}
          getOptionLabel={(option) => option?.label}
          value={filterValue}
          fullWidth
          renderInput={(params) => <TextField {...params} name="chapter" label={t('filterByChapter')} />}
        />
      </div>
      <div className={s.filterItems}>
        <Switch value={expandAll} onChange={toggleExpandAll} text={t('titles.expandAll')} />
        {organization?.role !== Roles.client && (
          <Switch value={hideWeekend} onChange={toggleWeekend} text={t('titles.hideWeekend')} />
        )}
      </div>
    </div>
  );
  if (isLoading) {
    return (
      <div className={s.emptyWrapper}>
        <Loader absolute={false} />
      </div>
    );
  }

  if (detailedReports && !detailedReports.some((x) => x.totalMinutes > 0)) {
    const handleReportCreate = showAddForm ? () => showAddForm(format(new Date(), 'yyyy-MM-dd')) : undefined;

    return (
      <>
        {(filterValue || detailedReports.some((x) => x.totalMinutes > 0)) && filters}
        <div className={s.emptyWrapper}>
          {filterValue?.value ? (
            <>
              <Typography>{t('noEntriesFound')}</Typography>
              <Button
                color="primary"
                variant="outlined"
                className={s.emptyButton}
                onClick={() => setChapters(undefined)}
              >
                {t('clearFilter')}
              </Button>
            </>
          ) : (
            <>
              {organization?.role === Roles.client ? (
                <div className={s.emptyWrapper}>
                  <div className={s.iconBox}>
                    <Storage />
                  </div>

                  <Typography>{t('noTimeRegistrations')}</Typography>
                </div>
              ) : (
                <Button variant="outlined" startIcon={<Add />} className={s.emptyButton} onClick={handleReportCreate}>
                  {t('createReport')}
                </Button>
              )}
            </>
          )}
        </div>
      </>
    );
  }
  return (
    <>
      {detailedReports?.length && filters}
      {detailedReports
        ?.filter((x) => organization?.role !== Roles.client || x.totalMinutes > 0)
        .map((item) => (
          <div className={s.workDay} key={item.date}>
            <div className={s.workDayHeader}>
              <Typography
                variant="h6"
                fontWeight={theme.typography.fontWeightBold}
                color={item.totalMinutes > 0 ? theme.palette.text : theme.palette.error[500]}
                className={s.workDayHeaderDate}
              >
                {renderDate(item.date)}
              </Typography>
              <div className={s.workDayHeaderInfo}>
                <Typography
                  fontWeight={theme.typography.fontWeightBold}
                  color={item.totalMinutes > 0 ? theme.palette.text : theme.palette.error[500]}
                  className={s.workDayHeaderDate}
                >
                  {t('hours', { amount: toFixedHours(item.totalMinutes / 60) })}
                </Typography>
                {sprintProduct?.status !== SprintProductStatus.closed && organization?.role !== Roles.client && (
                  <IconButton
                    onClick={showAddForm ? () => showAddForm(item.date) : undefined}
                    className={s.workDayHeaderInfoButton}
                  >
                    <Add />
                  </IconButton>
                )}
              </div>
            </div>
            <div>
              {item.totalMinutes > 0 &&
                item.chapters
                  .filter((x) => x.totalMinutes > 0)
                  .map((chapter) => (
                    <ProductReportItem
                      key={`${expandAll}-${chapter.name}`}
                      showDeleteForm={showDeleteForm}
                      showEditForm={showEditForm}
                      expandAll={expandAll}
                      chapter={chapter}
                      chapters={chapters}
                    />
                  ))}
            </div>
          </div>
        ))}
    </>
  );
};
