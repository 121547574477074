import React, { ReactElement } from 'react';
import { OrganizationEntity, UserEntity } from '@data';
import Box from '@mui/material/Box';
import { Roles } from '@app/utils/types';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import { ClientSidebar } from './sidebar/client-sidebar';
import Sidebar from './sidebar';

const StyledMainContainer = styled(Box)`
  flex-grow: 1;
  padding: ${({ theme }) => theme.spacing(3)};
`;

const StyledContainer = styled(Box)`
  display: flex;
  background-color: ${({ theme }) => theme.palette.background.default};
`;

export interface LayoutProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  user: UserEntity;
  organizations?: OrganizationEntity[];
}

const Layout = ({ children, user, organizations }: LayoutProps): ReactElement => {
  const isClientView = organizations?.[0].role === Roles.client;

  return (
    <StyledContainer>
      {isClientView ? (
        <>
          <ClientSidebar user={user} />
          <StyledMainContainer component="main">
            <Toolbar />
            {children}
          </StyledMainContainer>
        </>
      ) : (
        <>
          <Sidebar user={user} />
          <StyledMainContainer component="main">
            <Toolbar
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            />
            {children}
          </StyledMainContainer>
        </>
      )}
    </StyledContainer>
  );
};

export default Layout;
