import { addDays } from 'date-fns';
import {
  UseMutationResult,
  useQueryClient,
  useMutation,
  useInfiniteQuery,
  UseInfiniteQueryResult,
  InfiniteData,
  useQuery,
  UseQueryResult,
} from 'react-query';

import * as api from '../api';
import * as queryKeys from '../constants/keys';

import type {
  IError,
  ListResponse,
  MembershipEntity,
  OrganizationEntity,
  PmMembersInfiniteData,
  PmMembersParams,
  PmParams,
  SprintEntity,
  UserEntity,
  MembershipStatus,
} from '../utils/types';

export const useMembership = (membershipId: string): UseQueryResult<MembershipEntity, IError> =>
  useQuery([queryKeys.PM_MEMBERS, membershipId], () => api.fetchMembership(membershipId));

export const useRemoveMembership = (
  organizationId: string,
): UseMutationResult<void, IError, string | undefined, unknown> => {
  const queryClient = useQueryClient();
  return useMutation((membershipId) => api.removeMembership(membershipId), {
    onMutate: async (membershipId: string) => {
      const previousOrg = queryClient.getQueryData([queryKeys.ORGANIZATION, organizationId]) as OrganizationEntity;

      queryClient.setQueryData([queryKeys.ORGANIZATION, organizationId], (old: OrganizationEntity) => ({
        ...old,
        memberships: old.memberships.filter((x) => x.id !== membershipId),
      }));

      return { previousOrg };
    },
    onError: (_, __, context) => {
      queryClient.setQueryData([queryKeys.ORGANIZATION, organizationId], context?.previousOrg);
    },
  });
};

export const useInfiniteListPmMembers = (
  organizationId: string,
  params: PmMembersParams,
  enabled: boolean,
  sprints?: SprintEntity[],
): UseInfiniteQueryResult<PmMembersInfiniteData, IError> =>
  useInfiniteQuery(
    [queryKeys.PM_MEMBERS_INFINITE, { organizationId, params }],
    ({ pageParam }) => api.fetchPmMembersInfinite(organizationId, params, { pageParam }),
    {
      keepPreviousData: true,
      enabled,
      getNextPageParam: (lastPage: {
        from: Date | string;
        to: Date | string;
        sprintNumber: number;
        sprintId: string;
      }) => {
        const sprint = sprints?.find((x) => x.endDate === addDays(new Date(lastPage.from), -1).toISOString());
        return {
          from: sprint?.startDate,
          to: sprint?.endDate,
          sprintNumber: sprint?.number,
          sprintId: sprint?.id,
        };
      },
    },
  );
export const useListPmMembers = (
  organizationId: string,
  params: PmMembersParams,
): UseQueryResult<ListResponse<MembershipEntity>, IError> =>
  useQuery([queryKeys.PM_MEMBERS, { organizationId, params }], () => api.fetchPmMembers(organizationId, params));

export const useUpdatePmMembers = (
  organizationId: string,
  params: PmMembersParams,
): UseMutationResult<UserEntity, IError, { userId: string; activeStatus: MembershipStatus }, unknown> => {
  const queryClient = useQueryClient();
  return useMutation(({ userId, activeStatus }) => api.updateUser(userId, activeStatus), {
    onMutate: async ({ userId }) => {
      const previousUsers = queryClient.getQueryData([
        queryKeys.PM_MEMBERS,
        { organizationId, params },
      ]) as ListResponse<MembershipEntity>;

      queryClient.setQueryData(
        [queryKeys.PM_MEMBERS, { organizationId, params }],
        (old: ListResponse<MembershipEntity>) => ({
          ...old,
          items: old.items.filter((u) => u.id !== userId),
        }),
      );

      return { previousUsers };
    },

    onError: (_, __, context) => {
      queryClient.setQueryData(queryKeys.ORGANIZATIONS, context?.previousUsers);
    },
  });
};

const updateMembershipPages = (
  members: InfiniteData<PmMembersInfiniteData>,
  values: Partial<MembershipEntity>,
  sprintId?: string,
) =>
  members.pages.map((page) =>
    page.sprintId === sprintId
      ? {
          ...page,
          items: page.items.map((item) => (item.id === values.id ? { ...item, ...values } : item)),
        }
      : page,
  );

export const useSendMembershipReminder = (
  organizationId?: string,
  params?: PmParams,
): UseMutationResult<void, IError, Partial<MembershipEntity>, unknown> => {
  const queryClient = useQueryClient();
  const queryKey = [queryKeys.PM_MEMBERS_INFINITE, { organizationId, params }];
  return useMutation((values) => api.sendMembershipReminder(values.id), {
    onMutate:
      params && organizationId
        ? async (values: Partial<MembershipEntity>) => {
            const previousPmMembers = queryClient.getQueryData(queryKey) as InfiniteData<PmMembersInfiniteData>;

            queryClient.setQueryData(queryKey, (members: InfiniteData<PmMembersInfiniteData>) => {
              const updatedPages = updateMembershipPages(members, values, params.sprintId);
              return { ...members, pages: updatedPages };
            });

            return { previousPmMembers };
          }
        : undefined,

    onError:
      params && organizationId
        ? (_, __, context) => {
            queryClient.setQueryData(queryKey, context?.previousPmMembers);
          }
        : undefined,
  });
};
