import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import { useForm, Controller } from 'react-hook-form';

export interface SendSprintProps extends React.HTMLAttributes<HTMLDivElement> {
  productName: string;
  updateName: (name: string) => void;
  closePopup: () => void;
}

export const EditProductPopup = ({ productName, closePopup, updateName }: SendSprintProps): ReactElement => {
  const { t } = useTranslation();

  const { control, handleSubmit } = useForm({
    defaultValues: { name: productName },
  });

  const update = ({ name }) => {
    updateName(name);
    closePopup();
  };
  return (
    <Dialog fullWidth open scroll="body" onClose={closePopup} title={t('titles.name')}>
      <DialogTitle>{t('titles.name')}</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(update)}>
          <Grid container margin="20px 0">
            <Controller name="name" control={control} render={({ field }) => <TextField {...field} fullWidth />} />
          </Grid>
          <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
            <Grid>
              <Button fullWidth variant="outlined" onClick={closePopup}>
                {t('actions.cancel')}
              </Button>
            </Grid>
            <Grid>
              <Button fullWidth variant="contained" type="submit">
                {t('actions.save')}
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};
