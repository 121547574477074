import * as Yup from 'yup';
import { endOfDay } from 'date-fns';

export const ReportSchema = Yup.object().shape(
  {
    productId: Yup.string().nullable().required('Product is required'),
    userId: Yup.string().nullable().required('Member is required'),
    today: Yup.string().required('Description is required'),
    hours: Yup.number()
      .nullable()
      .when('customHours', {
        is: (customHours: number) => !customHours,
        then: (schema) => schema.nullable().typeError('Time is required').min(0.1).max(24).required('Time is required'),
        otherwise: (schema) => schema.nullable(),
      }),
    customHours: Yup.number()
      .nullable()
      .when('hours', {
        is: (hours: number) => !hours && hours !== 0,
        then: (schema) => schema.nullable().required('Time is required'),
        otherwise: (schema) => schema.nullable(),
      }),
    workday: Yup.date()
      .typeError('Invalid date')
      .max(endOfDay(new Date()), 'Date cannot be in the future')
      .required('Workday is required'),
    role: Yup.string().required('Role is required'),
  },
  [['hours', 'customHours']],
);

export const OrganizationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
});

export const SendSprintSchema = Yup.object().shape({
  emails: Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.string().email('Must be a valid email'),
      }),
    )
    .min(1, 'At least 1 email address'),
});
