import React, { ReactElement } from 'react';
import { SprintProductEntity } from '@data';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import s from './styles.module.scss';
import ExpandableContent from './expandableContent';

export interface SummaryProps extends React.HTMLAttributes<HTMLDivElement> {
  sprintProduct: SprintProductEntity | undefined;
}

const Summary = ({ sprintProduct }: SummaryProps): ReactElement => {
  const { t } = useTranslation();
  return (
    <>
      <div className={s.clientSummary}>
        <Typography className={s.clientSummaryTitle}>{t('sprintSummary')}</Typography>
        <ExpandableContent content={sprintProduct?.summary} />
      </div>
      {sprintProduct?.comment && (
        <div className={s.clientSummary}>
          <Typography className={s.clientSummaryTitle}>{t('titles.comment')}</Typography>
          <ExpandableContent content={sprintProduct?.comment} />
        </div>
      )}
    </>
  );
};

export default Summary;
