import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { debounce } from '@app/utils/helpers/debounce';
import {
  SprintProductEntity,
  SprintProductParamsType,
  SprintProductStatus,
  useCreateSprintProduct,
  useListSprintProductClients,
  useUpdateSprintProduct,
} from '@data';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Button from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Controller, useForm } from 'react-hook-form';
import Box from '@mui/material/Box';
import { ChatGPTIcon } from '@ui';
import { useAISummary } from '@app/utils/hooks';
import ExpandableContent from './expandableContent';
import s from './styles.module.scss';

export interface SummaryProps extends React.HTMLAttributes<HTMLDivElement> {
  sprintProduct: SprintProductEntity | undefined;
}

const Summary = ({ sprintProduct }: SummaryProps): ReactElement => {
  const { productId, sprintId, organizationId } = useParams<SprintProductParamsType>();
  const { t } = useTranslation();
  const [summary, setSummary] = useState<string | undefined>(sprintProduct?.summary);

  const { mutateAsync: createSprintProduct, isLoading: isCreateLoading } = useCreateSprintProduct(
    organizationId,
    {},
    { sprintId, productId },
  );
  const { mutate: updateSprintProduct } = useUpdateSprintProduct(organizationId, {}, { sprintId, productId });
  const { data: sprintProductClients } = useListSprintProductClients(
    sprintProduct && !sprintProduct.mutation ? sprintProduct.id : undefined,
  );

  const { aiSummary, generateAISummary, isAISummaryGenerating } = useAISummary();

  const update = (values: Partial<SprintProductEntity>) => {
    updateSprintProduct({ ...sprintProduct, ...values });
  };

  const debounceUpdate = useMemo(() => debounce(update, 500), [sprintProduct]);

  const initialValues = useMemo(
    () => ({
      summary: sprintProduct?.summary,
    }),
    [sprintProduct],
  );

  const { control, handleSubmit } = useForm({
    defaultValues: initialValues,
  });

  useEffect(() => {
    if (!sprintProduct) {
      createSprintProduct({ productId, sprintId, status: SprintProductStatus.open });
    }
  }, []);

  useEffect(() => {
    if (sprintProduct && summary !== sprintProduct.summary && !isCreateLoading && !isAISummaryGenerating) {
      debounceUpdate({ summary });
    }
  }, [summary, isAISummaryGenerating]);

  useEffect(() => {
    if (aiSummary && isAISummaryGenerating) {
      setSummary(aiSummary);
    }
  }, [aiSummary, isAISummaryGenerating]);

  const handleGenerateAISummary = (sprintProductId?: string) => () =>
    sprintProductId && generateAISummary(sprintProductId);

  return (
    <form onSubmit={handleSubmit(update)}>
      <Button
        variant="outlined"
        key="getSummaryButton"
        className={s.clientSummaryBtn}
        onClick={handleGenerateAISummary(sprintProduct?.id)}
        endIcon={<ChatGPTIcon />}
        disabled={!!sprintProductClients?.items.length || isAISummaryGenerating || isCreateLoading}
      >
        {aiSummary && !isAISummaryGenerating ? t('actions.regenerateSummaryAI') : t('actions.generateSummaryAI')}
      </Button>
      <Box mb={3}>
        <Controller
          name="summary"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              error={!!error}
              helperText={error?.message}
              minRows={4}
              fullWidth
              multiline
              label={t('sprintSummary')}
              disabled={!!sprintProductClients?.items.length}
              value={summary}
              onChange={(e) => setSummary(e.target.value)}
            />
          )}
        />
      </Box>

      {!!sprintProduct?.comment && (
        <div className={s.clientSummary}>
          <Typography className={s.clientSummaryTitle}>{t('titles.comment')}</Typography>
          <ExpandableContent content={sprintProduct?.comment} />
        </div>
      )}
    </form>
  );
};

export default Summary;
