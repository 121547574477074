import { OrganizationsEmptyComponent } from '@app/components/core/empty';
import { clientsReportingPath, sprintReportingPath } from '@app/constants/url';
import { Roles } from '@app/utils/types';
import { UserEntity } from '@data';
import { useOrganizations } from '@data/hooks/organizations';
import React, { ReactElement } from 'react';
import { Redirect } from 'react-router-dom';

export interface OrganizationsInit extends React.HTMLAttributes<HTMLDivElement> {
  user: UserEntity;
}

export const OrganizationsInit = ({ user }: OrganizationsInit): ReactElement => {
  const { data: organizations } = useOrganizations(user.id);

  if (organizations?.items?.length) {
    // we should do redirect to correct path here
    const [{ id, role }] = organizations.items;
    const redirectTo = role === Roles.client ? clientsReportingPath(id) : sprintReportingPath(id);

    return <Redirect to={redirectTo} />;
  }

  return <OrganizationsEmptyComponent />;
};
