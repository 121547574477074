import { useStore } from '@data/storages';
import {
  InfiniteData,
  QueryClient,
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
  UseQueryOptions,
} from 'react-query';

import * as api from '../api';
import * as queryKeys from '../constants/keys';
import {
  ApproveSprintFormValues,
  ClientProductEntity,
  IError,
  ListResponse,
  MembershipEntity,
  PmParams,
  PmProductsInfiniteData,
  SendSprintFormValues,
  SprintProductEntity,
  SprintProductClientEntity,
  SprintProductStatus,
} from '../utils/types';

const updateProductPages = (products: InfiniteData<PmProductsInfiniteData>, values: Partial<SprintProductEntity>) =>
  products.pages.map((page) =>
    page.sprintId === values.sprintId
      ? {
          ...page,
          items: page.items.map((item) =>
            item.id === values.productId
              ? {
                  ...item,
                  sprintProducts: item.sprintProducts?.length ? [{ ...item.sprintProducts[0], ...values }] : [values],
                }
              : item,
          ),
        }
      : page,
  );

const handleListMutation = (
  queryClient: QueryClient,
  organizationId,
  params: PmParams,
  values: Partial<SprintProductEntity>,
): void => {
  queryClient.setQueryData(
    [queryKeys.PM_PRODUCTS, { organizationId, params }],
    (products: InfiniteData<PmProductsInfiniteData>) => {
      const updatedPages = updateProductPages(products, values);
      return { ...products, pages: updatedPages };
    },
  );
};

const handleDetailMutation = (
  queryClient: QueryClient,
  detail: { sprintId: string; productId: string },
  newSprintProduct: Partial<SprintProductEntity>,
) => {
  const key = [queryKeys.PM_SPRINT_PRODUCTS, detail];
  const cacheData = queryClient.getQueryData(key) as ListResponse<SprintProductEntity>;
  const newObj = Object.assign({}, ...(cacheData?.items ?? []), newSprintProduct);

  queryClient.setQueryData(key, () => ({
    count: 1,
    items: [newObj],
  }));
};

const handleListSuccess = (
  queryClient: QueryClient,
  organizationId,
  params: PmParams,
  values: Partial<SprintProductEntity>,
  data: SprintProductEntity,
) => {
  queryClient.setQueryData(
    [queryKeys.PM_PRODUCTS, { organizationId, params }],
    (products: InfiniteData<PmProductsInfiniteData>) => {
      const updatedPages = updateProductPages(products, { ...values, ...data });
      return { ...products, pages: updatedPages };
    },
  );
};

export const useCreateSprintProduct = (
  organizationId: string,
  params: PmParams,
  detail?: { sprintId: string; productId: string },
): UseMutationResult<SprintProductEntity, IError, Partial<SprintProductEntity>, unknown> => {
  const queryClient = useQueryClient();
  return useMutation((values) => api.createSprintProduct(organizationId, values), {
    onMutate: async (values: Partial<SprintProductEntity>) => {
      const previousPmProducts = queryClient.getQueryData([
        queryKeys.PM_PRODUCTS,
        { organizationId, params },
      ]) as InfiniteData<PmProductsInfiniteData>;

      const previousSprintProducts = queryClient.getQueryData(
        queryKeys.PM_SPRINT_PRODUCTS,
      ) as ListResponse<SprintProductEntity>;

      if (!detail) handleListMutation(queryClient, organizationId, params, values);

      return { previousPmProducts, previousSprintProducts };
    },
    onSuccess: (data, values) => {
      if (detail) {
        queryClient.invalidateQueries([queryKeys.PM_SPRINT_PRODUCTS, detail]);
      } else {
        handleListSuccess(queryClient, organizationId, params, values, data);
      }
    },

    onError: (_, __, context) => {
      queryClient.setQueryData(queryKeys.PM_PRODUCTS, context?.previousPmProducts);
      queryClient.setQueryData(queryKeys.PM_SPRINT_PRODUCTS, context?.previousSprintProducts);
    },
  });
};

export const useUpdateSprintProduct = (
  organizationId: string,
  params: PmParams,
  detail?: { sprintId: string; productId: string },
): UseMutationResult<SprintProductEntity, IError, Partial<SprintProductEntity>, unknown> => {
  const queryClient = useQueryClient();
  return useMutation((values) => api.updateSprintProduct(values), {
    onMutate: async (values: Partial<SprintProductEntity>) => {
      const previousPmProducts = queryClient.getQueryData([
        queryKeys.PM_PRODUCTS,
        { organizationId, params },
      ]) as InfiniteData<PmProductsInfiniteData>;

      const previousSprintProducts = queryClient.getQueryData(
        queryKeys.PM_SPRINT_PRODUCTS,
      ) as ListResponse<SprintProductEntity>;

      if (detail) {
        handleDetailMutation(queryClient, detail, values);
      } else {
        handleListMutation(queryClient, organizationId, params, values);
      }
      return { previousPmProducts, previousSprintProducts };
    },
    onSuccess: (data, values) => {
      if (!detail) handleListSuccess(queryClient, organizationId, params, values, data);
    },

    onError: (_, __, context) => {
      queryClient.setQueryData(queryKeys.PM_PRODUCTS, context?.previousPmProducts);
      queryClient.setQueryData(queryKeys.PM_SPRINT_PRODUCTS, context?.previousSprintProducts);
    },
  });
};

export const useApproveSprintProduct = (): UseMutationResult<
  ClientProductEntity,
  IError,
  ApproveSprintFormValues,
  unknown
> => {
  const queryClient = useQueryClient();
  const { tokenData } = useStore();
  return useMutation((values) => api.approveSprintProduct(values), {
    onMutate: (values: ApproveSprintFormValues) => {
      const detail = { sprintId: values.sprintId, productId: values.productId };

      const previousDetail = queryClient.getQueryData([
        queryKeys.PM_SPRINT_PRODUCTS,
        detail,
      ]) as ListResponse<ClientProductEntity>;

      handleDetailMutation(queryClient, detail, {
        ...previousDetail.items?.[0],
        id: values.sprintProductId,
        status: SprintProductStatus.approved,
        approvedBy: { email: tokenData?.email ?? '' },
        approvedAt: new Date(),
      });

      return { previousDetail };
    },
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.CLIENT_SPRINT_PRODUCTS);
    },
    onError: (_, values, context) => {
      const detail = { sprintId: values.sprintId, productId: values.productId };
      queryClient.setQueryData([queryKeys.PM_SPRINT_PRODUCTS, detail], context?.previousDetail);
    },
  });
};

export const useSendSprintProduct = (): UseMutationResult<
  SprintProductEntity,
  IError,
  SendSprintFormValues,
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation((values) => api.sendSprintProduct(values), {
    onSuccess: (sprintProduct) => {
      queryClient.invalidateQueries([queryKeys.SPRINT_PRODUCT_CLIENTS, sprintProduct.id]);
      queryClient.invalidateQueries([queryKeys.PM_PRODUCTS]);
    },
  });
};

export const useClientSprintProducts = (
  organizationId?: string,
  params?: {
    statuses?: Partial<SprintProductStatus>[];
  },
): UseQueryResult<{ items: SprintProductClientEntity[]; count: number }, IError> =>
  useQuery([queryKeys.CLIENT_SPRINT_PRODUCTS, params], () => api.fetchClientSprintProducts(organizationId, params), {
    enabled: !!organizationId,
    staleTime: 1000,
  });

export const useListSprintProductClients = (
  sprintProductId?: string,
  options?: UseQueryOptions<ListResponse<MembershipEntity>, IError>,
): UseQueryResult<ListResponse<MembershipEntity>, IError> =>
  useQuery([queryKeys.SPRINT_PRODUCT_CLIENTS, sprintProductId], () => api.fetchSprintProductClients(sprintProductId), {
    staleTime: 1000,
    enabled: !!sprintProductId,
    ...options,
  });

export const useRemoveSprintProductClient = (
  sprintProductId?: string,
): UseMutationResult<void, IError, MembershipEntity, void> => {
  const queryClient = useQueryClient();
  return useMutation((client) => api.removeSprintProductClient(sprintProductId, client.id), {
    onMutate: async (client) => {
      queryClient.setQueryData(
        [queryKeys.SPRINT_PRODUCT_CLIENTS, sprintProductId],
        (old: ListResponse<MembershipEntity>) => {
          const newItems = old.items.filter((x) => x.user.email !== client.user.email);
          return { count: old.count - 1, items: newItems };
        },
      );
    },
  });
};

export const useResendSprintProduct = (): UseMutationResult<void, IError, { sprintId: string; productId: string }> =>
  useMutation(({ sprintId, productId }) => api.resendSprintProduct(sprintId, productId), {});
