import { RoleEntity } from '@data';
import { useQuery, UseQueryResult } from 'react-query';

import type { IError, ListResponse } from '../utils/types';
import * as api from '../api';
import * as queryKeys from '../constants/keys';

export const useListRoles = (params: {
  userId: string;
  productId: string;
}): UseQueryResult<ListResponse<RoleEntity>, IError> =>
  useQuery([queryKeys.ROLES, params], () => api.fetchRoles(params), {
    enabled: !!params.productId && !!params.userId,
  });
