import React, { Dispatch, ReactElement, SetStateAction } from 'react';
import cx from 'classnames';
import { v4 as uuid } from 'uuid';
import { Tab, Tabs, TabList, TabPanel, TabsProps } from 'react-tabs';

import s from './styles.module.scss';

interface IProps extends TabsProps {
  children?: React.ReactNode;
  tabs: (string | JSX.Element)[];
  className?: string;
  activeTab: number;
  setActiveTab: Dispatch<SetStateAction<number>>;
  tabClassName?: string;
  tabProps?: any;
  layoutClassName?: string;
}

const CustomTabs = ({
  children,
  tabs,
  className,
  setActiveTab,
  activeTab,
  tabClassName,
  tabProps,
  layoutClassName,
  ...props
}: IProps): ReactElement => (
  <Tabs selectedIndex={activeTab} onSelect={setActiveTab} className={layoutClassName} {...props}>
    <TabList className={cx(s.list, className)}>
      {tabs.map((content, i) => (
        <Tab className={cx(s.tab, tabClassName)} key={`tab_${i + 1}`} {...tabProps}>
          {content}
        </Tab>
      ))}
    </TabList>
    {/* when there are no children - just render empty tabs to prevent warning */}
    {children || tabs.map(() => <Tab className={s.tabHidden} key={uuid()} />)}
  </Tabs>
);

export { TabPanel as Tab, CustomTabs as Tabs, type IProps as TabsProps };
