import Button from '@mui/lab/LoadingButton';
import { SprintToggler } from '@app/components/core';
import { TabsType } from '@app/utils/types';
import { ProductEntity, UserEntity } from '@data';
import React, { Dispatch, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import s from './styles.module.scss';

export interface SprintHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  activeTab: TabsType;
  setActiveTab: Dispatch<TabsType>;
  productIds: string[];
  setProductIds: Dispatch<string[]>;
  products?: { items: ProductEntity[]; count: number };
  handleSendMembershipReminder: () => void;
  user?: UserEntity;
  sendMembershipReminderIsLoading: boolean;
}

const SprintHeader = ({
  activeTab,
  setActiveTab,
  productIds,
  setProductIds,
  products,
  handleSendMembershipReminder,
  user,
  sendMembershipReminderIsLoading,
}: SprintHeaderProps): ReactElement => {
  const { t } = useTranslation();

  const productsOptions = products?.items?.map((product) => ({ value: product.id, label: product.name })) || [];

  return (
    <div className={s.page}>
      <div className={s.pageHeader}>
        <div className={s.pageHeaderSection}>
          <p className={s.pageTitle}>
            {t('titles.membersReporting')} ({`${user?.firstName || ''} ${user?.lastName || ''}`})
          </p>

          <SprintToggler activeTab={activeTab} setActiveTab={setActiveTab} />
        </div>

        <div className={s.pageHeaderFilters}>
          <Autocomplete
            className={s.pageHeaderFiltersSelect}
            fullWidth
            multiple
            value={productIds.map((product) => productsOptions.find((option) => option.value === product))}
            options={productsOptions}
            getOptionLabel={(option) => option?.label || ''}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                className={s.pageHeaderFiltersSelectInput}
                placeholder={!productIds.length ? t('allProducts') : undefined}
                label={t('titles.products')}
              />
            )}
            onChange={(_e, options) => {
              setProductIds(options.map((option) => option!.value));
            }}
          />
          <Button variant="contained" loading={sendMembershipReminderIsLoading} onClick={handleSendMembershipReminder}>
            {t('ping')}
          </Button>
        </div>
      </div>
    </div>
  );
};
export default SprintHeader;
