import { membersPath } from '@app/constants/url';
import React, { ReactElement } from 'react';
import { Route, Switch } from 'react-router-dom';
import { UserEntity } from '@data';
import MembersContainer from './membersList';

export interface MembersProps {
  user: UserEntity;
}

const MembersRouter = ({ user }: MembersProps): ReactElement => (
  <Switch>
    <Route path={membersPath(':organizationId')}>
      <MembersContainer user={user} />
    </Route>
  </Switch>
);

export default MembersRouter;
