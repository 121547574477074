import { TabsType } from '@app/utils/types';
import { ProductStatus, MembershipStatus } from '@data';
import nl from 'date-fns/locale/nl';
import en from 'date-fns/locale/en-US';
import { Language } from '../enums';

export const daysInSprint = 14;
export const tabs = { currentSprint: TabsType.currentSprint, pastSprints: TabsType.pastSprints };

export const activeArchivedTabs = [ProductStatus.active, ProductStatus.archived];

export const membershipTabs = [MembershipStatus.active, MembershipStatus.deactivated];

export const dateFnsLocaleMap: Record<Language, Locale> = {
  [Language.English]: en,
  [Language.Dutch]: nl,
};
