import React, { useState, useEffect } from 'react';
import { createMarkup, toFixedHours, getFullNameOrEmail, useFirstRender } from '@app/utils';
import { Chapter, ReportEntity, WorkDayChapter } from '@data';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { Roles } from '@app/utils/types';
import { useStore } from '@data/storages';
import Accordion from '@app/components/core/accordion';
import EditOutlined from '@mui/icons-material/EditOutlined';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import s from './styles.module.scss';

type Props = {
  chapter: WorkDayChapter;
  expandAll: boolean;
  chapters?: Chapter[];
  showEditForm?: (report: ReportEntity) => void;
  showDeleteForm?: (report: ReportEntity) => void;
};

export const ProductReportItem = ({
  chapter,
  expandAll,
  chapters,
  showEditForm,
  showDeleteForm,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const { organization } = useStore();

  const [isOpen, setOpen] = useState(expandAll);
  const isFirstRender = useFirstRender();
  const toggleOpen = () => {
    setOpen(!isOpen);
  };
  useEffect(() => {
    if (!isFirstRender) setOpen(expandAll);
  }, [expandAll]);
  return (
    <div className={s.report}>
      <Typography className={s.reportHeader}>{chapters?.find((x) => x.role === chapter.name)?.text}</Typography>
      <div className={s.reportContent}>
        {chapter.reports.map((report) => {
          const {
            id,
            today,
            involvement: {
              membership: { user },
            },
            minutes,
          } = report;
          return (
            <Accordion
              titleContent={
                <div className={s.reportTitleContentWrap}>
                  <Typography className={s.reportContentBlockTitle}>{getFullNameOrEmail(user)}</Typography>
                  <div className={s.reportContentBlockButtons}>
                    <Typography>
                      {t('hours', {
                        amount: toFixedHours(minutes / 60),
                      })}
                    </Typography>
                    {organization?.role !== Roles.client && (
                      <>
                        <IconButton
                          className={s.workDayHeaderInfoButton}
                          onClick={
                            showEditForm
                              ? (e) => {
                                  e.stopPropagation();
                                  showEditForm(report);
                                }
                              : undefined
                          }
                        >
                          <EditOutlined />
                        </IconButton>

                        <IconButton
                          className={s.workDayHeaderInfoButton}
                          onClick={
                            showDeleteForm
                              ? (e) => {
                                  e.stopPropagation();
                                  showDeleteForm(report);
                                }
                              : undefined
                          }
                        >
                          <DeleteOutline />
                        </IconButton>
                      </>
                    )}
                  </div>
                </div>
              }
              key={id}
              onClick={toggleOpen}
              isOpen={isOpen}
              openContainerClassName={s.reportContentBlockOpen}
              className={cx(s.reportContentBlock)}
            >
              {/* eslint-disable-next-line */}
              <div
                className={s.reportContentBlockDescription}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <div
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={createMarkup(today)}
                  className={s.reportContentBlockDescriptionText}
                />
              </div>
            </Accordion>
          );
        })}
      </div>
    </div>
  );
};
