import React, { ReactElement, ReactNode, StyleHTMLAttributes } from 'react';
import cx from 'classnames';
import s from './styles.module.scss';

type PaperProps = {
  children: ReactNode;
  className?: string;
  shrink?: boolean;
  gutter?: number[] | string | number;
  style?: StyleHTMLAttributes<HTMLDivElement>;
};

const getPadding = (gutter: number[] | string | number): string | number => {
  if (Array.isArray(gutter)) {
    // add px to the last array item
    return `${gutter.join('px ')}px`;
  }

  return gutter;
};

export const Paper = ({
  className,
  children,
  shrink = false,
  gutter = [24, 40, 60],
  style = {},
}: PaperProps): ReactElement => (
  <div
    style={{
      padding: getPadding(gutter),
      ...style,
    }}
    className={cx(s.paper, shrink && s.paperShrink, className)}
  >
    {children}
  </div>
);
