import Button from '@mui/lab/LoadingButton';
import { clientsReportingPath } from '@app/constants/url';
import { formatDateString, getFullNameOrEmail } from '@app/utils';
import { Language } from '@app/constants/enums';
import {
  ApproveSprintFormValues,
  ProductEntity,
  SprintProductEntity,
  SprintProductParamsType,
  SprintProductStatus,
  useApproveSprintProduct,
} from '@data';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useParams } from 'react-router-dom';
import { ApprovalPopup } from '@app/components/core';
import ArrowBack from '@mui/icons-material/ArrowBack';
import Typography from '@mui/material/Typography';
import s from './styles.module.scss';

export interface ProductHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  product?: ProductEntity;
  sprintProduct: SprintProductEntity;
}

const ProductHeader = ({ product, sprintProduct }: ProductHeaderProps): ReactElement => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { state } = useLocation();
  const { organizationId, sprintId, productId } = useParams<SprintProductParamsType>();
  const [isApproveModalOpen, setApproveModalOpen] = useState<boolean>(false);

  const { mutate: approveSprintProduct, isLoading: isApproving } = useApproveSprintProduct();

  const handleApprove = (values: ApproveSprintFormValues) => {
    approveSprintProduct(values, {
      onSuccess: () => {
        setApproveModalOpen(false);
      },
    });
  };

  const approveButton = (
    <Button variant="contained" className={s.btn} key="approveButton" onClick={() => setApproveModalOpen(true)}>
      {t('actions.approve')}
    </Button>
  );

  const backBtn = (
    <Button
      to={{
        pathname: clientsReportingPath(organizationId),
        state,
      }}
      className={s.backBtn}
      component={Link}
      size="small"
      variant="text"
      startIcon={<ArrowBack />}
    >
      {t('back')}
    </Button>
  );

  return (
    <div>
      <div className={s.pageHeader}>
        {backBtn}

        <div className={s.row}>
          <div className={s.rowItem}>
            <Typography className={s.rowItemTitle}>
              {`${product?.name} ${t('sprintCount', {
                count: sprintProduct?.sprint?.number,
              })}`}
            </Typography>

            {(sprintProduct?.status === SprintProductStatus.approved ||
              sprintProduct?.status === SprintProductStatus.invoiced) && (
              <div className={s.approvedInfo}>
                <div className={s.approvedInfoHighlight}>{t('approved')}</div>
                <Typography component="span" className={s.approvedInfoTime}>
                  {`on ${formatDateString(sprintProduct?.approvedAt, language as Language, 'MMM dd')} `}{' '}
                </Typography>
                <Typography component="span" className={s.approvedInfoName}>{`by ${getFullNameOrEmail(
                  sprintProduct?.approvedBy,
                )}`}</Typography>
              </div>
            )}
          </div>
          {sprintProduct?.status === SprintProductStatus.closed && <div className={s.rowItem}>{approveButton}</div>}
        </div>
      </div>

      {isApproveModalOpen && (
        <ApprovalPopup
          sprintProduct={sprintProduct}
          onClose={() => setApproveModalOpen(false)}
          approveSprintProduct={handleApprove}
          isApproving={isApproving}
          productId={productId}
          sprintId={sprintId}
        />
      )}
    </div>
  );
};
export default ProductHeader;
