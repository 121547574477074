import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { SprintToggler } from '@app/components/core';
import { TabsType } from '@app/utils/types';
import { ProductEntity } from '@data';
import React, { Dispatch, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Unstable_Grid2';

import s from './styles.module.scss';

export interface SprintHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  activeTab: TabsType;
  setActiveTab: Dispatch<TabsType>;
  productIds: string[];
  setProductIds: Dispatch<string[]>;
  products?: { items: ProductEntity[]; count: number };
}

const SprintHeader = ({
  activeTab,
  setActiveTab,
  productIds,
  setProductIds,
  products,
}: SprintHeaderProps): ReactElement => {
  const { t } = useTranslation();
  const theme = useTheme();

  const productsOptions = products?.items?.map((product) => ({ value: product.id, label: product.name })) || [];
  const productsFilterOptions = productsOptions;

  return (
    <>
      <Grid mb={3} container spacing={2} justifyContent="space-between" alignItems="center">
        <Grid xs={12} md="auto">
          <Typography variant="h4" fontWeight={theme.typography.fontWeightBold}>
            {t('titles.sprintReporting')}
          </Typography>
        </Grid>
        <Grid xs={12} md="auto">
          <SprintToggler activeTab={activeTab} setActiveTab={setActiveTab} />
        </Grid>
      </Grid>
      <Box marginBottom={3}>
        <Autocomplete
          className={s.pageHeaderFiltersSelect}
          fullWidth
          multiple
          value={productIds?.map((x) => productsFilterOptions.find((y) => y.value === x))}
          options={productsFilterOptions}
          getOptionLabel={(option) => option?.label || ''}
          filterSelectedOptions
          renderInput={(params) => (
            <TextField
              {...params}
              className={s.filterItemsSelectInput}
              placeholder={!productIds.length ? t('allProducts') : undefined}
              label={t('titles.product')}
            />
          )}
          onChange={(_e, options) => {
            setProductIds(options.map((x: any) => x.value));
          }}
        />
      </Box>
    </>
  );
};
export default SprintHeader;
