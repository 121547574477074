import { organizationsPath } from '@app/constants/url';
import { UserEntity } from '@data';
import React, { ReactElement } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useStore } from '@data/storages';
import { useOrganizations } from '@data/hooks/organizations';
import Organizations from './organizations';

export interface AppProps extends React.HTMLAttributes<HTMLDivElement> {
  user: UserEntity;
}

const App = ({ user }: AppProps): ReactElement => {
  const { organization, setOrganization } = useStore();
  const { data: organizations } = useOrganizations(user?.id);

  if (!organization && organizations) {
    setOrganization(organizations.items[0]);
  }

  return (
    <Switch>
      <Route path={organizationsPath}>
        <Organizations user={user} />
      </Route>
      <Redirect to={organizationsPath} />
    </Switch>
  );
};

export default App;
