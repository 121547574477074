import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { BriefChapters, DetailedChapters, Paper, Tab, Tabs } from '@app/components/core';
import { Language } from '@app/constants/enums';
import { formatDateString, toFixedHours, useToggleState } from '@app/utils';
import {
  SprintProductParamsType,
  SprintProductReportsParams,
  useListSprintProducts,
  useProduct,
  useSprint,
  useSprintProductReports,
} from '@data';
import { transformSprintProductReportsData } from '@data/api/utils/helpers';
import { useOrganizationChapters } from '@data/hooks/organization';
import React, { ReactElement, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Loader } from '@ui';
import Container from '@mui/material/Container';
import ProductHeader from './ProductHeader';
import s from './styles.module.scss';
import Summary from './Summary';

const SprintProductContainer = (): ReactElement => {
  const theme = useTheme();
  const { sprintId, productId, organizationId } = useParams<SprintProductParamsType>();
  const [hideWeekend, setWeekend] = useToggleState(true);
  const { data: sprint } = useSprint(sprintId);
  const { data: product } = useProduct(productId);
  const { data: sprintProducts, isLoading: isSprintProductsLoading } = useListSprintProducts({ sprintId, productId });
  const [selectedChapters, setChapters] = useState<string[] | undefined>();
  const sprintProductReportParams: SprintProductReportsParams = {
    sprint,
    productId,
    chapters: selectedChapters,
  };
  const { data: sprintProductReports } = useSprintProductReports(sprintProductReportParams);
  const { data: chapters } = useOrganizationChapters(organizationId);

  const { detailed, brief } = transformSprintProductReportsData(hideWeekend, sprint, sprintProductReports);

  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [activeTab, setActiveTab] = React.useState(0);

  const totalHours = detailed
    ? toFixedHours(detailed.reduce((sum, workday) => sum + workday.totalMinutes, 0) / 60)
    : undefined;

  const isBriefReportLoading = !brief || !chapters;
  const isDetailedReportLoading = !detailed || !chapters;

  const sprintProduct = sprintProducts?.items?.[0];
  return (
    <Container maxWidth="xl">
      {!sprint || !product || !sprintProduct || isSprintProductsLoading ? (
        <Loader />
      ) : (
        <>
          <ProductHeader product={product} sprintProduct={sprintProduct} />
          <Paper className={s.pagePaper} gutter={[40, 40, 60]}>
            <div className={s.pagePaperTitle}>
              <div>
                <Typography component="span" className={s.pagePaperTitleDate}>
                  {t('date')}
                </Typography>{' '}
                <Typography component="span" fontWeight={theme.typography.fontWeightBold}>
                  {`${formatDateString(sprint?.startDate, language as Language, 'dd MMM')} -
            ${formatDateString(sprint?.endDate, language as Language, 'dd MMM yyyy')}`}
                </Typography>
              </div>
              <div>
                <Trans defaults={t('sprintTotalHours', { totalHours })} components={{ bold: <strong /> }} />
              </div>
            </div>
            <Summary sprintProduct={sprintProduct} />
            <Tabs
              tabs={[t('tabs.brief'), t('tabs.detailed')]}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              tabProps={{ tabIndex: '0' }}
              className={s.tab}
            >
              <Tab>
                <BriefChapters
                  isLoading={isBriefReportLoading}
                  setChapters={setChapters}
                  chapters={chapters?.items}
                  briefReports={brief}
                  selectedChapters={selectedChapters}
                />
              </Tab>
              <Tab>
                <DetailedChapters
                  isLoading={isDetailedReportLoading}
                  hideWeekend={hideWeekend}
                  setWeekend={setWeekend}
                  chapters={chapters?.items}
                  detailedReports={detailed}
                  setChapters={setChapters}
                  selectedChapters={selectedChapters}
                  sprintProduct={sprintProduct}
                />
              </Tab>
            </Tabs>
          </Paper>
        </>
      )}
    </Container>
  );
};

export default SprintProductContainer;
