import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@mui/lab/LoadingButton';

import AnimateHeight from 'react-animate-height';
import { createMarkup } from '@app/utils';
import Typography from '@mui/material/Typography';
import s from './styles.module.scss';

interface ExpandableContentProps {
  content?: string;
}

const ExpandableContent: React.FC<ExpandableContentProps> = ({ content }) => {
  const { t } = useTranslation();
  const contentRef = useRef<HTMLSpanElement>(null);
  const [isTextClamped, setShowTextClamped] = useState(true);
  const [shouldShowButton, setShouldShowButton] = useState(false);
  // fix animation issue
  const approximatelyRowsHeight = contentRef.current ? 56 : 'auto';

  useEffect(() => {
    if (contentRef.current) {
      const { clientHeight, scrollHeight } = contentRef.current;

      setShowTextClamped(scrollHeight > clientHeight);

      if (typeof approximatelyRowsHeight === 'string') setShouldShowButton(scrollHeight > 0);
      else setShouldShowButton(scrollHeight > approximatelyRowsHeight);
    }
  }, [contentRef.current]);

  return (
    <>
      <AnimateHeight
        height={isTextClamped ? approximatelyRowsHeight : contentRef?.current?.scrollHeight || 'auto'}
        duration={300}
      >
        <Typography
          ref={contentRef}
          className={s.content}
          style={{ display: isTextClamped ? '-webkit-box' : 'block' }}
          dangerouslySetInnerHTML={createMarkup(content || '')}
        />
      </AnimateHeight>

      {contentRef.current && shouldShowButton && (
        <Button className={s.toggleBtn} onClick={() => setShowTextClamped(!isTextClamped)}>
          {isTextClamped ? t('showMore') : t('showLess')}
        </Button>
      )}
    </>
  );
};

export default ExpandableContent;
