import { hoursToMinutes } from '@app/utils';
import { ReportEntity, WorkDayUserEntity } from '@data';
import { useGTMEvents } from '@data/events';
import { useInfiniteQuery, UseInfiniteQueryResult, useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { v4 as uuidv4 } from 'uuid';
import { fullDate } from '../../app/utils/helpers/date-utils';

import * as api from '../api';
import * as queryKeys from '../constants/keys';
import { IError, ListResponse, OrganizationEntity, ProductStatus, SprintProductReportsParams } from '../utils/types';
import { useListProducts } from './products';

type QueryContext = { previousReports: ReportEntity[] };
type UpdatedReportPmQueryContext = { previousReports?: ListResponse<ReportEntity> };
type CreateReportPmQueryContext = { previousReports?: ListResponse<ReportEntity>; newReport: any };

export const useListReports = (
  organizationId: string,
  userId: string,
  params: { from?: string | Date; to?: string | Date; productIds?: string[]; offset?: number; limit?: number },
  isEnabled: boolean,
  hideWeekend: boolean,
): UseInfiniteQueryResult<{ from: Date | string; to: Date | string; workDaysData: WorkDayUserEntity[] }, IError> =>
  useInfiniteQuery(
    [queryKeys.REPORTS, { organizationId, userId, params, hideWeekend }],
    ({ pageParam }) => api.fetchReports(organizationId, userId, params, hideWeekend, { pageParam }),
    {
      enabled: isEnabled,
      keepPreviousData: true,
      getNextPageParam: (lastPage: { from: Date | string; to: Date | string; workDaysData: WorkDayUserEntity[] }) => {
        const nextPage = {
          from: new Date(new Date(lastPage.from).setUTCHours(0)),
          to: new Date(new Date(lastPage.to).setUTCHours(0)),
        };

        return nextPage;
      },
    },
  );

export const useCreateReport = (
  userId: string,
  organizationId: string,
  params: { from?: string | Date; to?: string | Date; productIds?: string[]; offset?: number; limit?: number },
  hideWeekend: boolean,
): UseMutationResult<ReportEntity, IError, Partial<ReportEntity>, QueryContext> => {
  const queryClient = useQueryClient();
  const { data: products } = useListProducts(organizationId, {
    statuses: [ProductStatus.active, ProductStatus.support],
  });

  const { events, trackEvent } = useGTMEvents();

  return useMutation((values) => api.createReport(values), {
    onSuccess: (data) => {
      queryClient.invalidateQueries([queryKeys.REPORTS, { organizationId, userId, params, hideWeekend }]);
      queryClient.invalidateQueries([queryKeys.PRODUCTS]);
      // GTM track event
      trackEvent(events.ReportSubmit, { teamName: data.involvement.role, today: data.today });
    },
    onMutate: async (newReport: any) => {
      await queryClient.cancelQueries([queryKeys.REPORTS, { organizationId, userId, params, hideWeekend }]);

      const previousReports = queryClient.getQueryData([
        queryKeys.REPORTS,
        { organizationId, userId, params, hideWeekend },
      ]) as ReportEntity[];

      queryClient.setQueryData([queryKeys.REPORTS, { organizationId, userId, params, hideWeekend }], (old: any) => {
        const newItem = {
          id: uuidv4(),
          minutes: newReport.hours * 60,
          involvement: {
            id: uuidv4(),
            product: products?.items?.find((product) => product.id === newReport.productId),
          },
          ...newReport,
        } as ReportEntity;

        return {
          ...old,
          pages: old.pages.reduce((newPages, page) => {
            const newPage = {
              ...page,
              workDaysData: page.workDaysData.reduce(
                (newList, dayReports) => [
                  ...newList,
                  {
                    ...dayReports,
                    reports:
                      fullDate(newItem.workday) === dayReports.date
                        ? [...dayReports.reports, newItem]
                        : dayReports.reports,
                  },
                ],
                [],
              ),
            };
            return [...newPages, newPage];
          }, []),
        };
      });

      return { previousReports };
    },
    onError: (_, __, context) => {
      queryClient.setQueryData(
        [queryKeys.REPORTS, { organizationId, userId, params, hideWeekend }],
        context?.previousReports,
      );
    },
  });
};

export const useUpdateReport = (
  organizationId: string,
  userId: string,
  params: { from?: string | Date; to?: string | Date; productIds?: string[]; offset?: number; limit?: number },
  hideWeekend: boolean,
): UseMutationResult<ReportEntity, IError, Partial<ReportEntity>, QueryContext> => {
  const queryClient = useQueryClient();
  return useMutation((values) => api.updateReport(values), {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.REPORTS, { organizationId, userId, params, hideWeekend }]);
    },
    onMutate: async (newReport: Partial<ReportEntity>) => {
      await queryClient.cancelQueries([queryKeys.REPORTS, { organizationId, userId, params, hideWeekend }]);

      const previousReports = queryClient.getQueryData([
        queryKeys.REPORTS,
        organizationId,
        userId,
        params,
        hideWeekend,
      ]) as ReportEntity[];

      queryClient.setQueryData([queryKeys.REPORTS, { organizationId, userId, params, hideWeekend }], (old: any) => {
        const newData = old.pages.reduce((newPages, page) => {
          const newPage = {
            ...page,
            workDaysData: page.workDaysData.reduce(
              (newList, dayReports) => [
                ...newList,
                {
                  ...dayReports,
                  reports:
                    newReport.workday === dayReports.date
                      ? dayReports.reports.map((report) =>
                          report.id === newReport.id ? { ...report, ...newReport } : report,
                        )
                      : dayReports.reports,
                },
              ],
              [],
            ),
          };
          return [...newPages, newPage];
        }, []);

        return { ...old, pages: newData };
      });

      return { previousReports };
    },
    onError: (_, __, context) => {
      queryClient.setQueryData(
        [queryKeys.REPORTS, { organizationId, userId, params, hideWeekend }],
        context?.previousReports,
      );
    },
  });
};

export const useRemoveReport = (
  organizationId: string,
  userId: string,
  params: { from?: string | Date; to?: string | Date; productIds?: string[]; offset?: number; limit?: number },
  hideWeekend: boolean,
): UseMutationResult<void, IError, string, QueryContext> => {
  const queryClient = useQueryClient();

  return useMutation((reportId) => api.removeReport(reportId), {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.REPORTS, { organizationId, userId, params, hideWeekend }]);
    },
    onMutate: async (id: string) => {
      await queryClient.cancelQueries([queryKeys.REPORTS, { organizationId, userId, params, hideWeekend }]);

      const previousReports = queryClient.getQueryData([
        queryKeys.REPORTS,
        organizationId,
        userId,
        params,
        hideWeekend,
      ]) as ReportEntity[];

      queryClient.setQueryData([queryKeys.REPORTS, { organizationId, userId, params, hideWeekend }], (old: any) => ({
        ...old,
        pages: old.pages.reduce((newPages, page) => {
          const newPage = {
            ...page,
            workDaysData: page.workDaysData.reduce(
              (newList, dayReports) => [
                ...newList,
                { ...dayReports, reports: dayReports.reports.filter((report) => String(report.id) !== String(id)) },
              ],
              [],
            ),
          };
          return [...newPages, newPage];
        }, []),
      }));
      return { previousReports };
    },
    onError: (_, __, context) => {
      queryClient.setQueryData(queryKeys.REPORTS, context?.previousReports);
    },
  });
};

export const useCreatePmReport = (
  organizationId: string,
  params: SprintProductReportsParams,
): UseMutationResult<ReportEntity, IError, Partial<ReportEntity>, CreateReportPmQueryContext> => {
  const queryClient = useQueryClient();
  return useMutation((values) => api.createReport(values), {
    onMutate: async (values) => {
      const previousReports = queryClient.getQueryData<ListResponse<ReportEntity>>([
        queryKeys.PM_SPRINT_PRODUCT_REPORTS,
        params,
      ]);

      const member = queryClient
        .getQueryData<OrganizationEntity>([queryKeys.ORGANIZATION, organizationId])
        ?.memberships.find((m) => m.user.id === values.userId);

      const newReport = {
        ...values,
        id: uuidv4(),
        minutes: hoursToMinutes(values.hours),
        involvement: { role: values.role, membership: member },
      };

      queryClient.setQueryData([queryKeys.PM_SPRINT_PRODUCT_REPORTS, params], (old: ListResponse<ReportEntity>) => ({
        ...old,
        items: [...old.items, newReport],
      }));

      return { previousReports, newReport };
    },
    onSuccess: (data, _, context) => {
      queryClient.setQueryData([queryKeys.PM_SPRINT_PRODUCT_REPORTS, params], (old: ListResponse<ReportEntity>) => ({
        ...old,
        items: old.items.map((x) => (x.id === context?.newReport.id ? { ...x, id: data.id } : x)),
      }));
    },
    onError: (_, __, context) => {
      queryClient.setQueryData([queryKeys.PM_SPRINT_PRODUCT_REPORTS, params], context?.previousReports);
    },
  });
};

export const useUpdatePmReport = (
  params: SprintProductReportsParams,
): UseMutationResult<ReportEntity, IError, Partial<ReportEntity>, UpdatedReportPmQueryContext> => {
  const queryClient = useQueryClient();
  return useMutation((values) => api.updateReport(values), {
    onMutate: async (updatedReport: Partial<ReportEntity>) => {
      const previousReports = queryClient.getQueryData<ListResponse<ReportEntity>>([
        queryKeys.PM_SPRINT_PRODUCT_REPORTS,
        params,
      ]);

      queryClient.setQueryData([queryKeys.PM_SPRINT_PRODUCT_REPORTS, params], (old: ListResponse<ReportEntity>) => ({
        ...old,
        items: old.items.map((x) =>
          x.id === updatedReport.id ? { ...x, ...updatedReport, minutes: hoursToMinutes(updatedReport.hours) } : x,
        ),
      }));

      return { previousReports };
    },
    onError: (_, __, context) => {
      queryClient.setQueryData([queryKeys.PM_SPRINT_PRODUCT_REPORTS, params], context?.previousReports);
    },
  });
};

export const useRemovePmReport = (
  params: SprintProductReportsParams,
): UseMutationResult<void, IError, string, UpdatedReportPmQueryContext> => {
  const queryClient = useQueryClient();

  return useMutation((reportId) => api.removeReport(reportId), {
    onMutate: async (reportId: string) => {
      const previousReports = queryClient.getQueryData<ListResponse<ReportEntity>>([
        queryKeys.PM_SPRINT_PRODUCT_REPORTS,
        params,
      ]);
      queryClient.setQueryData([queryKeys.PM_SPRINT_PRODUCT_REPORTS, params], (old: ListResponse<ReportEntity>) => ({
        ...old,
        items: old.items.filter((i) => i.id !== reportId),
      }));
      return { previousReports };
    },
    onError: (_, __, context) => {
      queryClient.setQueryData([queryKeys.PM_SPRINT_PRODUCT_REPORTS, params], context?.previousReports);
    },
  });
};

export const useReportSummary = (): UseMutationResult<
  string,
  any,
  {
    productId: string;
    since: string;
    until: string;
  },
  unknown
> => useMutation(({ productId, since, until }) => api.fetchSummary({ productId, since, until }));
