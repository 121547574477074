import React, { useState, ReactElement, ReactNode } from 'react';
import AnimateHeight from 'react-animate-height';
import cx from 'classnames';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import s from './styles.module.scss';

interface IAccordion extends React.HTMLAttributes<HTMLDivElement> {
  titleContent: string | JSX.Element;
  children: ReactNode;
  isOpen?: boolean;
  containerClassName?: string;
  contentClassName?: string;
  className?: string;
  openContainerClassName?: string;
}

const Accordion = ({
  titleContent,
  children,
  isOpen,
  containerClassName,
  contentClassName,
  className,
  openContainerClassName,
}: IAccordion): ReactElement => {
  const [isActive, setIsActive] = useState(isOpen);

  return (
    <div className={cx(s.accordionContainer, containerClassName, isActive && openContainerClassName)}>
      <button className={cx(s.accordionHeader, className)} onClick={() => setIsActive(!isActive)}>
        <div className={s.accordionHeaderContainer}>
          {isActive ? (
            <ExpandLess className={s.accordionHeaderIcon} />
          ) : (
            <ExpandMore className={s.accordionHeaderIcon} />
          )}

          <div className={s.accordionHeaderTitleContent}>{titleContent}</div>
        </div>
      </button>

      <AnimateHeight height={isActive ? 'auto' : 0}>
        <div className={cx(s.accordionContent, contentClassName)}>{children}</div>
      </AnimateHeight>
    </div>
  );
};

export default Accordion;
