import { useEffect, useRef } from 'react';

const MOUSELEAVE = 'mouseleave';
const SCROLL = 'scroll';
const ERROR = 'error';
const BLUR = 'blur';
const RESIZE = 'resize';

type HandledEvents = [typeof MOUSELEAVE, typeof SCROLL, typeof ERROR, typeof BLUR, typeof RESIZE];

type HandledEventsType = HandledEvents[number];

type PossibleEvent = {
  [Type in HandledEventsType]: HTMLElementEventMap[Type];
}[HandledEventsType];
type Handler = (event: PossibleEvent) => void;

const isWindow = (ref): ref is Window => ref instanceof window.constructor;

export const useEventListener = (
  type: HandledEventsType,
  ref: React.RefObject<HTMLElement> | Window,
  handler: Handler,
): void => {
  const handlerRef = useRef(handler);

  useEffect(() => {
    handlerRef.current = handler;
  });

  useEffect(() => {
    const listener = (event: PossibleEvent) => {
      handlerRef.current(event);
    };

    if (isWindow(ref)) {
      window.addEventListener(type, listener);
    } else {
      ref.current?.addEventListener(type, listener);
    }

    return () => {
      if (isWindow(ref)) {
        window.removeEventListener(type, listener);
      } else {
        ref.current?.removeEventListener(type, listener);
      }
    };
  }, [handler]);
};
