import { RoleEntity } from '@data/utils/types';
import { api } from '@data/store';

export const fetchRoles = (params: {
  userId: string;
  productId: string;
}): Promise<{ items: RoleEntity[]; count: number }> =>
  api.get(`/involvements/roles`, {
    params,
  });
