import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { BriefChapters, DetailedChapters, Paper, Tab, Tabs } from '@app/components/core';
import RemoveReportModal from '@app/components/shared/removeReportModal';
import ReportModal from '@app/components/shared/reportModal';
import { Language } from '@app/constants/enums';
import { formatDateString, toFixedHours, useToggleState } from '@app/utils';
import {
  ReportEntity,
  SprintProductParamsType,
  SprintProductReportsParams,
  useCreatePmReport,
  useCreateSprintProduct,
  useListSprintProductClients,
  useListSprintProducts,
  useProduct,
  useRemovePmReport,
  useSendSprintProduct,
  useSprint,
  useSprintProductReports,
  useUpdatePmReport,
  useUpdateSprintProduct,
} from '@data';
import { transformSprintProductReportsData } from '@data/api/utils/helpers';
import { useOrganizationChapters } from '@data/hooks/organization';
import React, { ReactElement, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Loader } from '@ui';
import ProductHeader from './ProductHeader';
import s from './styles.module.scss';
import Summary from './Summary';

const ProductReporting = (): ReactElement => {
  const theme = useTheme();
  const { sprintId, productId, organizationId } = useParams<SprintProductParamsType>();
  const [hideWeekend, setWeekend] = useToggleState(true);
  const { data: sprint } = useSprint(sprintId);
  const { data: product } = useProduct(productId);
  const { data: sprintProducts, isLoading: isSprintProductsLoading } = useListSprintProducts({ sprintId, productId });
  const [selectedChapters, setChapters] = useState<string[] | undefined>();
  const sprintProductReportParams: SprintProductReportsParams = {
    sprint,
    productId,
    chapters: selectedChapters,
  };
  const { data: sprintProductReports } = useSprintProductReports(sprintProductReportParams);
  const { data: chapters } = useOrganizationChapters(organizationId);
  const { mutate: createSprintProduct } = useCreateSprintProduct(organizationId, {}, { sprintId, productId });
  const { mutate: updateSprintProduct } = useUpdateSprintProduct(organizationId, {}, { sprintId, productId });
  const { mutate: sendSprint } = useSendSprintProduct();
  const { mutate: createReport } = useCreatePmReport(organizationId, sprintProductReportParams);
  const { mutate: updateReport } = useUpdatePmReport(sprintProductReportParams);
  const { mutate: removeReport } = useRemovePmReport(sprintProductReportParams);
  const sprintProduct = sprintProducts?.items?.[0];
  const { data: sprintProductClients } = useListSprintProductClients(
    sprintProduct && !sprintProduct.mutation ? sprintProduct.id : undefined,
  );

  const { detailed, brief } = React.useMemo(
    () => transformSprintProductReportsData(hideWeekend, sprint, sprintProductReports),
    [hideWeekend, sprint, sprintProductReports],
  );

  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [activeTab, setActiveTab] = React.useState(0);
  const [isAddFormOpen, toggleAddForm] = useToggleState(false);
  const [workDayToAdd, setWorkDayToAdd] = React.useState<string | undefined>();
  const [selectedReport, setSelectedReport] = React.useState<ReportEntity | undefined>();
  const [reportToEdit, setReportToEdit] = React.useState<ReportEntity | undefined>();
  const [isDeleteFormOpen, toggleDeleteForm] = useToggleState(false);

  const totalHours = detailed
    ? toFixedHours(detailed.reduce((sum, workday) => sum + workday.totalMinutes, 0) / 60)
    : undefined;

  const showAddForm = (workDay: string) => {
    toggleAddForm();
    setWorkDayToAdd(workDay);
  };
  const closeAddForm = () => {
    toggleAddForm();
    setWorkDayToAdd(undefined);
  };

  const showEditForm = (report: ReportEntity) => {
    setReportToEdit(report);
  };
  const closeEditForm = () => {
    setReportToEdit(undefined);
  };

  const showDeleteForm = (report: ReportEntity) => {
    setSelectedReport(report);
    toggleDeleteForm();
  };

  const closeDeleteForm = () => {
    toggleDeleteForm();
    setSelectedReport(undefined);
  };

  const handleDeleting = () => {
    if (selectedReport && removeReport) {
      removeReport(selectedReport?.id);
    }
    closeDeleteForm();
  };

  const handleCreateReport = (values: Partial<ReportEntity>) => {
    createReport(values);
    closeAddForm();
  };

  const handleUpdateReport = (values: Partial<ReportEntity>) => {
    updateReport(values);
    closeEditForm();
  };

  const isBriefReportLoading = !brief || !chapters;
  const isDetailedReportLoading = !detailed || !chapters;
  const disabledDays = useMemo(() => {
    if (!sprint) return undefined;
    return { before: new Date(sprint.startDate), after: new Date(sprint.endDate) };
  }, [sprint]);

  return (
    <div className={s.page}>
      {!sprint || !product || isSprintProductsLoading ? (
        <Loader />
      ) : (
        <>
          <ProductHeader
            product={product}
            sprintProduct={sprintProduct}
            createSprintProduct={createSprintProduct}
            updateSprintProduct={updateSprintProduct}
            sendSprint={sendSprint}
            sprintProductClients={sprintProductClients}
          />
          <Paper className={s.pagePaper} gutter={[40, 40, 60]}>
            <div className={s.pagePaperTitle}>
              <div>
                <Typography component="span" className={s.pagePaperTitleDate}>
                  {t('date')}
                </Typography>{' '}
                <Typography component="span" fontWeight={theme.typography.fontWeightBold}>
                  {`${formatDateString(sprint?.startDate, language as Language, 'dd MMM')} -
            ${formatDateString(sprint?.endDate, language as Language, 'dd MMM yyyy')}`}
                </Typography>
              </div>
              <div>
                <Trans defaults={t('sprintTotalHours', { totalHours })} components={{ bold: <strong /> }} />
              </div>
            </div>
            <Summary sprintProduct={sprintProduct} />
            <Tabs
              tabs={[t('tabs.brief'), t('tabs.detailed')]}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              tabProps={{ tabIndex: '0' }}
              className={s.tab}
            >
              <Tab>
                <BriefChapters
                  isLoading={isBriefReportLoading}
                  setChapters={setChapters}
                  chapters={chapters?.items}
                  briefReports={brief}
                  selectedChapters={selectedChapters}
                />
              </Tab>
              <Tab>
                <DetailedChapters
                  isLoading={isDetailedReportLoading}
                  hideWeekend={hideWeekend}
                  setWeekend={setWeekend}
                  chapters={chapters?.items}
                  detailedReports={detailed}
                  setChapters={setChapters}
                  selectedChapters={selectedChapters}
                  sprintProduct={sprintProduct}
                  showAddForm={showAddForm}
                  showEditForm={showEditForm}
                  showDeleteForm={showDeleteForm}
                />
              </Tab>
            </Tabs>
          </Paper>

          {isAddFormOpen ? (
            <ReportModal
              title={t('titles.addEntry')}
              show={isAddFormOpen}
              onHide={closeAddForm}
              onSubmit={handleCreateReport}
              disabledDays={disabledDays}
              date={workDayToAdd}
              productId={product?.id}
            />
          ) : null}

          {reportToEdit ? (
            <ReportModal
              title={t('titles.editEntry')}
              show={!!reportToEdit}
              onHide={closeEditForm}
              initialData={reportToEdit}
              onSubmit={handleUpdateReport}
              disabledDays={disabledDays}
            />
          ) : null}

          <RemoveReportModal show={isDeleteFormOpen} onHide={closeDeleteForm} onRemove={handleDeleting} />
        </>
      )}
    </div>
  );
};

export default ProductReporting;
