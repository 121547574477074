import { api } from '@data/store';
import { transformUserReportsData } from '@data/utils/transform-user-reports-data';
import { ReportEntity, WorkDayUserEntity } from '@data/utils/types';
import { addDays } from '@app/utils';
import { daysInSprint } from '@app/constants/app';

export const fetchReports = async (
  organizationId: string,
  userId: string,
  params: { from?: string | Date; to?: string | Date; productIds?: string[]; offset?: number; limit?: number },
  hideWeekend: boolean,
  { pageParam },
): Promise<{ from: Date | string; to: Date | string; workDaysData: WorkDayUserEntity[] }> => {
  const newParams = pageParam ? { ...params, ...pageParam } : { ...params };

  const data = await api.get(`organizations/${organizationId}/users/${userId}/reports`, {
    params: newParams,
  });

  const transformedReportsData = transformUserReportsData(data.items, newParams, hideWeekend);

  return {
    from: addDays(new Date(newParams?.from as string), -daysInSprint),
    to: addDays(new Date(newParams?.to as string), -daysInSprint),
    workDaysData: transformedReportsData,
  };
};

export const createReport = (values: Partial<ReportEntity>): Promise<ReportEntity> => api.post('/reports', values);

export const updateReport = (values: Partial<ReportEntity>): Promise<ReportEntity> =>
  api.patch(`/reports/${values.id}`, values);

export const removeReport = (reportId: string): Promise<void> => api.delete(`/reports/${reportId}`);

export const fetchSummary = async (params: { productId: string; since: string; until: string }) =>
  api.get(`reports/summary`, { params });
