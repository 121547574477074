export enum GTMEvent {
  Login = 'login',
  ReportSubmit = 'report_submit',
}

type PassedEvent = {
  event: GTMEvent;
  [key: string]: unknown;
};

declare global {
  interface Window {
    dataLayer?: PassedEvent[];
  }
}

export const useGTMEvents = () => {
  const pushToDataLayer = (data: PassedEvent): void => {
    if (typeof window === 'undefined') {
      return;
    }

    if (!window.dataLayer) {
      window.dataLayer = [];
    }

    window.dataLayer.push(data);
  };

  return {
    events: GTMEvent,
    trackEvent: (event: GTMEvent, data?: Record<string, unknown>) => {
      pushToDataLayer({
        event,
        ...data,
      });
    },
  };
};
