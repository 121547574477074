import React, { useRef, useState, useEffect } from 'react';
import { UserEntity } from '@data';
import { useEventListener } from '@app/utils/hooks';
import { useStore } from '@data/storages';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import { styled, useTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import panencoLogo from './panenco-logo.png';

const StyledImage = styled('img')`
  width: 100%;
  height: 100%;
  object-fit: contain;
  max-width: 100px;

  ${(props) => props.theme.breakpoints.up('md')} {
    max-width: 150px;
  }
`;

export const ClientSidebar = ({ user }: { user: UserEntity }): JSX.Element => {
  const theme = useTheme();
  const { organization } = useStore();
  const ref = useRef<HTMLImageElement>(null);
  const [imgUrl, setImageUrl] = useState(organization?.logoUrl);

  // handle case if loading url was canceled or restricted (401 error)
  useEventListener('error', ref, () => {
    // panenco logo as fallback
    setImageUrl(panencoLogo);
  });

  useEffect(() => {
    // if organisation value was changed from undefined/null to string, we have to update state
    if (organization?.logoUrl && !imgUrl) {
      setImageUrl(organization?.logoUrl);
    }
  }, [organization?.logoUrl]);

  const logoImage = <StyledImage src={panencoLogo} alt="logo" />;

  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: theme.palette.grey[900],
      }}
    >
      <Toolbar>
        <Container
          maxWidth="xl"
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box display="flex" alignItems="center">
            {logoImage}
          </Box>
          <Typography>{user?.email}</Typography>
        </Container>
      </Toolbar>
    </AppBar>
  );
};
