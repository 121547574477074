import React, { ReactElement, Dispatch } from 'react';
import cx from 'classnames';
import Button from '@mui/lab/LoadingButton';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import ButtonGroup from '@mui/material/ButtonGroup';
import s from './styles.module.scss';

interface TogglerProps<T> {
  tabs: T[];
  activeTab: T;
  setActiveTab: Dispatch<T>;
}

export const GenericToggler = <T,>({ tabs, activeTab, setActiveTab }: TogglerProps<T>): ReactElement => {
  const { t } = useTranslation();
  const history = useHistory();

  const setTab = (tab: T) => {
    setActiveTab(tab);
    history.push({ search: `?tab=${tab}` });
  };

  return (
    <ButtonGroup>
      {tabs.map((tab) => (
        <Button
          key={tab as string}
          className={cx(s.togglerTabsTab, activeTab === tab && s.togglerTabsTabActive)}
          onClick={() => setTab(tab)}
        >
          {t(`tabs.${tab}`)}
        </Button>
      ))}
    </ButtonGroup>
  );
};
