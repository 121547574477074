/* eslint-disable @typescript-eslint/no-empty-function */
// eslint-disable prettier/prettier
import { createContext } from 'react';

export type ContextProps = {
  redirect: (path?: string, state?: any) => void;
  getLocation: () => void;
};

const AuthContext = createContext<ContextProps>({
  redirect: () => {},
  getLocation: () => {},
});

export const { Provider: AuthProvider, Consumer: AuthConsumer } = AuthContext;

export default AuthContext;
