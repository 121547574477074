/* eslint-disable no-shadow */
import { produce } from 'immer';
import create, { State, SetState, GetState, StoreApi, UseStore } from 'zustand';
import { persist, devtools } from 'zustand/middleware';
import createVanillaStore from 'zustand/vanilla';
import HttpClient from './http-client';
import type { ApiStore } from './utils/types';

export const apiStore = createVanillaStore<ApiStore>(() => ({
  error: null,
  success: null,
  refreshFailed: false,
}));

export const api = new HttpClient(
  {
    baseURL: import.meta.env.VITE_API_BASE_URL,
  },
  apiStore,
);

type StateCreator<T extends State, CustomSetState = SetState<T>, U extends State = T> = (
  set: CustomSetState,
  get: GetState<T>,
  api: StoreApi<T>,
) => U;

const immer =
  <T extends State, U extends State>(
    config: StateCreator<T, (fn: (draft: T) => void) => void, U>,
  ): StateCreator<T, SetState<T>, U> =>
  (set, get, api) =>
    config((fn) => set(produce(fn) as (state: T) => T), get, api);

const combine =
  <PrimaryState extends State, SecondaryState extends State>(
    initialState: PrimaryState,
    create: (set: SetState<PrimaryState>, get: GetState<PrimaryState>, api: StoreApi<PrimaryState>) => SecondaryState,
  ): StateCreator<PrimaryState & SecondaryState> =>
  (set, get, api) => ({
    ...initialState,
    ...create(set as SetState<PrimaryState>, get as GetState<PrimaryState>, api as StoreApi<PrimaryState>),
  });

const createStore = <PrimaryState extends State, SecondaryState extends State>(
  initialState: PrimaryState,
  config: StateCreator<PrimaryState, (fn: (draft: PrimaryState) => void) => void, SecondaryState>,
  name: string,
): UseStore<PrimaryState & SecondaryState> => create(devtools(persist(combine(initialState, immer(config)), { name })));

export default createStore;
