import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import Button from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import { CreatableSelect } from '@app/components/core/form/create-select';
import Field from '@app/utils/form-field';
import cx from 'classnames';
import { MembershipEntity, SendSprintFormValues } from '@data';
import { Form, FormikProps } from 'formik';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import s from './styles.module.scss';

export interface SendSprintFormProps extends React.HTMLAttributes<HTMLDivElement> {
  handleClick: () => void;
  clients?: MembershipEntity[];
  checkedEmails: string[];
  onSend: () => void;
}

const SendSprintForm = ({
  handleClick,
  clients,
  checkedEmails,
  onSend,
}: SendSprintFormProps & FormikProps<SendSprintFormValues>): ReactElement => {
  const { t } = useTranslation();

  const clientOptions = clients?.map((x) => ({
    value: x.user.email,
    label: x.user.email,
  }));

  return (
    <Form>
      <div className={s.formEmails}>
        <Field
          component={CreatableSelect}
          id="emails"
          name="emails"
          placeholder={t('titles.newClients')}
          options={clientOptions}
        />
        <IconButton type="submit">
          <AddCircleOutline />
        </IconButton>
      </div>
      <div className={s.formActions}>
        <Button variant="outlined" onClick={handleClick} className={cx(s.formActionsButton, s.formActionsButtonCancel)}>
          {t('actions.cancel')}
        </Button>
        <Button
          variant="contained"
          disabled={!checkedEmails.length}
          onClick={onSend}
          className={cx(s.formActionsButton, s.formActionsButtonSave)}
          type="button"
        >
          {`${t('actions.send')} (${checkedEmails.length})`}
        </Button>
      </div>
    </Form>
  );
};

export default SendSprintForm;
