import { OrganizationEntity } from '@data';
import { useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from 'react-query';

import { useHistory } from 'react-router-dom';
import type { Chapter, IError, ListResponse, OrganizationSlackAuthorizeValues } from '../utils/types';
import * as api from '../api';
import * as queryKeys from '../constants/keys';

export const useOrganization = (organizationId: string): UseQueryResult<OrganizationEntity, IError> => {
  const queryClient = useQueryClient();
  return useQuery([queryKeys.ORGANIZATION, organizationId], () => api.fetchOrganization(organizationId), {
    staleTime: 1000,
    placeholderData: () =>
      queryClient
        .getQueryData<ListResponse<OrganizationEntity>>(queryKeys.ORGANIZATIONS)
        ?.items.find((org) => org.id === organizationId),
  });
};

export const useOrganizationChapters = (
  organizationId: string,
): UseQueryResult<{ items: Chapter[]; count: number }, IError> =>
  useQuery([queryKeys.ORGANIZATION, organizationId, 'chapters'], () => api.fetchOrganizationChapters(organizationId));

export const useOrganizationSlackAuthorize = (
  organizationId: string,
  values: OrganizationSlackAuthorizeValues,
): UseQueryResult<OrganizationEntity, IError> => {
  const { code } = values;
  if (code) {
    const history = useHistory();
    history.replace({
      search: '',
    });
  }
  return useQuery([queryKeys.ORGANIZATION, organizationId], () => api.authorizeSlack(organizationId, values), {
    enabled: !!code,
    staleTime: 1000,
  });
};

export const useUpdateOrganization = (
  organizationId: string,
): UseMutationResult<OrganizationEntity, IError, Partial<OrganizationEntity>, unknown> => {
  const queryClient = useQueryClient();
  return useMutation((values) => api.updateOrganization(organizationId, values), {
    onMutate: async (updatedOrganization: Partial<OrganizationEntity>) => {
      const previousOrg = queryClient.getQueryData([queryKeys.ORGANIZATION, organizationId]) as OrganizationEntity;
      const previousOrgs = queryClient.getQueryData(queryKeys.ORGANIZATIONS) as ListResponse<OrganizationEntity>;

      queryClient.setQueryData([queryKeys.ORGANIZATION, organizationId], (old: OrganizationEntity) => ({
        ...old,
        ...updatedOrganization,
      }));

      queryClient.setQueryData(queryKeys.ORGANIZATIONS, (old: ListResponse<OrganizationEntity>) => ({
        ...old,
        items: old.items.map((x) => (x.id === previousOrg.id ? { ...x, ...updatedOrganization } : x)),
      }));

      return { previousOrg, previousOrgs };
    },
    onError: (_, __, context) => {
      queryClient.setQueryData([queryKeys.ORGANIZATION, organizationId], context?.previousOrg);
      queryClient.setQueryData(queryKeys.ORGANIZATIONS, context?.previousOrgs);
    },
  });
};

export const useRevokeSlack = (
  organizationId: string,
): UseMutationResult<OrganizationEntity, IError, void, unknown> => {
  const queryClient = useQueryClient();
  return useMutation(() => api.revokeSlack(organizationId), {
    onMutate: async () => {
      const previousOrg = queryClient.getQueryData([queryKeys.ORGANIZATION, organizationId]) as OrganizationEntity;

      queryClient.setQueryData([queryKeys.ORGANIZATION, organizationId], (old: OrganizationEntity) => ({
        ...old,
        slackTeamInfo: null,
      }));

      return { previousOrg };
    },
    onError: (_, __, context) => {
      queryClient.setQueryData([queryKeys.ORGANIZATION, organizationId], context?.previousOrg);
    },
  });
};
