import React, { ReactElement, Dispatch } from 'react';
import cx from 'classnames';
import Button from '@mui/lab/LoadingButton';
import { tabs } from '@app/constants/app';
import { TabType } from '@app/utils/types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import ButtonGroup from '@mui/material/ButtonGroup';
import s from './styles.module.scss';

export const SprintToggler = ({
  activeTab,
  setActiveTab,
}: {
  activeTab: TabType;
  setActiveTab: Dispatch<TabType>;
}): ReactElement => {
  const { t } = useTranslation();
  const history = useHistory();

  const setTab = (tab: TabType) => {
    setActiveTab(tab);
    history.push({ search: `?tab=${tab}` });
  };

  return (
    <ButtonGroup>
      <Button
        className={cx(s.togglerTabsTab, activeTab === tabs.currentSprint && s.togglerTabsTabActive)}
        onClick={() => setTab(tabs.currentSprint)}
      >
        {t('tabs.currentSprint')}
      </Button>
      <Button
        className={cx(s.togglerTabsTab, activeTab === tabs.pastSprints && s.togglerTabsTabActive)}
        onClick={() => setTab(tabs.pastSprints)}
      >
        {t('tabs.pastSprints')}
      </Button>
    </ButtonGroup>
  );
};
