import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { AuthProvider as ContextProvider } from '@data';

interface AuthProviderProps extends RouteComponentProps<any> {
  children: React.ReactNode;
}

const AuthProvider = ({ location, history, children }: AuthProviderProps) => {
  const getLocation = () => location;

  const redirect = (to, state) => {
    if (state) {
      history.replace(to, state);
    } else {
      history.replace(to);
    }
  };

  return <ContextProvider value={{ redirect, getLocation }}>{children}</ContextProvider>;
};

export default withRouter(AuthProvider);
