import { tabs } from '@app/constants/app';
import { memberReportingPath, membersReportingListPath } from '@app/constants/url';
import { TabsType } from '@app/utils/types';
import React, { ReactElement } from 'react';
import { Route, Switch } from 'react-router-dom';
import MemberReporting from './memberReporting';
import MembersReportingList from './membersReportingList';

const MembersReportingRouter = (): ReactElement => {
  const params = new URLSearchParams(window.location.search);
  const [activeTab, setActiveTab] = React.useState((params.get('tab') as TabsType) || tabs.currentSprint);

  return (
    <Switch>
      <Route path={memberReportingPath(':organizationId', ':memberId')}>
        <MemberReporting activeTab={activeTab} setActiveTab={setActiveTab} />
      </Route>
      <Route path={membersReportingListPath(':organizationId')}>
        <MembersReportingList activeTab={activeTab} setActiveTab={setActiveTab} />
      </Route>
    </Switch>
  );
};

export default MembersReportingRouter;
