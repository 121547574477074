import React, { Dispatch, ReactElement, useMemo } from 'react';
import cx from 'classnames';
import { toFixedHours, getFullNameOrEmail } from '@app/utils';
import { Chapter, SprintProductChapter } from '@data';
import { useTranslation } from 'react-i18next';
import { Loader } from '@ui';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/lab/LoadingButton';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Storage from '@mui/icons-material/Storage';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import s from './styles.module.scss';

export interface BriefChaptersProps extends React.HTMLAttributes<HTMLDivElement> {
  briefReports?: SprintProductChapter[] | null;
  chapters?: Chapter[];
  isLoading?: boolean;
  setChapters: Dispatch<string[] | undefined>;
  selectedChapters?: string[];
}

type CellType<T> = {
  value?: T;
  isRole: boolean;
  isLastItem?: boolean;
};

const Cell = <T extends React.ReactNode>({ item }: { item: CellType<T> }) => {
  const { value, isRole, isLastItem } = item;
  return (
    <TableCell className={cx(s.cell, isRole && s.cellRole, isLastItem && s.cellLastItem)}>
      <Typography>{value}</Typography>
    </TableCell>
  );
};

export const BriefChapters = ({
  briefReports,
  setChapters,
  selectedChapters,
  chapters,
  isLoading,
}: BriefChaptersProps): ReactElement => {
  const { t } = useTranslation();
  const theme = useTheme();

  const rows = useMemo(() => {
    // row data doesn't support "not a primitive" values, so we have to transform data to primitive
    const stringifyCellItem = <T,>(value: T, isRole: boolean, isLastItem?: boolean) =>
      JSON.stringify({ value, isRole, isLastItem });

    return (
      briefReports?.reduce<any[]>((acc, curr, i) => {
        // we have specific type of table, so this implementation only for our particular case
        // 1️⃣ on a first place should be specific role (BE, FE, PM etc.) and total time in hours
        acc.push({
          id: `${curr.chapter}+${i}`,
          data: {
            chapter: stringifyCellItem(chapters?.find((chapter) => chapter.role === curr.chapter)?.text, true),
            timeSpent: stringifyCellItem(toFixedHours(curr.totalMinutes / 60), true),
          },
        });

        // 2️⃣ next should be members who were involved in this report
        curr.members.forEach((item, i) => {
          const isLastItem = curr.members.length - 1 === i;

          acc.push({
            id: `${curr.chapter}-${item.member.id}`,
            data: {
              chapter: stringifyCellItem(getFullNameOrEmail(item.member.user), false, isLastItem),
              timeSpent: stringifyCellItem(toFixedHours(item.totalMinutes / 60), false, isLastItem),
            },
          });
        });

        return acc;
      }, []) || []
    );
  }, [chapters, briefReports]);

  const chapterOptions = [
    { value: undefined, label: t('allChapters') },
    ...(chapters || []).map((x) => ({ value: x.role, label: x.text })),
  ];

  const filterValue = useMemo(
    () => chapterOptions.find((x) => x.value && selectedChapters?.includes(x.value)) ?? chapterOptions[0],
    [chapterOptions, selectedChapters],
  );

  const handleChapterChange = (option) => {
    setChapters(option.value && [option.value]);
  };

  if (isLoading) {
    return (
      <div className={s.emptyWrapper}>
        <Loader absolute={false} />
      </div>
    );
  }

  const filters = (
    <div className={s.tabHeader}>
      <div className={s.filterItems}>
        <Autocomplete
          className={s.filterItemsSelect}
          options={chapterOptions}
          onChange={(_e, value) => handleChapterChange(value)}
          getOptionLabel={(option) => option?.label}
          value={filterValue}
          fullWidth
          renderInput={(params) => <TextField {...params} name="chapter" id="chapter" label={t('filterByChapter')} />}
        />
      </div>
    </div>
  );

  return (
    <div>
      {briefReports && !briefReports.some((x) => x.totalMinutes > 0) ? (
        <>
          {filterValue?.value ? (
            <>
              {filters}
              <div className={s.emptyWrapper}>
                <Typography>{filterValue ? t('noEntriesFound') : t('noReportsYet')}</Typography>
                <Button
                  color="primary"
                  variant="outlined"
                  className={s.emptyButton}
                  onClick={() => setChapters(undefined)}
                >
                  {t('clearFilter')}
                </Button>
              </div>
            </>
          ) : (
            <div className={s.emptyWrapper}>
              <div className={s.iconBox}>
                <Storage />
              </div>

              <Typography>{t('noTimeRegistrations')}</Typography>
            </div>
          )}
        </>
      ) : (
        <>
          {filters}
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography fontWeight={theme.typography.fontWeightBold}>{t('table.involvedTeams')}</Typography>
                </TableCell>
                <TableCell>
                  <Typography fontWeight={theme.typography.fontWeightBold}>{t('table.timeSpent')}</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <Cell item={JSON.parse(row.data.chapter)} />
                  <Cell item={JSON.parse(row.data.timeSpent)} />
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </>
      )}
    </div>
  );
};
