import { createTheme, ThemeOptions } from '@mui/material/styles';
import type {} from '@mui/lab/themeAugmentation';

export const theme: ThemeOptions = createTheme({
  palette: {
    primary: {
      main: '#FF5722',
      dark: '#E64A18',
      light: '#FFEAE3',
      '900': '#A72800',
      '700': '#E64A18',
      '500': '#FF5722',
      '200': '#FFEAE3',
    },
    secondary: {
      main: '#652AD3',
      dark: '#441C8E',
      light: '#EDE6FB',
      '900': '#1D0053',
      '700': '#441C8E',
      '500': '#652AD3',
      '200': '#EDE6FB',
    },
    info: {
      main: '#2AD6A8',
      dark: '#1FAD87',
      light: '#DAF5EE',
      '900': '#00553E',
      '700': '#1FAD87',
      '500': '#2AD6A8',
      '200': '#DAF5EE',
    },
    error: {
      main: '#F44336',
      '500': '#F44336',
    },
    warning: {
      main: '#FFA000',
      '500': '#FFA000',
    },
    success: {
      main: '#00C853',
      '500': '#00C853',
    },
    background: {
      default: '#eceff1',
    },
  },
  typography: {
    button: {
      textTransform: 'none',
      fontWeight: 600,
      fontSize: '1rem',
      lineHeight: '1.5rem',
    },
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(ownerState.size === 'medium' && {
            padding: '10px 22px',
          }),
          ...(ownerState.variant === 'contained' && {
            ...(ownerState.size === 'medium' && {
              padding: '12px 22px',
            }),
            boxShadow: 'none',
            ':hover': {
              boxShadow: 'none',
            },
          }),
          ...(ownerState.variant === 'outlined' && {
            borderWidth: 2,
            borderColor: ownerState.color ? theme.palette[ownerState.color].main : theme.palette.primary.main,
            ':hover': {
              borderWidth: 2,
              backgroundColor: 'transparent',
              color: ownerState.color ? theme.palette[ownerState.color].dark : theme.palette.primary.dark,
              borderColor: ownerState.color ? theme.palette[ownerState.color].dark : theme.palette.primary.dark,
            },
          }),
        }),
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        notched: false,
      },
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.palette.common.white,
          'label + &': {
            marginTop: theme.spacing(2),
          },
        }),
        multiline: {
          padding: 0,
        },
        input: {
          padding: '12.5px 16px',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          padding: 5,
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        shrink: true,
        variant: 'standard',
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(118, 118, 118, 0.15)',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          overflowY: 'visible',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderBottom: `1px solid ${theme.palette.grey[200]}`,
          fontWeight: 600,
        }),
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          paddingTop: 16,
          overflowY: 'visible',
        },
      },
    },
  },
  shape: {
    borderRadius: 4,
  },
});
