import ContentPaste from '@mui/icons-material/ContentPaste';
import FolderOpen from '@mui/icons-material/FolderOpen';
import PeopleAltOutlined from '@mui/icons-material/PeopleAltOutlined';
import WidgetsOutlined from '@mui/icons-material/WidgetsOutlined';
import WorkOutline from '@mui/icons-material/WorkOutline';
import { styled, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import {
  clientsReportingPath,
  membersPath,
  membersReportingListPath,
  productsPath,
  productsReportingPath,
  sprintReportingPath,
} from '@app/constants/url';
import { Roles } from '@app/utils/types';
import { UserEntity } from '@data';
import { useOrganizations } from '@data/hooks/organizations';
import { useStore } from '@data/storages';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

const StyledNavLink = styled(NavLink)`
  align-items: center;
  display: flex;
  padding: 14px 18px;
  color: ${({ theme }) => theme.palette.grey[50]};
  gap: 10px;

  &.active {
    background-color: ${({ theme }) => theme.palette.grey[600]};
  }
`;

export const NavItems = ({ user }: { user: UserEntity }): JSX.Element => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { organization } = useStore();
  const { data: orgs } = useOrganizations(user.id);
  const role = orgs?.items.find((org) => org.id === organization?.id)?.role;

  const isPMorAdmin = role === Roles.admin || role === Roles.productManager;

  return (
    <>
      {role === Roles.client ? (
        <StyledNavLink to={clientsReportingPath(organization?.id || '')}>
          <ContentPaste />
          <Typography color={theme.palette.grey[50]}>{t('titles.reports')}</Typography>
        </StyledNavLink>
      ) : (
        <>
          <StyledNavLink to={sprintReportingPath(organization?.id || '')}>
            <WorkOutline />
            <Typography color={theme.palette.grey[50]}>{t('sidebar.sprintReporting')}</Typography>
          </StyledNavLink>
          {isPMorAdmin && (
            <>
              <StyledNavLink to={productsPath(organization?.id || '')}>
                <WidgetsOutlined />
                <Typography color={theme.palette.grey[50]}>{t('sidebar.products')}</Typography>
              </StyledNavLink>
              <StyledNavLink to={membersPath(organization?.id || '')}>
                <PeopleAltOutlined />
                <Typography color={theme.palette.grey[50]}>{t('sidebar.members')}</Typography>
              </StyledNavLink>
              <StyledNavLink to={membersReportingListPath(organization?.id || '')}>
                <FolderOpen />
                <Typography color={theme.palette.grey[50]}>{t('sidebar.membersReporting')}</Typography>
              </StyledNavLink>
              <StyledNavLink to={productsReportingPath(organization?.id || '')}>
                <ContentPaste />
                <Typography color={theme.palette.grey[50]}>{t('titles.productsReporting')}</Typography>
              </StyledNavLink>
            </>
          )}
        </>
      )}
    </>
  );
};
