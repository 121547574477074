import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { GenericToggler } from '@app/components/core';
import { MembershipStatus } from '@data';
import React, { Dispatch, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Unstable_Grid2';

export interface SprintHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  tabs: MembershipStatus[];
  activeTab: MembershipStatus;
  setActiveTab: Dispatch<MembershipStatus>;
}

const SprintHeader = ({ tabs, activeTab, setActiveTab }: SprintHeaderProps): ReactElement => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Grid mb={3} container spacing={2} justifyContent="space-between" alignItems="center">
      <Grid xs={12} md="auto">
        <Typography variant="h4" fontWeight={theme.typography.fontWeightBold}>
          {t('titles.members')}
        </Typography>
      </Grid>
      <Grid xs={12} md="auto">
        <GenericToggler tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
      </Grid>
    </Grid>
  );
};
export default SprintHeader;
