import { UserEntity } from '@data';
import React, { ReactElement, useEffect } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { Link, useLocation } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import { Menu } from '@mui/icons-material';
import ProfilePopover from './profilePopover';
import { NavItems } from './sidebar-components';
import panencoLogo from './panenco-logo.png';

const StyledDrawer = styled(Drawer)`
  width: 240px;
  flex-shrink: 0;
  .MuiDrawer-paper {
    width: 240px;
    box-sizing: border-box;
    background-color: ${({ theme }) => theme.palette.grey[900]};
  }
`;

const StyledImage = styled('img')`
  width: 100%;
  height: 100%;
  object-fit: contain;
  max-width: 100px;

  ${(props) => props.theme.breakpoints.up('md')} {
    max-width: 150px;
  }
`;

export interface SidebarProps extends React.HTMLAttributes<HTMLDivElement> {
  user: UserEntity;
}

const Sidebar = ({ user }: SidebarProps): ReactElement => {
  const location = useLocation();
  const theme = useTheme();

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    if (mobileOpen) {
      setMobileOpen(false);
    }
  }, [location]);

  const logoImage = <StyledImage src={panencoLogo} alt="logo" />;

  const drawerContent = (
    <Box display="flex" flexDirection="column" justifyContent="space-between" alignItems="flex-start" height="100%">
      <Box width="100%">
        <Box component={Link} to="/" p={3} display="block">
          {logoImage}
        </Box>
        <Divider />
        <nav>
          <NavItems user={user} />
        </nav>
      </Box>
      <ProfilePopover user={user} />
    </Box>
  );

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          display: { xs: 'flex', md: 'none' },
          backgroundColor: theme.palette.grey[900],
        }}
      >
        <Toolbar
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box display="flex" alignItems="center">
            {logoImage}
          </Box>
          <IconButton
            color="inherit"
            aria-label="open menu"
            edge="end"
            onClick={handleDrawerToggle}
            sx={{ ml: 2, display: { md: 'none' } }}
          >
            <Menu />
          </IconButton>
        </Toolbar>
      </AppBar>
      <StyledDrawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        anchor="right"
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: 'block', md: 'none' },
        }}
      >
        {drawerContent}
      </StyledDrawer>
      <StyledDrawer
        variant="permanent"
        open
        anchor="left"
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: 'none', md: 'block' },
        }}
      >
        {drawerContent}
      </StyledDrawer>
    </>
  );
};

export default Sidebar;
