import {
  productsReportingPath,
  sprintReportingPath,
  membersPath,
  membersReportingListPath,
  productsPath,
  baseAppPath,
  clientsReportingPath,
} from '@app/constants/url';
import { UserEntity, withAuth } from '@data';
import React, { ReactElement } from 'react';
import { useStore } from '@data/storages';
import { Roles } from '@app/utils/types';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Loader } from '@ui';
import { Helmet } from 'react-helmet';
import SprintReporting from './sprintReporting';
import Members from './members';
import MembersReporting from './membersReporting';
import Products from './products';
import ProductsReporting from './productsReporting';
import ClientReporting from './clientReporting';

export interface OrganizationsProps {
  user: UserEntity;
}

const AuthorizedProductsReportingRoute = withAuth({
  redirectTo: baseAppPath,
  allowedRoles: [Roles.admin, Roles.productManager],
  preloader: <Loader />,
})(ProductsReporting);

const AuthorizedMembersRoute = withAuth({
  redirectTo: baseAppPath,
  allowedRoles: [Roles.admin, Roles.productManager],
  preloader: <Loader />,
})(Members);

const AuthorizedMembersReportingListRoute = withAuth({
  redirectTo: baseAppPath,
  allowedRoles: [Roles.admin, Roles.productManager],
  preloader: <Loader />,
})(MembersReporting);

const AuthorizedProductsRoute = withAuth({
  redirectTo: baseAppPath,
  allowedRoles: [Roles.admin, Roles.productManager],
  preloader: <Loader />,
})(Products);

const AuthorizedSprintReportingRoute = withAuth({
  redirectTo: baseAppPath,
  allowedRoles: [Roles.admin, Roles.productManager, Roles.teamMember],
  preloader: <Loader />,
})(SprintReporting);

const AuthorizedClientsReportingRoute = withAuth({
  redirectTo: baseAppPath,
  allowedRoles: [Roles.client],
  preloader: <Loader />,
})(ClientReporting);

const Organization = ({ user }: OrganizationsProps): ReactElement => {
  const { organization } = useStore();

  return (
    <>
      <Helmet>
        <title>{organization?.name} sprint reporting</title>
      </Helmet>
      <Switch>
        <Route path={productsReportingPath(':organizationId')}>
          <AuthorizedProductsReportingRoute />
        </Route>
        <Route path={clientsReportingPath(':organizationId')}>
          <AuthorizedClientsReportingRoute />
        </Route>
        <Route path={sprintReportingPath(':organizationId')}>
          <AuthorizedSprintReportingRoute user={user} />
        </Route>
        <Route path={membersPath(':organizationId')}>
          <AuthorizedMembersRoute user={user} />
        </Route>
        <Route path={membersReportingListPath(':organizationId')}>
          <AuthorizedMembersReportingListRoute />
        </Route>
        <Route path={productsPath(':organizationId')}>
          <AuthorizedProductsRoute />
        </Route>
        <Redirect
          to={
            organization?.role === Roles.client
              ? clientsReportingPath(organization?.id || ':organizationId')
              : sprintReportingPath(organization?.id || ':organizationId')
          }
        />
      </Switch>
    </>
  );
};

export default Organization;
