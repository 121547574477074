import React, { ReactNode } from 'react';
import * as Sentry from '@sentry/browser';
import Typography from '@mui/material/Typography';
import s from './styles.module.scss';

type ErrorBoundaryProps = {
  children: ReactNode;
};

type ErrorBoundaryState = {
  hasError: boolean;
};

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(e: Error): { hasError: boolean } {
    console.log(e);
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo): void {
    Sentry.captureException(error, {
      extra: {
        info: errorInfo,
      },
    });
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      return (
        <div className={s.warning}>
          <Typography variant="h4">Oops, something went wrong!</Typography>
        </div>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
