import React from 'react';
import cx from 'classnames';

import s from './styles.module.scss';

export enum LoaderSize {
  small = 'small',
  medium = 'medium',
  large = 'large',
}
export interface LoaderProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}
const BaseLoader: React.FC<LoaderProps> = ({ className, ...props }: LoaderProps) => (
  <div className={cx(s.baseLoader, className)} {...props} />
);

export const ContentLoader: React.FC = () => <BaseLoader className={s.contentLoader} />;

export const Loader = ({
  absolute = true,
  className,
  size = LoaderSize.medium,
}: {
  absolute?: boolean;
  className?: string;
  size?: LoaderSize;
}): JSX.Element => {
  const sizeMapper = {
    [s.loaderSmall]: size === LoaderSize.small,
    [s.loaderMedium]: size === LoaderSize.medium,
    [s.loaderLarge]: size === LoaderSize.large,
  };

  return !absolute ? (
    <BaseLoader className={cx(s.loader, s.loaderStatic, sizeMapper, className)} />
  ) : (
    <div className={s.wrapper}>
      <BaseLoader className={cx(s.loader, sizeMapper, className)} />
    </div>
  );
};
