import TokenExpiredComponent from '@app/components/shared/tokenExpiredComponent';
import AlertPopUp from '@app/components/core/alert-popup';
import Layout from '@app/components/core/layout';
import { maxWidthMobile } from '@app/constants/app';
import { clientReportingSlugPath } from '@app/constants/url';
import { ScreenSizeProvider } from '@app/utils/context';
import ErrorBoundary from '@app/utils/error-boundary';
import { DecodedJwt, useGetUserData, useRefreshToken, UserEntity, useResendSprintProduct } from '@data';
import { useOrganizations } from '@data/hooks/organizations';
import { useStore } from '@data/storages';
import { apiStore } from '@data/store';
import { jwtDecode } from 'jwt-decode';
import React, { useCallback, useEffect, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { matchPath, useHistory } from 'react-router-dom';
import { Loader } from '@ui';

import GlobalStyles from '@mui/material/GlobalStyles';
import { useTheme } from '@mui/material/styles';
import { GoogleLogin } from '@react-oauth/google';
import Box from '@mui/material/Box';
import { ACCESS_TOKEN_STORAGE_KEY, REFRESH_TOKEN_STORAGE_KEY } from '@app/constants/app/storage';
import App from './pages';

const mql = window.matchMedia(`(max-width: ${maxWidthMobile})`);

const PanencoBot = () => {
  const theme = useTheme();
  const [isMobile, setIsMobile] = useState(mql.matches);
  const [isPopUpBlocked, setPopUpIsBlocked] = useState(false);

  const { mutate: getUserData } = useGetUserData();
  const accessToken = localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY);
  const refreshToken = new URLSearchParams(window.location.search)?.get('token');
  const history = useHistory();
  const [isRefreshFailed, setRefreshFailed] = useState<boolean | undefined>(false);
  const { mutate: resendSprint } = useResendSprintProduct();
  const { tokenData, setTokenData } = useStore();
  const { mutate: refresh } = useRefreshToken();

  const clientUrlMatch = matchPath<{ organizationId: string; productId: string; sprintId: string }>(
    history.location.pathname,
    {
      path: clientReportingSlugPath,
    },
  );

  const handleRefresh = useCallback(() => {
    const { refreshFailed } = apiStore.getState();
    setRefreshFailed(refreshFailed);
  }, []);

  useEffect(() => {
    const unsubFromRefresh = apiStore.subscribe(handleRefresh, (state) => state.refreshFailed);
    return () => {
      unsubFromRefresh();
      apiStore.destroy();
    };
  }, []);

  const handleMqlChange = () => {
    setIsMobile(mql.matches);
  };

  useEffect(() => {
    // For Safari < 14
    if (typeof mql.addListener === 'function') {
      mql.addListener(handleMqlChange);
    } else {
      mql.addEventListener('change', handleMqlChange);
    }

    return () => {
      // For Safari < 14
      if (typeof mql.removeListener === 'function') {
        mql.removeListener(handleMqlChange);
      } else {
        mql.removeEventListener('change', handleMqlChange);
      }
    };
  }, []);

  useEffect(() => {
    if (refreshToken) {
      localStorage.setItem(REFRESH_TOKEN_STORAGE_KEY, refreshToken);
      refresh(
        { token: refreshToken },
        {
          onSuccess: () => {
            history.replace({
              search: '',
            });
          },
        },
      );
    }
  }, [refreshToken]);
  let userData: UserEntity | undefined;

  if (accessToken) {
    const decodedJwt = jwtDecode<DecodedJwt>(accessToken);
    userData = { ...decodedJwt, id: decodedJwt.userId };
    if (!tokenData) {
      setTokenData(decodedJwt);
    }
  }

  const { data: organizations } = useOrganizations(userData?.id);

  if (!accessToken && isPopUpBlocked && !clientUrlMatch)
    return <AlertPopUp handleClick={() => setPopUpIsBlocked(false)} />;

  if (isRefreshFailed && clientUrlMatch?.isExact) {
    const {
      params: { productId, sprintId },
    } = clientUrlMatch;
    return <TokenExpiredComponent sendMails={() => resendSprint({ productId, sprintId })} />;
  }

  if (!accessToken || !userData || !organizations) {
    return (
      <>
        {!accessToken && (
          <Box zIndex={11} position="absolute" top={10} left={10}>
            <GoogleLogin
              onSuccess={(credentialResponse) => {
                if (credentialResponse?.credential) {
                  getUserData({ token: credentialResponse.credential });
                }
              }}
              onError={() => setPopUpIsBlocked(true)}
            />
          </Box>
        )}
        <Loader />
      </>
    );
  }

  return (
    <ScreenSizeProvider value={{ isMobile }}>
      <GlobalStyles styles={{ body: { backgroundColor: theme.palette.background.default } }} />

      {userData && (
        <Layout user={userData} organizations={organizations?.items}>
          <ErrorBoundary>
            <App user={userData} />
          </ErrorBoundary>
        </Layout>
      )}
    </ScreenSizeProvider>
  );
};

export default hot(PanencoBot);
