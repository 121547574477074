import { fullDate, renderDate, toFixedHours, createMarkup } from '@app/utils';
import { ReportEntity, WorkDayUserEntity } from '@data';
import cx from 'classnames';
import React from 'react';
import Accordion from '@app/components/core/accordion';
import { useTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';
import EditOutlined from '@mui/icons-material/EditOutlined';
import Add from '@mui/icons-material/Add';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import s from './styles.module.scss';

interface SprintDayReportProps {
  workDay: WorkDayUserEntity;
  todayDate: Date;
  showAddForm: (workDay: string, productIds?: string[]) => void;
  showEditForm: (report: ReportEntity) => void;
  showDeleteForm: (report: ReportEntity) => void;
}

const SprintDayReport: React.FC<SprintDayReportProps> = ({
  workDay,
  todayDate,
  showAddForm,
  showEditForm,
  showDeleteForm,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <div className={s.pageContentWorkDay}>
      <div
        className={
          workDay.date === fullDate(todayDate) && workDay.reports.length === 0
            ? cx(s.pageContentWorkDayHeader, s.pageContentWorkDayHeaderCurrent)
            : s.pageContentWorkDayHeader
        }
      >
        <Box display="flex" gap={3} alignItems="center" justifyContent="space-between" width="100%">
          <Grid container flex={1}>
            <Grid xs={12} md>
              <Typography
                variant="h6"
                fontWeight={theme.typography.fontWeightBold}
                color={
                  workDay.reports.length > 0 || workDay.date === fullDate(todayDate)
                    ? theme.palette.grey[900]
                    : theme.palette.grey[600]
                }
              >
                {renderDate(workDay.date)}
              </Typography>
            </Grid>
            <Grid xs={12} md="auto" display="flex" alignItems="center">
              <Typography
                fontWeight={theme.typography.fontWeightBold}
                color={
                  workDay.reports.length > 0 || workDay.date === fullDate(todayDate)
                    ? theme.palette.grey[900]
                    : theme.palette.grey[600]
                }
              >
                {t('hours', { amount: toFixedHours(workDay.totalMinutes / 60) })}
              </Typography>
            </Grid>
          </Grid>
          <Box>
            <IconButton
              className={s.pageContentWorkDayHeaderInfoButton}
              onClick={() =>
                showAddForm(
                  workDay.date,
                  workDay.reports.map((report) => report.involvement.product.id),
                )
              }
            >
              <Add />
            </IconButton>
          </Box>
        </Box>
      </div>
      {workDay.reports.length > 0 && (
        <div className={s.pageContentWorkDayReports}>
          {workDay.reports.map((report) => (
            <Accordion
              key={report.id}
              titleContent={
                <Box display="flex" gap={3} justifyContent="space-between" alignItems="center">
                  <Grid container textAlign="start" flex={1} justifyContent="space-between">
                    <Grid xs={12} md="auto">
                      <Typography>{report.involvement.product.name}</Typography>
                    </Grid>
                    <Grid xs={12} md="auto">
                      <Typography>{t('hours', { amount: toFixedHours(report.minutes / 60) })}</Typography>
                    </Grid>
                  </Grid>

                  <Box display="flex" flexWrap="wrap" gap={1}>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        showEditForm(report);
                      }}
                      className={s.pageContentWorkDayHeaderInfoButton}
                    >
                      <EditOutlined />
                    </IconButton>

                    <IconButton
                      className={cx(
                        s.pageContentWorkDayReportsReportHeaderInfoButtonsRemove,
                        s.pageContentWorkDayHeaderInfoButton,
                      )}
                      onClick={(e) => {
                        e.stopPropagation();
                        showDeleteForm(report);
                      }}
                    >
                      <DeleteOutline />
                    </IconButton>
                  </Box>
                </Box>
              }
            >
              <div className={s.pageContentWorkDayReportsReportContent}>
                <div className={s.pageContentWorkDayReportsReportContentBlock}>
                  <Typography
                    className={s.pageContentWorkDayReportsReportContentBlockTitle}
                    fontWeight={theme.typography.fontWeightBold}
                  >
                    {t('titles.todayWithoutDate')}
                  </Typography>
                  <div className={s.pageContentWorkDayReportsReportContentBlockDescription}>
                    <div
                      dangerouslySetInnerHTML={createMarkup(report.today)}
                      className={s.pageContentWorkDayReportsReportContentBlockDescriptionText}
                    />
                  </div>
                </div>
              </div>
            </Accordion>
          ))}
        </div>
      )}
    </div>
  );
};

export default SprintDayReport;
