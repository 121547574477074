import Button from '@mui/lab/LoadingButton';
import { SprintToggler } from '@app/components/core';
import { daysInSprint, tabs } from '@app/constants/app';
import { productReportingPath } from '@app/constants/url';
import { addDays, capitalize, shortDate, toFixedHours, usePrevious } from '@app/utils';
import { ProductsReportingNavState, TabsType } from '@app/utils/types';
import {
  OrganizationParamsType,
  PmProductsInfiniteData,
  ProductEntity,
  ProductStatus,
  SprintProductEntity,
  SprintProductStatus,
  useCreateSprintProduct,
  useInfiniteListPmProducts,
  useListProducts,
  useListSprintProductClients,
  useListSprints,
  useSendSprintProduct,
  useUpdateSprintProduct,
} from '@data';
import { useOrganizationChapters } from '@data/hooks/organization';
import { addYears } from 'date-fns';
import React, { ReactElement, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Loader } from '@ui';
import ImportContacts from '@mui/icons-material/ImportContacts';
import Inventory2Outlined from '@mui/icons-material/Inventory2Outlined';
import Send from '@mui/icons-material/Send';
import TaskAlt from '@mui/icons-material/TaskAlt';
import ShoppingCart from '@mui/icons-material/ShoppingCart';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import TableContainer from '@mui/material/TableContainer';
import MUILink from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import SendSprintPopup from '../sendSprint/SendSprintPopup';

const ProductsReportingList = (): ReactElement => {
  const theme = useTheme();
  const { state } = useLocation<ProductsReportingNavState>();
  const { organizationId } = useParams<OrganizationParamsType>();
  const [activeTab, setActiveTab] = useState(state?.activeTab || tabs.currentSprint);

  const rangeForSprints = useMemo(
    () =>
      activeTab === tabs.currentSprint
        ? { from: new Date(), to: addDays(new Date(), daysInSprint - 1) }
        : { to: new Date() },
    [activeTab],
  );

  const { data: chapters } = useOrganizationChapters(organizationId);
  const { data: sprints } = useListSprints(rangeForSprints);
  const currentSprint = sprints?.items.sort(
    (a, b) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime(),
  )[0];

  const productPmParams = {
    from: currentSprint?.startDate,
    to: currentSprint?.endDate,
    sprintNumber: currentSprint?.number,
    sprintId: currentSprint?.id,
  };

  const {
    data: pmProducts,
    fetchNextPage,
    isFetching,
  } = useInfiniteListPmProducts(organizationId, productPmParams, !!currentSprint, sprints?.items);

  const isFetchingPrev = usePrevious(isFetching);

  if (activeTab === TabsType.pastSprints && pmProducts?.pages.length === 1 && isFetchingPrev && !isFetching)
    fetchNextPage();

  const { data: products } = useListProducts(organizationId, {
    statuses: [ProductStatus.active, ProductStatus.support],
  });

  const { mutate: createSprintProduct } = useCreateSprintProduct(organizationId, productPmParams);
  const { mutate: updateSprintProduct } = useUpdateSprintProduct(organizationId, productPmParams);

  const { t } = useTranslation();

  const [sendModalSprintProduct, setSendModalOpen] = useState<SprintProductEntity | undefined>(undefined);
  const { data: sprintProductClients } = useListSprintProductClients(sendModalSprintProduct?.id);
  const { mutate: sendSprint } = useSendSprintProduct();

  const [statusFilter, setStatusFilter] = useState(state?.statusFilter);
  const [productsFilter, setProductsFilter] = useState(state?.productsFilter || []);

  const renderActions = ({ sprintProducts, id: productId }: ProductEntity, sprintId?: string) => {
    const sprintProduct = sprintProducts?.[0];
    const updateStatus = (status: SprintProductStatus) =>
      updateSprintProduct({ id: sprintProduct?.id, status, sprintId, productId });

    const reopenButton = (
      <Button
        size="small"
        variant="text"
        startIcon={<ImportContacts />}
        key="reopen"
        onClick={() => updateStatus(SprintProductStatus.open)}
      >
        {t('actions.reopen')}
      </Button>
    );

    const closeButton = (
      <Button
        size="small"
        variant="text"
        startIcon={<Inventory2Outlined />}
        key="close"
        onClick={() =>
          !sprintProduct
            ? createSprintProduct({ status: SprintProductStatus.closed, sprintId, productId })
            : updateStatus(SprintProductStatus.closed)
        }
      >
        {t('actions.close')}
      </Button>
    );

    const approveButton = (
      <Button
        size="small"
        variant="text"
        startIcon={<TaskAlt />}
        key="approve"
        onClick={() => updateStatus(SprintProductStatus.approved)}
      >
        {t('actions.approve')}
      </Button>
    );

    const sendButton = (
      <Button
        size="small"
        variant="text"
        key="send"
        startIcon={<Send />}
        onClick={() => setSendModalOpen(sprintProduct)}
      >
        {`${t('actions.send')} (${sprintProduct?.clientCount || 0})`}
      </Button>
    );

    const invoiceButton = (
      <Button
        startIcon={<ShoppingCart />}
        size="small"
        variant="text"
        key="invoice"
        onClick={() => updateStatus(SprintProductStatus.invoiced)}
      >
        {t('actions.invoice')}
      </Button>
    );

    const buttonMap = {
      [SprintProductStatus.open]: [closeButton],
      [SprintProductStatus.closed]: [reopenButton, approveButton, sendButton],
      [SprintProductStatus.approved]: [invoiceButton, sendButton],
      [SprintProductStatus.invoiced]: [sendButton],
    };

    return buttonMap[sprintProduct?.status || SprintProductStatus.open];
  };

  const statusOptions = Object.values(SprintProductStatus)
    .sort((a, b) => a.localeCompare(b))
    .map((x) => ({ value: x, label: x }));

  const productOptions = (products?.items || []).map((product) => ({
    value: product.id,
    label: capitalize(product.name),
  }));

  const productFilterFunction = (product: ProductEntity): boolean => {
    const sprintProduct = product.sprintProducts?.[0];
    const statusCondition =
      !statusFilter ||
      (statusFilter === SprintProductStatus.open && !sprintProduct) ||
      sprintProduct?.status === statusFilter;
    const productCondition = !productsFilter.length || productsFilter.includes(product.id);
    return statusCondition && productCondition;
  };

  const getFilteredProducts = (page: PmProductsInfiniteData): ProductEntity[] =>
    page.items.filter(productFilterFunction);

  const getRowData = (products: ProductEntity[], sprintId: string) =>
    products.map((product) => {
      const status = product.sprintProducts?.[0]?.status || SprintProductStatus.open;

      return {
        ...product,
        chapters: product.chapters.map((chapter) => chapters?.items?.find((x) => x.role === chapter)?.text).join(', '),
        hours: toFixedHours(product.totalMinutes / 60),
        status,
        actions: (
          <Box display="flex" gap={1}>
            {renderActions(product, sprintId)}
          </Box>
        ),
        sprintId,
      };
    });

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t('titles.products'),
      flex: 1,
      renderCell: (params) => (
        <MUILink
          component={Link}
          to={{
            pathname: productReportingPath(organizationId, params.row.id, params.row.sprintId),
            state: { activeTab, productsFilter, statusFilter },
          }}
        >
          <Typography variant="body2">{capitalize(params.row.name)}</Typography>
        </MUILink>
      ),
    },
    {
      field: 'hours',
      headerName: t('totalHours'),
      flex: 1,
    },
    {
      field: 'status',
      headerName: t('status'),
      flex: 1,
      renderCell: (params) => <Chip variant="filled" label={capitalize(params.row.status)} />,
    },
    {
      field: 'actions',
      headerName: '',
      flex: 1,
      sortable: false,
      minWidth: 300,
      renderCell: (params) => <div>{renderActions(params.row, params.row.sprintId)}</div>,
    },
  ];
  return (
    <>
      <Box marginBottom={3} gap={3} display="flex" justifyContent="space-between">
        <Typography variant="h4" fontWeight={theme.typography.fontWeightBold}>
          {t('titles.productsReporting')}
        </Typography>
        <SprintToggler activeTab={activeTab} setActiveTab={setActiveTab} />
      </Box>

      <Box marginBottom={3} display="flex" gap={2}>
        <Box width="100%" maxWidth={300}>
          <Autocomplete
            fullWidth
            multiple
            value={productsFilter?.map((x) => productOptions.find((y) => y.value === x))}
            options={productOptions}
            getOptionLabel={(option) => option?.label || ''}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={!productsFilter.length ? t('allProducts') : undefined}
                label={t('titles.product')}
              />
            )}
            onChange={(_e, options) => {
              setProductsFilter(options.map((x: any) => x.value));
            }}
          />
        </Box>
        <Box width="100%" maxWidth={300}>
          <Autocomplete
            options={statusOptions}
            onChange={(_e, option) => setStatusFilter(option?.value || '')}
            getOptionLabel={(option) => option?.label}
            value={statusOptions.find((x) => x.value === statusFilter)}
            fullWidth
            renderInput={(params) => (
              <TextField {...params} name="status" label={t('status')} placeholder={t('allStatuses')} />
            )}
          />
        </Box>
      </Box>

      {/* Fix loader, hasMore is incorrect */}
      <InfiniteScroll
        dataLength={pmProducts?.pages?.length || 0}
        next={() => fetchNextPage()}
        hasMore={
          activeTab !== TabsType.currentSprint &&
          !pmProducts?.pages.some((x) => x.sprintNumber === 1 && new Date(x.from) < addYears(new Date(), -1))
        }
        loader={<div />}
      >
        <div>
          {pmProducts ? (
            pmProducts.pages.map((page) => {
              const rows = getRowData(getFilteredProducts(page), page.sprintId);
              return (
                <Box marginBottom={3} key={page.sprintNumber}>
                  <Typography marginTop={3} marginBottom={2} variant="h6">
                    {`Sprint ${page.sprintNumber} (${shortDate(page.from)} - ${shortDate(page.to)})`}
                  </Typography>
                  <TableContainer component={Paper}>
                    <DataGrid
                      rows={rows}
                      columns={columns}
                      autoHeight
                      paginationModel={{ page: 0, pageSize: rows.length }}
                      disableColumnFilter
                      disableColumnMenu
                      disableColumnSelector
                      disableDensitySelector
                      disableRowSelectionOnClick
                      loading={isFetching}
                      initialState={{
                        sorting: {
                          sortModel: [{ field: 'name', sort: 'asc' }],
                        },
                      }}
                    />
                  </TableContainer>
                </Box>
              );
            })
          ) : (
            <Loader />
          )}
        </div>
      </InfiniteScroll>
      {sendModalSprintProduct && (
        <SendSprintPopup
          handleClick={() => setSendModalOpen(undefined)}
          sendSprint={sendSprint}
          sprintProduct={sendModalSprintProduct}
          sprintProductClients={sprintProductClients?.items}
        />
      )}
    </>
  );
};

export default ProductsReportingList;
