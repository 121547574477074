import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { jwtDecode } from 'jwt-decode';
import { v4 as uuidv4 } from 'uuid';
import { StoreApi } from 'zustand';

import { ACCESS_TOKEN_STORAGE_KEY, REFRESH_TOKEN_STORAGE_KEY } from '@app/constants/app/storage';
import { refresh } from './api';

import type { ApiStore } from './utils/types';
/* eslint-disable no-shadow */

enum APIMethod {
  POST = 'post',
  PATCH = 'patch',
  PUT = 'put',
  DELETE = 'delete',
  GET = 'get',
}
class HttpClient {
  private readonly axios: AxiosInstance;

  private readonly store: StoreApi<ApiStore>;

  public constructor(config: AxiosRequestConfig, store: StoreApi<ApiStore>) {
    this.axios = axios.create(config);
    this.store = store;
    this.axios.interceptors.response.use(this.successInterceptor, this.errorInterceptor);
    this.axios.interceptors.request.use(this.requestInterceptor);
  }

  isAuthUrl = (url) => /\/api\/auth\/google/.test(url);

  isRefreshUrl = (url) => /\/auth\/refresh/.test(url);

  private requestInterceptor = async (config: InternalAxiosRequestConfig) => {
    const newConfig = { ...config };
    const token = localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY);
    const refreshToken = localStorage.getItem(REFRESH_TOKEN_STORAGE_KEY);
    if (token) {
      const { exp }: { exp: number } = jwtDecode(token);
      const tokenHasExpired = new Date().getTime() / 1000 > Number(exp) - 5;
      if (tokenHasExpired && refreshToken && !this.isRefreshUrl(config.url)) {
        // after successful refreshing let's get new token and do request with updated token
        // check logic in the successInterceptor for refreshUrl
        const freshToken = await refresh(refreshToken).then(() => localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY));

        if (freshToken && newConfig.headers) {
          newConfig.headers['x-auth'] = `${freshToken}`;
          return newConfig;
        }

        // if sth went wrong we are canceling request
        return {
          ...config,
          cancelToken: new axios.CancelToken((cancel) => cancel(`Cancel request ${config.url}`)),
        };
      }

      if (!tokenHasExpired && newConfig.headers) {
        newConfig.headers['x-auth'] = `${token}`;
      }
    }

    return newConfig;
  };

  private successInterceptor = (res: AxiosResponse) => {
    const {
      data,
      request,
      config: { method },
    } = res;
    if (method !== APIMethod.GET && !this.isRefreshUrl(request.responseURL)) {
      this.store.setState({ success: { id: uuidv4(), message: 'Success!' } });
    }
    if (this.isAuthUrl(request.responseURL)) {
      localStorage.setItem(ACCESS_TOKEN_STORAGE_KEY, res.headers['x-auth']);
      localStorage.setItem(REFRESH_TOKEN_STORAGE_KEY, res.headers['x-refresh-token']);
    }

    if (this.isRefreshUrl(request.responseURL)) {
      // here we set new value to token
      localStorage.setItem(ACCESS_TOKEN_STORAGE_KEY, res.headers['x-auth']);
    }

    return data;
  };

  private errorInterceptor = (error): void => {
    if (error.response?.status === 401) {
      localStorage.clear();
      window.location.reload();
    }

    if (this.isRefreshUrl(error.config?.url)) {
      this.store.setState({ refreshFailed: true });
    }
    if (error?.response?.data) {
      this.store.setState({ error: { detail: error.response.data?.message || 'Error' } });
      throw Error();
    } else {
      this.store.setState({
        error: {
          detail: 'Connection error',
        },
      });
      throw new Error('No connection');
    }
  };

  get = (...args) => this.axios.get.apply(this, args);

  post = (...args) => this.axios.post.apply(this, args);

  patch = (...args) => this.axios.patch.apply(this, args);

  put = (...args) => this.axios.put.apply(this, args);

  delete = (...args) => this.axios.delete.apply(this, args);
}

export default HttpClient;
