export const getFullNameOrEmail = (
  user:
    | {
        firstName?: string | null;
        lastName?: string | null;
        email?: string | null;
      }
    | undefined
    | null,
): string => {
  if (!user) return '';

  // if no first and last names return email
  if (!user.firstName && !user.lastName) {
    return `${user.email || ''}`;
  }

  return `${user.firstName || ''} ${user.lastName || ''}`.trim();
};
