const prefix = (name: string): string => `panenco-bot/${name}`;

export const REPORTS = prefix('reports');
export const PRODUCT = prefix('product');
export const SPRINT_PRODUCT_CLIENTS = prefix('sprint-product-clients');
export const SPRINT_PRODUCT_CLIENT_URL = prefix('sprint-product-client-url');
export const PRODUCT_CLIENTS = prefix('product-clients');
export const PRODUCTS = prefix('products');
export const PM_PRODUCTS = prefix('pm-products');
export const PM_SPRINT_PRODUCT_REPORTS = prefix('pm-sprint-products-reports');
export const PM_MEMBERS = prefix('pm-members');
export const PM_MEMBERS_INFINITE = prefix('pm-members-infinite');
export const PM_SPRINT_PRODUCTS = prefix('pm-sprint-products');
export const SPRINT = prefix('sprint');
export const SPRINTS = prefix('sprints');
export const TOKEN = prefix('token');
export const ROLES = prefix('roles');
export const ORGANIZATIONS = prefix('organizations');
export const ORGANIZATION = prefix('organization');
export const CLIENT_SPRINT_PRODUCTS = prefix('client-sprint-products');
