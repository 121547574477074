import * as React from 'react';
import cx from 'classnames';
import { v4 as uuid } from 'uuid';
import Typography from '@mui/material/Typography';
import Done from '@mui/icons-material/Done';
import Remove from '@mui/icons-material/Remove';
import s from './styles.module.scss';

export const sizeToString = (size?: number | string): string | undefined => {
  if (!size) return undefined;
  if (typeof size === 'number') return `${size}px`;
  return size;
};

export interface SwitchProps extends React.HTMLAttributes<HTMLElement> {
  value: boolean;
  size?: number;
  inputRef?: React.Ref<HTMLInputElement>;
  text?: string;
}

export const Switch = ({ inputRef, value = false, size = 48, className, text, ...props }: SwitchProps): JSX.Element => {
  const id = uuid();

  return (
    <label className={s.wrapper} htmlFor={id}>
      <div className={cx(s.switch, className)} style={{ height: `calc(${size}px / 2)`, width: `${size}px` }}>
        <input ref={inputRef} id={id} type="checkbox" checked={value} {...props} />
        <div className={s.slider} style={{ borderRadius: `calc(${size}px / 2)` }}>
          <span className={s.round} style={{ height: `calc(${size}px / 2 - 4px)`, width: `calc(${size}px / 2 - 4px)` }}>
            {value ? <Done className={s.icon} /> : <Remove className={s.icon} />}
          </span>
        </div>
      </div>

      {text && <Typography>{text}</Typography>}
    </label>
  );
};
