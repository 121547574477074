import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/lab/LoadingButton';
import s from './styles.module.scss';

export interface AlertPopUpProps extends React.HTMLAttributes<HTMLDivElement> {
  handleClick: () => void;
}

const AlertPopUp = ({ handleClick }: AlertPopUpProps): ReactElement => {
  const { t } = useTranslation();
  return (
    <Dialog fullWidth scroll="body" open onClose={handleClick}>
      <DialogTitle>{t('alerts.allowPopups')}</DialogTitle>
      <DialogContent>
        <Button variant="contained" color="primary" className={s.alertActions} onClick={() => window.location.reload()}>
          {t('actions.reloadPage')}
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default AlertPopUp;
