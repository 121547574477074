import { api } from '@data/store';
import { OrganizationEntity } from '@data';
import { OrganizationSlackAuthorizeValues } from '@data/utils/types';

export const fetchOrganization = (organizationId: string): Promise<OrganizationEntity> =>
  organizationId && api.get(`/organizations/${organizationId}`);

export const fetchOrganizationChapters = (organizationId: string): Promise<OrganizationEntity> =>
  api.get(`/organizations/${organizationId}/chapters`);

export const authorizeSlack = (
  organizationId: string,
  values: OrganizationSlackAuthorizeValues,
): Promise<OrganizationEntity> => api.post(`organizations/${organizationId}/slack/authorize`, values);

export const updateOrganization = (
  organizationId: string,
  values: Partial<OrganizationEntity>,
): Promise<OrganizationEntity> => api.patch(`/organizations/${organizationId}`, values);

export const revokeSlack = (organizationId: string): Promise<OrganizationEntity> =>
  api.post(`organizations/${organizationId}/slack/revoke`);
