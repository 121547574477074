import { FormFieldProps } from '@app/utils/form-field';
import cx from 'classnames';
import React, { ReactElement } from 'react';
import Creatable from 'react-select/creatable';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

export const CreatableSelect = ({
  title,
  error,
  value,
  setFieldValue,
  name,
  options,
  placeholder,
  className,
  ...props
}: FormFieldProps): ReactElement => {
  const theme = useTheme();
  const onChange = (e, option) => {
    if (option.removedValue && option.removedValue.isFixed) return;

    setFieldValue(name, e);
  };
  return (
    <div>
      {title && <Typography>{title}</Typography>}
      <Creatable
        {...props}
        className={cx(className)}
        isMulti
        onChange={onChange}
        value={value}
        options={options}
        placeholder={placeholder}
      />
      {!Array.isArray(error) ? (
        <Typography variant="caption" color={theme.palette.error[500]}>
          {error}
        </Typography>
      ) : (
        error.map((x) => (
          <div key={x}>
            <Typography variant="caption" color={theme.palette.error[500]}>
              {typeof x === 'string' ? x : x?.value}
            </Typography>
          </div>
        ))
      )}
    </div>
  );
};
