/* eslint-disable no-param-reassign */
import createStore from '@data/store';
import { DecodedJwt, OrganizationEntity } from '@data/utils/types';

type State = {
  organization: OrganizationEntity | null;
  tokenData?: DecodedJwt;
};

const initialState: State = {
  organization: null,
};

export const useStore = createStore(
  initialState,
  (set) => ({
    setOrganization: (organization: OrganizationEntity | null) =>
      set((draft) => {
        draft.organization = organization;
      }),

    setTokenData: (tokenData: DecodedJwt) =>
      set((draft) => {
        draft.tokenData = tokenData;
      }),
  }),
  'state',
);
