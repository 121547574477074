import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid'; // Ensure using the right Grid component
import { styled } from '@mui/material/styles'; // For custom styling

export interface SendSprintProps extends React.HTMLAttributes<HTMLDivElement> {
  productRepo: string[];
  updateRepo: (name: string[]) => void;
  closePopup: () => void;
}

const ReadOnlyTextField = styled(TextField)({
  '& .MuiInputBase-input': {
    color: 'grey', // Apply grey color to the text
  },
});

export const EditProductRepoPopup = ({ productRepo, closePopup, updateRepo }: SendSprintProps): ReactElement => {
  const { t } = useTranslation();

  const [names, setNames] = useState<string[]>(productRepo);
  const [inputValue, setInputValue] = useState<string>('');
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  const handleAdd = () => {
    if (inputValue.trim() && !names.includes(inputValue.trim())) {
      setNames([...names, inputValue.trim()]);
      setInputValue('');
    }
  };

  const handleRemove = () => {
    if (selectedIndex !== null) {
      setNames(names.filter((_, index) => index !== selectedIndex));
      setSelectedIndex(null); // Reset selection
    }
  };

  const handleSave = () => {
    updateRepo(names);
    closePopup();
  };

  return (
    <Dialog fullWidth open scroll="body" onClose={closePopup}>
      <DialogTitle>{t('titles.addRepository')}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} margin="20px 0">
          <Grid item xs={12}>
            <TextField
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              fullWidth
              placeholder={t('Add a repository (owner/repositoryName)')}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} margin="20px 0">
          {names.map((name, index) => (
            <Grid item xs={12} key={name}>
              <ReadOnlyTextField
                value={name}
                fullWidth
                InputProps={{ readOnly: true }}
                onClick={() => setSelectedIndex(index)} // Set index on click
              />
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <Grid container spacing={2} justifyContent="flex-end" padding="16px" marginTop="auto">
        {/* Buttons at the bottom */}
        <Grid item>
          <Button variant="outlined" onClick={handleAdd}>
            {t('actions.add')}
          </Button>
        </Grid>
        <Grid item>
          <Button variant="outlined" onClick={handleRemove}>
            {t('actions.remove')}
          </Button>
        </Grid>
        <Grid item>
          <Button variant="outlined" onClick={closePopup}>
            {t('actions.cancel')}
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" onClick={handleSave}>
            {t('actions.save')}
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
};
