export const basePath = '/';

export const baseAppPath = '/app';
const applyBaseUrl = (url: string): string => `${baseAppPath}/${url}`;

export const organizationsPath: string = applyBaseUrl('organizations');
export const organizationsNewPath = `${organizationsPath}/new`;
export const organizationPath = (organizationId?: string): string => `${organizationsPath}/${organizationId}`;
export const sprintReportingPath = (organizationId: string): string =>
  `${organizationPath(organizationId)}/sprint-reporting`;
export const productsReportingPath = (organizationId: string): string =>
  `${organizationPath(organizationId)}/products-reporting`;
export const clientsReportingPath = (organizationId: string): string =>
  `${organizationPath(organizationId)}/client-reporting`;
export const productReportingPath = (organizationId: string, productId: string, sprintId: string): string =>
  `${organizationPath(organizationId)}/products-reporting/products/${productId}/sprints/${sprintId}`;
export const clientReportingPath = (organizationId?: string, productId?: string, sprintId?: string): string =>
  `${organizationPath(organizationId)}/client-reporting/products/${productId}/sprints/${sprintId}`;

export const membersPath = (organizationId: string): string => `${organizationPath(organizationId)}/members`;

export const membersReportingListPath = (organizationId: string): string =>
  `${organizationPath(organizationId)}/members-reporting`;
export const memberReportingPath = (orgId: string, memberId: string): string =>
  `${membersReportingListPath(orgId)}/${memberId}`;

export const productsPath = (organizationId: string): string => `${organizationPath(organizationId)}/products`;
export const productPath = (orgId: string, productId: string): string => `${productsPath(orgId)}/${productId}`;

export const clientReportingSlugPath = clientReportingPath(':organizationId', ':productId', ':sprintId');
