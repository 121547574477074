import { api } from '@data/store';
import {
  MembershipEntity,
  ProductEntity,
  ProductParams,
  PmProductsParams,
  ListResponse,
  PmParams,
  PmProductsInfiniteData,
  UpdateProduct,
} from '@data/utils/types';

export const fetchProducts = (
  organizationId: string,
  params?: ProductParams,
): Promise<{ items: ProductEntity[]; count: number }> =>
  api.get(`/organizations/${organizationId}/products`, { params });

export const fetchPmProducts = async (
  organizationId: string,
  params: PmProductsParams,
): Promise<ListResponse<ProductEntity>> =>
  api.get(`organizations/${organizationId}/pm/products`, {
    params,
  });

export const fetchInfinitePmProducts = async (
  organizationId: string,
  params: PmParams,
  { pageParam },
): Promise<PmProductsInfiniteData> => {
  const newParams = {} as any;

  // sorry for the very dirty hotfix
  if (pageParam) {
    newParams.sprintNumber = pageParam.sprintNumber ?? params.sprintNumber;
    newParams.sprintId = pageParam.sprintId ?? params.sprintId;
    newParams.from = pageParam.from ?? params.from;
    newParams.to = pageParam.to ?? params.to;
    newParams.statuses = params.statuses;
    newParams.search = params.search;
  } else {
    newParams.sprintNumber = params.sprintNumber;
    newParams.sprintId = params.sprintId;
    newParams.from = params.from;
    newParams.to = params.to;
    newParams.statuses = params.statuses;
    newParams.search = params.search;
  }

  const data = await api.get(`organizations/${organizationId}/pm/products`, {
    params: newParams,
  });
  return {
    sprintNumber: newParams?.sprintNumber,
    sprintId: newParams?.sprintId,
    from: newParams?.from,
    to: newParams?.to,
    items: data.items,
  };
};

export const updateProduct = (productId: string, values: UpdateProduct): Promise<ProductEntity> =>
  api.patch(`/products/${productId}`, values);

export const fetchProduct = (productId: string): Promise<ProductEntity> => api.get(`/products/${productId}`);

export const fetchProductClients = (productId?: string): Promise<{ items: MembershipEntity[]; count: number }> =>
  api.get(`/products/${productId}/clients`);
