import { SprintProductStatus, useClientSprintProducts, ClientReportWithProductsEntity } from '@data';
import { useStore } from '@data/storages';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Loader } from '@ui';
import ContentPaste from '@mui/icons-material/ContentPaste';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import { addProductsToClientEntityFn } from '../addProductsToClientEntityFn';
import s from './styles.module.scss';
import ClientReportCard from './ClientReportCard';

const EmptyState = () => {
  const { t } = useTranslation();

  return (
    <div className={s.empty}>
      <div className={s.iconParent}>
        <ContentPaste />
      </div>
      <Typography className={s.emptyTitle}>{t('noReportsYet')}</Typography>
      <Typography className={s.emptyDescription}>{t('sendOutMsgAfterSprintEnd')}</Typography>
    </div>
  );
};

type ReduceReturnType = {
  newReports: ClientReportWithProductsEntity[];
  approvedReports: ClientReportWithProductsEntity[];
};

const SprintProducts = (): JSX.Element => {
  const { organization } = useStore();
  const theme = useTheme();

  const statuses = [SprintProductStatus.closed, SprintProductStatus.approved, SprintProductStatus.invoiced];

  const { data: sprintProducts, isLoading } = useClientSprintProducts(organization?.id, { statuses });

  const mergeReportsPerStatus = useMemo(() => {
    const defaultValue = { newReports: [], approvedReports: [] };

    if (!sprintProducts) return defaultValue;

    return sprintProducts.items.reduce<ReduceReturnType>((acc, curr) => {
      if (curr.status === SprintProductStatus.closed) {
        addProductsToClientEntityFn(acc.newReports, curr);
      } else {
        addProductsToClientEntityFn(acc.approvedReports, curr);
      }
      return acc;
    }, defaultValue);
  }, [sprintProducts]);

  const { t } = useTranslation();

  if (!isLoading && !mergeReportsPerStatus.approvedReports.length && !mergeReportsPerStatus.newReports.length) {
    return <EmptyState />;
  }

  return (
    <Container maxWidth="xl">
      <div className={s.reports}>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <Typography variant="h6" fontWeight={theme.typography.fontWeightBold}>
              {t('titles.reports')}
            </Typography>
            {!!mergeReportsPerStatus.newReports.length && (
              <>
                <Typography className={s.reportsSubTitle} fontWeight={theme.typography.fontWeightBold}>
                  {t('newReport', { count: mergeReportsPerStatus.newReports.length })}
                </Typography>

                {mergeReportsPerStatus.newReports.map((report) => (
                  <ClientReportCard key={report.id} report={report} organizationId={organization?.id} isNew />
                ))}
              </>
            )}
            {!!mergeReportsPerStatus.approvedReports.length && (
              <>
                <Typography fontWeight={theme.typography.fontWeightBold}>{t('approvedReports')}</Typography>

                {mergeReportsPerStatus.approvedReports.map((report) => (
                  <ClientReportCard key={report.id} organizationId={organization?.id} report={report} />
                ))}
              </>
            )}
          </>
        )}
      </div>
    </Container>
  );
};

export default SprintProducts;
