import { getUserInitials } from '@app/utils/helpers/getUserInitials';
import { UserEntity } from '@data/index';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import { useTheme, styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ButtonBase from '@mui/material/ButtonBase';
import Box from '@mui/material/Box';
import { googleLogout } from '@react-oauth/google';

const StyledButton = styled(ButtonBase)`
  background: transparent;
  border: none;
  outline: none;
  display: flex;
  gap: 8px;
`;

const StyledInitials = styled(Box)`
  align-items: center;
  background-color: ${({ theme }) => theme.palette.grey[400]};
  border-radius: 5px;
  display: flex;
  font-weight: 600;
  justify-content: center;
  min-height: 36px;
  min-width: 36px;
`;

const StyledPopoverContent = styled(Box)`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 8px;
`;

interface ProfilePopoverProps {
  user: UserEntity;
}

const ProfilePopover: React.FC<ProfilePopoverProps> = ({ user }) => {
  const { t } = useTranslation();

  const theme = useTheme();

  const handleSignOutSuccess = () => {
    googleLogout();
    localStorage.clear();
    window.location.reload();
  };

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <Box p={3}>
      <StyledButton aria-describedby={id} onClick={handleClick}>
        <StyledInitials>{getUserInitials(user)}</StyledInitials>
        <Typography color={theme.palette.grey[50]}>
          {user.firstName || user.lastName ? `${user?.firstName} ${user?.lastName}` : user.email}
        </Typography>
      </StyledButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Typography sx={{ p: 2 }}>
          <StyledPopoverContent mb={2}>
            <StyledInitials>{getUserInitials(user)}</StyledInitials>
            <div>
              <Typography>{`${user?.firstName} ${user?.lastName}`}</Typography>

              <Typography variant="body2" color={theme.palette.grey[600]}>
                {user?.email}
              </Typography>
            </div>
          </StyledPopoverContent>
          <Button variant="outlined" color="primary" onClick={handleSignOutSuccess} sx={{ mr: 1 }}>
            {t('actions.signOut')}
          </Button>
          <Button
            variant="outlined"
            color="primary"
            component="a"
            href={`${import.meta.env.VITE_API_BASE_URL}/auth/github/authorize?clientRedirect=${import.meta.env.VITE_GITHUB_REDIRECT_URL}`}
          >
            {t('actions.githubAuth')}
          </Button>
        </Typography>
      </Popover>
    </Box>
  );
};

export default ProfilePopover;
