import Button from '@mui/lab/LoadingButton';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import s from './styles.module.scss';

interface TokenExpiredComponentProps {
  sendMails: () => void;
}

const TokenExpiredComponent = ({ sendMails }: TokenExpiredComponentProps): ReactElement => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <div className={s.token}>
      <Typography fontWeight={theme.typography.fontWeightBold}>{t('titles.linkExpired')}</Typography>
      <Button variant="contained" className={s.tokenButton} onClick={sendMails}>
        Send mail to all receivers!
      </Button>
    </div>
  );
};

export default TokenExpiredComponent;
