/* eslint-disable no-use-before-define */
export interface IEntity {
  id: string;
  createdAt: string;
  updatedAt: string;
}

export enum SprintProductStatus {
  open = 'open',
  closed = 'closed',
  approved = 'approved',
  invoiced = 'invoiced',
}

export enum ProductStatus {
  deactivated = 'deactivated',
  active = 'active',
  support = 'support',
  archived = 'archived',
}

export enum MembershipStatus {
  deactivated = 'deactivated',
  active = 'active',
}

export interface DecodedJwt {
  userId: string;
  firstName: string;
  email: string;
  lastName: string;
  iat: number;
  exp: number;
}

export interface SprintProductClientEntity extends IEntity {
  status: SprintProductStatus;
  sprintId?: string;
  productId?: string;
  product: ClientProductEntity;
  sprint: SprintEntity;
  clientCount?: number;
  summary?: string | null;
  mutation?: boolean;
  clients?: MembershipEntity[];
  sentBy?: UserEntity & IEntity;
  approvedBy?: UserEntity | null;
  approvedAt?: Date | null;
}

export interface ClientProductEntity extends IEntity {
  name: string;
  status: ProductStatus;
  channelId: string;
}

export interface SprintProductEntity extends IEntity {
  status: SprintProductStatus;
  sprintId?: string;
  productId?: string;
  product?: ProductEntity;
  sprint?: SprintEntity;
  clientCount?: number;
  summary?: string;
  mutation?: boolean;
  clients?: MembershipEntity[];
  sentBy?: UserEntity;
  approvedBy?: UserEntity | null;
  approvedAt?: Date | null;
  comment?: string;
}

export interface ProductEntity extends ClientProductEntity {
  chapters: string[];
  membershipCount: number;
  totalMinutes: number;
  sprintProducts?: SprintProductEntity[];
  repositories: string[];
}

export type UserEntity = {
  id?: string;
  firstName?: string;
  lastName?: string;
  email: string;
  status?: MembershipStatus;
};
export interface MembershipEntity extends IEntity {
  role: string;
  user: UserEntity;
  slackId?: string;
  productsCount: number;
  chapters: string[];
  totalMinutes: number;
  emptyDaysCount: number;
  pingSent?: boolean;
}
export interface InvolvementEntity extends IEntity {
  role: string;
  product: ProductEntity;
  membership: MembershipEntity;
}
export interface ReportEntity extends IEntity {
  today: string;
  minutes: number;
  threadId: string;
  workday: Date;
  involvement: InvolvementEntity;
  userId?: string;
  hours: number;
  role: string;
}

export interface SprintEntity extends IEntity {
  startDate: string;
  endDate: string;
  number: number;
}

export interface WorkDayUserEntity {
  date: string;
  totalMinutes: number;
  reports: ReportEntity[];
}

export interface WorkDayChapter {
  name: string;
  reports: ReportEntity[];
  totalMinutes: number;
}
export interface WorkDayProduct {
  date: string;
  chapters: WorkDayChapter[];
  totalMinutes: number;
}

export interface SprintProductMember {
  member: MembershipEntity;
  totalMinutes: number;
}

export interface SprintProductChapter {
  chapter: string;
  totalMinutes: number;
  members: SprintProductMember[];
}

export interface RoleEntity {
  role: string;
  text: string;
  isUserDefault: boolean;
  isMostRecentInvolvement: boolean;
}

export type IError = {
  detail: string;
  status?: number;
  title?: string;
  type?: string;
};

export type ApiStore = {
  error: null | IError;
  success: null | { id: string; message: string };
  refreshFailed?: boolean;
};

export interface OrganizationEntity extends IEntity {
  name: string;
  memberships: MembershipEntity[];
  role: string;
  slackTeamInfo?: any;
  logoUrl?: string;
}

export interface Chapter {
  role: string;
  text: string;
}

export type OrganizationParamsType = {
  organizationId: string;
};

export type SprintProductParamsType = {
  organizationId: string;
  sprintId: string;
  productId: string;
};

export type OrganizationFormValues = {
  name: string;
};

export type SendSprintFormValues = {
  sprintProductId?: string;
  emails: string[];
};

export type ApproveSprintFormValues = {
  sprintProductId?: string;
  approvalRecipients: string[];
  sprintId: string;
  productId: string;
  comment?: string;
};

export type ListResponse<TItem> = {
  count: number;
  items: TItem[];
};

export type Product = {
  count: number;
  items: SprintEntity[];
};

export type OrganizationSlackAuthorizeValues = {
  code: string | null;
  redirectUri?: string;
};

export interface PmInfiniteData {
  from: string | Date;
  to: string | Date;
  sprintNumber: number;
  sprintId: string;
}

export interface PmMembersInfiniteData extends PmInfiniteData {
  items: MembershipEntity[];
}

export interface PmProductsInfiniteData extends PmInfiniteData {
  items: ProductEntity[];
}

export interface SprintProductReportsData {
  detailed: WorkDayProduct[] | null;
  brief: SprintProductChapter[] | null;
}

export type PmParams = {
  from?: string | Date;
  to?: string | Date;
  sprintNumber?: number;
  sprintId?: string;
  statuses?: ProductStatus[];
  search?: string;
};

export interface PmMembersParams extends PmParams {
  userStatus?: MembershipStatus;
}

export type SprintProductReportsParams = {
  sprint?: SprintEntity;
  productId: string;
  chapters?: string[];
};

export type ProductParams = { statuses?: ProductStatus[] };

export interface PmProductsParams {
  from?: string;
  to?: string;
  productIds?: string[];
  statuses?: ProductStatus[];
  search?: string;
}

export interface UpdateProduct {
  name?: string;
  status?: ProductStatus;
  repositories?: string[];
}

export type ClientReportWithProductsEntity = SprintProductClientEntity & { products: ClientProductEntity[] };

export enum RoleType {
  BE = 'BE',
  FE = 'FE',
  PM = 'PM',
  C = 'C',
  DS = 'DS',
  G = 'G',
  S = 'S',
}

export type AddReportFormValues = {
  userId: string;
  productId: string;
  role: string;
  today: string;
  customHours: number | null;
  hours: number | null;
  workday: Date;
};
