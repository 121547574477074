import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { shortDate, toFixedHours } from '@app/utils';
import { Roles, TimeValueType } from '@app/utils/types';
import { ReportEntity, useListProducts, useListRoles, useReportSummary, validationSchemas } from '@data';
import React, { ReactElement, useEffect, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { AddReportFormValues, OrganizationParamsType, ProductStatus, MembershipStatus } from '@data/utils/types';
import { yupResolver } from '@hookform/resolvers/yup';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Unstable_Grid2';
import FormHelperText from '@mui/material/FormHelperText';
import Typography from '@mui/material/Typography';
import { useParams } from 'react-router-dom';
import { useOrganization } from '@data/hooks/organization';

export interface ReportModalProps {
  title: string;
  onHide: () => void;
  show?: boolean;
  initialData?: Partial<ReportEntity> | null;
  userId?: string;
  productId?: string;
  onSubmit: (values: Partial<ReportEntity>) => void;
  disabledDays?: { before?: Date; after?: Date };
  date?: string;
  reportProductIds?: string[];
}

const ReportModal = ({
  title,
  onHide,
  show = false,
  initialData,
  userId,
  productId,
  onSubmit,
  disabledDays,
  date,
  reportProductIds,
}: ReportModalProps): ReactElement => {
  const { t } = useTranslation();

  const { productId: urlProductId } = useParams<{ productId?: string }>();

  const initialValues = useMemo(() => {
    const initialWorkday = initialData?.workday || date;

    const initialValuesHours = initialData?.minutes ? toFixedHours(initialData.minutes / 60) : TimeValueType.fullDay;

    return {
      userId: userId || initialData?.involvement?.membership?.user?.id || '',
      productId: initialData?.involvement?.product?.id || productId || urlProductId || '',
      role: initialData?.involvement?.role || '',
      today: initialData?.today || '',
      customHours:
        initialValuesHours === TimeValueType.fullDay || initialValuesHours === TimeValueType.halfDay
          ? initialValuesHours
          : null,
      hours:
        initialValuesHours !== TimeValueType.fullDay && initialValuesHours !== TimeValueType.halfDay
          ? initialValuesHours
          : null,
      workday: initialWorkday ? new Date(initialWorkday) : new Date(),
    };
  }, [initialData, userId, date]);

  const {
    control,
    handleSubmit: handleUseFormSubmit,
    reset,
    setValue,
    formState: { errors },
    clearErrors,
    watch,
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchemas.ReportSchema),
  });

  useEffect(() => {
    if (show) {
      reset(initialValues);
    }
  }, [show]);

  const formCustomHours = watch('customHours');
  const formWorkday = watch('workday');
  const formUserId = watch('userId');
  const formProductId = watch('productId');

  const { organizationId } = useParams<OrganizationParamsType>();

  const { data: organization = { memberships: [] } } = useOrganization(organizationId);
  const members = organization.memberships.filter(
    (x) => x.user.status === MembershipStatus.active && x.role !== Roles.client,
  );

  const { data: products, isLoading: isProductsLoading } = useListProducts(organizationId, {
    statuses: [ProductStatus.active, ProductStatus.support],
  });

  const productsOptions = useMemo(
    () =>
      products?.items
        ?.filter((el) => !reportProductIds?.includes(el.id))
        .map((product) => ({ value: product.id, label: product.name })) || [],
    [products, reportProductIds?.length],
  );

  useEffect(() => {
    if (!productId && productsOptions.length && !initialData) {
      const defaultProduct = productsOptions[0];
      if (defaultProduct) {
        setValue('productId', defaultProduct.value);
      }
    }
  }, [productsOptions, show]);

  const { data: roles, isLoading: rolesIsLoading } = useListRoles({ userId: formUserId, productId: formProductId });

  const rolesOptions = useMemo(
    () =>
      roles?.items
        ?.sort((a, b) => a.text.localeCompare(b.text))
        ?.map(({ role, text, isMostRecentInvolvement }) => ({
          value: role,
          label: text,
          isMostRecentInvolvement,
        })) || [],
    [roles],
  );

  useEffect(() => {
    if (!initialValues.role && rolesOptions) {
      const defaultRole = rolesOptions.find((r) => r.isMostRecentInvolvement);
      if (defaultRole) {
        setValue('role', defaultRole.value);
      }
    }
  }, [rolesOptions, show]);

  const memberOptions = useMemo(
    () => members.map((m) => ({ value: m.user.id, label: `${m.user.firstName} ${m.user.lastName}` })),
    [members],
  );

  const handleSubmit: SubmitHandler<AddReportFormValues> = (values) => {
    const { hours, customHours, ...restValues } = values;
    onSubmit({
      ...(initialData || {}),
      ...restValues,
      hours: customHours || hours || 0,
    });
  };

  const { mutate: fetchSummaryData, isLoading: isLoadingSummary } = useReportSummary();

  const generateSummary = async () => {
    const productId = watch('productId');
    const since = new Date(date!).toISOString();
    const until = new Date(date!);
    until.setHours(26);

    fetchSummaryData(
      {
        productId,
        since,
        until: until.toISOString(),
      },
      {
        onSuccess: (data) => {
          setValue('today', data);
        },
      },
    );
  };

  const selectedProductLabel = productsOptions.find((el) => el.value === formProductId)?.label;

  return (
    <Dialog fullWidth maxWidth="sm" scroll="body" open={show} onClose={onHide}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <form onSubmit={handleUseFormSubmit(handleSubmit)}>
          <Grid container spacing={3}>
            <Grid xs={12} md={6}>
              <Controller
                name="productId"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Autocomplete
                    {...field}
                    onChange={(_, option) => field.onChange(option?.value || '')}
                    options={productsOptions}
                    getOptionLabel={(option) => option.label || ''}
                    value={productsOptions.find((el) => el.value === field.value)}
                    disabled={!!initialData || !!productId}
                    loading={isProductsLoading}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        label={t('titles.product')}
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid xs={12} md={6}>
              <Controller
                name="workday"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <DatePicker
                    {...field}
                    format="dd/MM/yyyy"
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        label: t('titles.workday'),
                        placeholder: t('select'),
                        error: !!error,
                        helperText: error?.message,
                      },
                    }}
                    shouldDisableDate={(day) => {
                      if (disabledDays?.before && day < disabledDays.before) {
                        return true;
                      }
                      if (disabledDays?.after && day > disabledDays.after) {
                        return true;
                      }
                      return false;
                    }}
                  />
                )}
              />
            </Grid>

            {!userId && (
              <Grid xs={12}>
                <Controller
                  name="userId"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <Autocomplete
                      {...field}
                      onChange={(_, option) => field.onChange(option?.value || '')}
                      options={memberOptions}
                      getOptionLabel={(option) => option.label || ''}
                      value={memberOptions.find((el) => el.value === field.value)}
                      disabled={!!initialData}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label={t('member')}
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
            )}

            <Grid xs={12}>
              <Controller
                name="role"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Autocomplete
                    {...field}
                    onChange={(_, option) => field.onChange(option?.value || '')}
                    options={rolesOptions}
                    getOptionLabel={(option) => option.label || ''}
                    value={rolesOptions.find((el) => el.value === field.value) || null}
                    loading={rolesIsLoading}
                    disabled={!!initialData || !rolesOptions.length}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        label={t('titles.involvement')}
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                )}
              />
            </Grid>

            <Grid component={FormControl} error={!!errors.hours} container spacing={1}>
              <Grid xs={12} pb={0}>
                <Typography variant="body2">
                  <Trans
                    defaults={t('titles.hoursWithProductName', { product: selectedProductLabel || 'product' })}
                    components={{ bold: <strong /> }}
                  />
                </Typography>
              </Grid>

              <Grid xs={6} md="auto">
                <Button
                  fullWidth
                  variant={formCustomHours === TimeValueType.fullDay ? 'contained' : 'outlined'}
                  name="customHours"
                  onClick={() => {
                    setValue('hours', null);
                    clearErrors('hours');
                    setValue('customHours', TimeValueType.fullDay);
                  }}
                >
                  {t('workingTimeValue.fullDay')}
                </Button>
              </Grid>
              <Grid xs={6} md="auto">
                <Button
                  fullWidth
                  variant={formCustomHours === TimeValueType.halfDay ? 'contained' : 'outlined'}
                  name="customHours"
                  onClick={() => {
                    setValue('hours', null);
                    clearErrors('hours');
                    setValue('customHours', TimeValueType.halfDay);
                  }}
                >
                  {t('workingTimeValue.halfDay')}
                </Button>
              </Grid>
              <Grid xs={12} md="auto" display="flex" alignItems="center" justifyContent="center">
                <Typography px={2}>{t('or')}</Typography>
              </Grid>
              <Grid xs={12} md>
                <Controller
                  name="hours"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      aria-describedby="hours-helper-text"
                      fullWidth
                      onChange={(e) => {
                        setValue('customHours', null);
                        const output = e.target.value.replace(/[^0-9.]/g, '');
                        field.onChange(output);
                      }}
                      value={
                        !field.value || Number.isNaN(field.value) || field.value === 0 ? '' : field.value.toString()
                      }
                    />
                  )}
                />
              </Grid>

              <Grid xs={12}>
                {!!errors.hours && <FormHelperText id="hours-helper-text">{errors.hours?.message}</FormHelperText>}
              </Grid>
            </Grid>

            <Grid xs={12}>
              <Controller
                name="today"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    minRows={4}
                    fullWidth
                    label={
                      formWorkday instanceof Date && !Number.isNaN(formWorkday.getTime())
                        ? t('titles.today', {
                            date: shortDate(formWorkday),
                          })
                        : undefined
                    }
                    multiline
                    disabled={isLoadingSummary}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </Grid>

            <Grid
              container
              xs={12}
              spacing={1}
              justifyContent="flex-end"
              direction={{ xs: 'column-reverse', md: 'row' }}
            >
              <Grid xs={12} md="auto">
                <Button fullWidth variant="outlined" onClick={generateSummary}>
                  {t('actions.generateSummaryReport')}
                </Button>
              </Grid>
              <Grid xs={12} md="auto">
                <Button fullWidth variant="outlined" onClick={onHide}>
                  {t('actions.cancel')}
                </Button>
              </Grid>
              <Grid xs={12} md="auto">
                <Button fullWidth variant="contained" type="submit">
                  {t('actions.submitReport')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};
export default ReportModal;
