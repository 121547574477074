import { OrganizationEntity } from '@data';
import { UseQueryResult, UseMutationResult, useMutation, useQueryClient, useQuery } from 'react-query';
import { v4 as uuidv4 } from 'uuid';

import type { IError, ListResponse, UserEntity } from '../utils/types';
import * as api from '../api';
import * as queryKeys from '../constants/keys';

export const useOrganizations = (
  userId?: string,
  params?: {
    offset?: number;
    limit?: number;
  },
): UseQueryResult<{ items: OrganizationEntity[]; count: number }, IError> =>
  useQuery([queryKeys.ORGANIZATIONS], () => api.fetchOrganizations(userId, params), {
    enabled: !!userId,
    staleTime: 1000,
  });

export const useCreateOrganization = ({
  id,
  firstName,
  lastName,
}: UserEntity): UseMutationResult<OrganizationEntity, IError, Partial<OrganizationEntity>, unknown> => {
  const queryClient = useQueryClient();
  return useMutation((values) => api.createOrganization(values), {
    onMutate: async (newOrganization: Partial<OrganizationEntity>) => {
      const previousOrgs = queryClient.getQueryData(queryKeys.ORGANIZATIONS) as ListResponse<OrganizationEntity>;

      const newOrg = {
        id: uuidv4(),
        name: newOrganization.name,
        memberships: [{ user: { id, firstName, lastName } }],
        membershipCount: 1,
      };

      queryClient.setQueryData(queryKeys.ORGANIZATIONS, (old: ListResponse<OrganizationEntity>) => {
        const newItems = [...old.items, newOrg];
        return { count: old.count + 1, items: newItems };
      });

      return { previousOrgs, newOrg };
    },
    onSuccess: (data, variables, context) => {
      queryClient.setQueryData(queryKeys.ORGANIZATIONS, (old: ListResponse<OrganizationEntity>) => ({
        ...old,
        items: old.items.map((x) => (x.id === context?.newOrg.id ? { ...x, id: data.id } : x)),
      }));
    },

    onError: (_, __, context) => {
      queryClient.setQueryData(queryKeys.ORGANIZATIONS, context?.previousOrgs);
    },
  });
};

export const useLeaveOrganization = ({
  id,
}: UserEntity): UseMutationResult<void, IError, string | undefined, unknown> => {
  const queryClient = useQueryClient();
  return useMutation((organizationId) => api.leaveOrganization(id, organizationId), {
    onMutate: async (organizationId: string) => {
      const previousOrgs = queryClient.getQueryData(queryKeys.ORGANIZATIONS) as ListResponse<OrganizationEntity>;

      queryClient.setQueryData(queryKeys.ORGANIZATIONS, (old: ListResponse<OrganizationEntity>) => ({
        count: old.count - 1,
        items: old.items.filter((x) => x.id !== organizationId),
      }));

      return { previousOrgs };
    },
    onError: (_, __, context) => {
      queryClient.setQueryData(queryKeys.ORGANIZATIONS, context?.previousOrgs);
    },
  });
};
