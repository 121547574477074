import { api } from '@data/store';
import {
  ListResponse,
  ReportEntity,
  SprintEntity,
  SprintProductEntity,
  SprintProductReportsParams,
} from '@data/utils/types';

export const fetchSprints = (params?: {
  from?: string | Date;
  to?: string | Date;
  offset?: number;
  limit?: number;
}): Promise<{ items: SprintEntity[]; count: number }> =>
  api.get(`/sprints`, {
    params,
  });

export const fetchSprintProductReports = async ({
  productId,
  sprint,
  chapters,
}: SprintProductReportsParams): Promise<ListResponse<ReportEntity>> =>
  api.get(`sprints/${sprint?.id}/products/${productId}/reports`, { params: { chapters } });

export const fetchSprint = (sprintId: string): Promise<SprintEntity> => api.get(`/sprints/${sprintId}`);

export const fetchSprintProducts = ({
  sprintId,
  productId,
}: {
  sprintId: string | undefined;
  productId: string | undefined;
}): Promise<ListResponse<SprintProductEntity>> => api.get(`/sprints/${sprintId}/products/${productId}`);
