/* eslint-disable react/require-default-props */
import Typography from '@mui/material/Typography';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import s from './styles.module.scss';

export const OrganizationsEmptyComponent = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <div className={s.organizations}>
      <Typography>{t('titles.youAreNotInAnyOrg')}</Typography>
    </div>
  );
};
