import { productsPath } from '@app/constants/url';
import React, { ReactElement } from 'react';
import { Route, Switch } from 'react-router-dom';
import ProductsList from './productsList';

const ProductsRouter = (): ReactElement => (
  <Switch>
    <Route path={productsPath(':organizationId')}>
      <ProductsList />
    </Route>
  </Switch>
);

export default ProductsRouter;
