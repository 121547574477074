import { UseMutationResult, useMutation, UseMutationOptions } from 'react-query';
import Cookies from 'js-cookie';
import { AxiosError } from 'axios';
import { useGTMEvents } from '../events';
import type { IError, UserEntity } from '../utils/types';
import * as api from '../api';

export const useGetUserData = (): UseMutationResult<UserEntity, IError, { token: string }, unknown> => {
  const { events, trackEvent } = useGTMEvents();

  return useMutation(({ token }) => api.login(token), {
    onSuccess: (data) => {
      trackEvent(events.Login, { userId: data.id });
      // for GTM tracking
      Cookies.set('user_id', data.id);
    },
  });
};

type RefreshTokenParams = {
  token: string;
};

type UseRefreshTokenOptions = UseMutationOptions<void, AxiosError<IError>, RefreshTokenParams, unknown>;

export const useRefreshToken = (options?: UseRefreshTokenOptions) =>
  useMutation({
    mutationFn: ({ token }: RefreshTokenParams) => api.refresh(token),
    ...options,
  });
