import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/lab/LoadingButton';
import React, { ReactElement } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import s from './styles.module.scss';

interface RemoveReportProps {
  onRemove: () => void;
  show: boolean;
  onHide: () => void;
}

const RemoveReport = ({ show = false, onHide, onRemove }: RemoveReportProps): ReactElement => {
  const { t } = useTranslation();
  return (
    <Dialog fullWidth scroll="body" open={show} onClose={onHide}>
      <DialogTitle>{t('titles.deleteReport')}</DialogTitle>
      <DialogContent>
        <Typography>{t('alerts.deletePopup')}</Typography>
        <div className={s.popupActions}>
          <Button variant="outlined" onClick={onHide} className={cx(s.popupActionsButton, s.popupActionsButtonCancel)}>
            {t('actions.cancel')}
          </Button>
          <Button variant="contained" className={cx(s.popupActionsButton, s.popupActionsButtonSave)} onClick={onRemove}>
            {t('actions.delete')}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default RemoveReport;
