import Inventory2Outlined from '@mui/icons-material/Inventory2Outlined';
import TaskAlt from '@mui/icons-material/TaskAlt';
import Search from '@mui/icons-material/Search';
import WorkOutline from '@mui/icons-material/WorkOutline';
import ArchiveOutlined from '@mui/icons-material/ArchiveOutlined';
import EditOutlined from '@mui/icons-material/EditOutlined';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import { capitalize } from '@app/utils';
import { debounce } from '@app/utils/helpers/debounce';
import {
  OrganizationParamsType,
  PmProductsParams,
  ProductEntity,
  ProductStatus,
  useListPmProducts,
  useUpdateProduct,
} from '@data';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Button from '@mui/lab/LoadingButton';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import { activeArchivedTabs } from '@app/constants/app';
import { EditProductPopup } from './EditProductPopup';

import ProductsListHeader from './ProductsListHeader';
import s from './styles.module.scss';
import { EditProductRepoPopup } from './EditProductRepoPopup';

const activeStatuses = Object.values(ProductStatus).filter((s) => s !== ProductStatus.archived) as ProductStatus[];

const ProductsList = (): ReactElement => {
  const theme = useTheme();
  const params = new URLSearchParams(window.location.search);
  const { organizationId } = useParams<OrganizationParamsType>();
  const [statuses, setStatuses] = useState<ProductStatus[]>([]);
  const [search, setSearch] = useState<string | undefined>();
  const [selectedProduct, setSelectedProduct] = useState<ProductEntity | null>(null);
  const [selectedProductRepo, setSelectedProductRepo] = useState<ProductEntity | null>(null);
  const [activeTab, setActiveTab] = React.useState<ProductStatus>(
    (params.get('tab') as ProductStatus) || activeArchivedTabs[0],
  );

  let statusParams: ProductStatus[];

  if (statuses?.length) {
    statusParams = statuses;
  } else if (activeTab === ProductStatus.archived) {
    statusParams = [ProductStatus.archived];
  } else {
    statusParams = activeStatuses;
  }

  const productPmParams: PmProductsParams = {
    statuses: statusParams,
    search,
  };

  const { data: listPmProducts, isLoading: listPmProductsIsLoading } = useListPmProducts(
    organizationId,
    productPmParams,
  );

  const { mutate: updateProduct } = useUpdateProduct(organizationId, productPmParams);
  const { mutate: updateRepository } = useUpdateProduct(organizationId, productPmParams);

  const { t } = useTranslation();

  const renderEditButton = (product: ProductEntity) => (
    <Button
      className={s.button}
      aria-label={t('edit')}
      size="small"
      variant="text"
      startIcon={<EditOutlined />}
      onClick={() => {
        setSelectedProduct(product);
      }}
    />
  );

  const renderEditButtonRepos = (product: ProductEntity) => (
    <Button
      className={s.button}
      aria-label={t('edit')}
      size="small"
      variant="text"
      startIcon={<EditOutlined />}
      onClick={() => {
        setSelectedProductRepo(product);
      }}
    />
  );

  const renderActions = (product: ProductEntity) => {
    const updateStatus = (status: ProductStatus) => updateProduct({ productId: product.id, values: { status } });
    switch (product?.status) {
      case ProductStatus.active: {
        return (
          <Box display="flex" gap={1}>
            <Button
              size="small"
              variant="text"
              startIcon={<Inventory2Outlined />}
              onClick={() => updateStatus(ProductStatus.deactivated)}
            >
              {t('actions.deactivate')}
            </Button>

            <Button
              size="small"
              variant="text"
              startIcon={<WorkOutline />}
              onClick={() => updateStatus(ProductStatus.support)}
            >
              {t('actions.support')}
            </Button>
          </Box>
        );
      }

      case ProductStatus.deactivated: {
        return (
          <Box display="flex" gap={1} gridTemplateColumns={1}>
            <Button
              size="small"
              variant="text"
              startIcon={<TaskAlt />}
              onClick={() => updateStatus(ProductStatus.active)}
            >
              {t('actions.activate')}
            </Button>

            <Button
              size="small"
              variant="text"
              startIcon={<WorkOutline />}
              onClick={() => updateStatus(ProductStatus.support)}
            >
              {t('actions.support')}
            </Button>

            <Button
              size="small"
              variant="text"
              startIcon={<ArchiveOutlined />}
              onClick={() => updateStatus(ProductStatus.archived)}
            >
              {t('actions.archive')}
            </Button>
          </Box>
        );
      }

      case ProductStatus.support: {
        return (
          <Box display="flex" gap={1} gridTemplateColumns={1}>
            <Button
              size="small"
              variant="text"
              startIcon={<TaskAlt />}
              onClick={() => updateStatus(ProductStatus.active)}
            >
              {t('actions.activate')}
            </Button>

            <Button
              size="small"
              variant="text"
              startIcon={<Inventory2Outlined />}
              onClick={() => updateStatus(ProductStatus.deactivated)}
            >
              {t('actions.deactivate')}
            </Button>
          </Box>
        );
      }

      case ProductStatus.archived: {
        return (
          <Box display="flex" gap={1} gridTemplateColumns={1}>
            <Button
              size="small"
              variant="text"
              startIcon={<TaskAlt />}
              onClick={() => updateStatus(ProductStatus.active)}
            >
              {t('actions.activate')}
            </Button>

            <Button
              size="small"
              variant="text"
              startIcon={<WorkOutline />}
              onClick={() => updateStatus(ProductStatus.support)}
            >
              {t('actions.support')}
            </Button>
          </Box>
        );
      }

      default: {
        return (
          <Button
            size="small"
            variant="text"
            startIcon={<Inventory2Outlined />}
            onClick={() => updateStatus(ProductStatus.deactivated)}
          >
            {t('actions.deactivate')}
          </Button>
        );
      }
    }
  };

  const statusOptions = Object.values(ProductStatus)
    .sort((a, b) => a.localeCompare(b))
    .map((x) => ({ value: x, label: capitalize(x) }));

  const getRowData = (products: ProductEntity[]) =>
    products.map((x) => ({
      ...x,
      name: capitalize(x.name),
    }));

  const debouncedSetSearch = debounce(setSearch, 500);

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t('titles.products'),
      flex: 1,
      minWidth: 140,
      renderCell: ({ row }) => (
        <Box display="flex" alignItems="center" gap={1}>
          {renderEditButton(row)}
          {row.name}
        </Box>
      ),
    },
    {
      field: 'repositories',
      headerName: t('repositories'),
      flex: 1,
      minWidth: 200,
      renderCell: ({ row }) => (
        <Box display="flex" alignItems="center" gap={1}>
          {renderEditButtonRepos(row)}
          {row.repositories.join(', ')}
        </Box>
      ),
    },
    {
      field: 'status',
      headerName: t('status'),
      flex: 1,
      renderCell: ({ row }) => capitalize(row.status),
    },
    {
      field: 'actions',
      headerName: '',
      flex: 1,
      minWidth: 300,
      renderCell: ({ row }) => <Box>{renderActions(row)}</Box>,
    },
  ];

  const rows = getRowData(listPmProducts?.items || []);

  return (
    <>
      <ProductsListHeader tabs={activeArchivedTabs} activeTab={activeTab} setActiveTab={setActiveTab} />
      <Box
        marginBottom={3}
        display="flex"
        gap={2}
        alignItems="flex-end"
        sx={{ flexDirection: { xs: 'column', sm: 'row' } }}
      >
        <Box width="100%" sx={{ maxWidth: { sm: '300px' } }}>
          <TextField
            fullWidth
            label={t('search')}
            placeholder={t('search')}
            InputProps={{
              startAdornment: <Search htmlColor={theme.palette.grey[500]} />,
            }}
            onChange={(e) => debouncedSetSearch(e.target.value)}
          />
        </Box>

        <Box width="100%" sx={{ maxWidth: { sm: '300px' } }}>
          <Autocomplete
            fullWidth
            multiple
            value={statuses?.map((status) => statusOptions.find((option) => option.value === status))}
            options={statusOptions}
            getOptionLabel={(option) => option?.label || ''}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={!statuses?.length ? t('allStatuses') : undefined}
                label={t('titles.products')}
              />
            )}
            onChange={(_e, options) => {
              setStatuses(options.map((option) => option!.value));
            }}
          />
        </Box>
      </Box>

      <Box>
        {listPmProducts && (
          <>
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
              <TableContainer component={Paper}>
                <DataGrid
                  rows={rows}
                  columns={columns}
                  autoHeight
                  paginationModel={{ page: 0, pageSize: rows.length }}
                  disableColumnFilter
                  disableColumnMenu
                  disableColumnSelector
                  disableDensitySelector
                  disableRowSelectionOnClick
                  loading={listPmProductsIsLoading}
                  initialState={{
                    sorting: {
                      sortModel: [{ field: 'name', sort: 'asc' }],
                    },
                  }}
                />
              </TableContainer>
            </Box>
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
              <Stack spacing={2}>
                {listPmProducts?.items.map((product) => (
                  <Paper key={product.id}>
                    <Box display="flex" flexDirection="column" gap={1} p={3}>
                      <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Box display="flex" justifyContent="flex-start" alignItems="center" gap={1}>
                          {renderEditButton(product)}
                          <Typography variant="h6" fontWeight={theme.typography.fontWeightBold}>
                            {capitalize(product.name)}
                          </Typography>
                        </Box>
                        <Typography variant="body1" fontWeight={theme.typography.fontWeightBold}>
                          {t('status')}: {product.status}
                        </Typography>
                      </Box>
                      <Box display="flex" gap={1}>
                        {renderActions(product)}
                      </Box>
                    </Box>
                  </Paper>
                ))}
              </Stack>
            </Box>
          </>
        )}
      </Box>
      {selectedProduct && (
        <EditProductPopup
          productName={selectedProduct.name}
          closePopup={() => setSelectedProduct(null)}
          updateName={(name: string) => updateProduct({ productId: selectedProduct.id, values: { name } })}
        />
      )}
      {selectedProductRepo && (
        <EditProductRepoPopup
          productRepo={selectedProductRepo.repositories}
          closePopup={() => setSelectedProductRepo(null)}
          updateRepo={(repositories: string[]) =>
            updateRepository({ productId: selectedProductRepo.id, values: { repositories } })
          }
        />
      )}
    </>
  );
};

export default ProductsList;
