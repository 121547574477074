import React, { Dispatch, ReactElement, useMemo } from 'react';
import {
  useListReports,
  useListSprints,
  useListProducts,
  useRemoveReport,
  ProductStatus,
  ReportEntity,
  useCreateReport,
  useUpdateReport,
} from '@data';
import Button from '@mui/lab/LoadingButton';
import { daysInSprint, tabs } from '@app/constants/app';
import { useToggleState, addDays, usePrevious, fullDate, toFixedHours, monthAndDate, year } from '@app/utils';
import { Link, useParams } from 'react-router-dom';
import { TabsType } from '@app/utils/types';
import { useMembership, useSendMembershipReminder } from '@data/hooks/membership';
import { Trans, useTranslation } from 'react-i18next';
import { membersReportingListPath } from '@app/constants/url';
import InfiniteScroll from 'react-infinite-scroll-component';
import Accordion from '@app/components/core/accordion';
import { Loader } from '@ui';
import { Switch } from '@app/components/core';
import cx from 'classnames';
import SprintDayReport from '@app/components/shared/sprintDayReport';
import ArrowBack from '@mui/icons-material/ArrowBack';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import ReportModal from '@app/components/shared/reportModal';
import RemoveReport from './RemoveReport';
import SprintHeader from './SprintHeader';
import s from './styles.module.scss';

interface MemberReportingProps {
  activeTab: TabsType;
  setActiveTab: Dispatch<TabsType>;
}

const MemberReporting: React.FC<MemberReportingProps> = ({ activeTab, setActiveTab }): ReactElement => {
  const theme = useTheme();
  const [hideWeekend, toggleHideWeekend] = useToggleState(true);
  const [productIds, setProductIds] = React.useState<string[]>([]);

  const rangeForSprints = React.useMemo(
    () =>
      activeTab === tabs.currentSprint
        ? { from: new Date(), to: addDays(new Date(), daysInSprint - 1) }
        : { to: new Date() },
    [activeTab],
  );

  const { data: sprints } = useListSprints(rangeForSprints);
  const currentSprint = sprints?.items.sort(
    (a, b) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime(),
  )[0];

  const { memberId, organizationId } = useParams<{ memberId: string; organizationId: string }>();

  const { data: membership, isLoading: membershipIsLoading } = useMembership(memberId);

  const userId = membership?.user?.id || '';

  const { data: products } = useListProducts(organizationId, {
    statuses: [ProductStatus.active, ProductStatus.support],
  });

  const paramsForReports = React.useMemo(
    () =>
      activeTab === tabs.currentSprint
        ? {
            from: currentSprint?.startDate,
            to: new Date(),
            productIds,
          }
        : {
            from: addDays(new Date(currentSprint?.endDate as string), -(daysInSprint - 1)),
            to: new Date(currentSprint?.endDate as string),
            productIds,
          },
    [activeTab, productIds, currentSprint],
  );

  const {
    data: reports,
    fetchNextPage,
    isFetching,
  } = useListReports(organizationId, userId, paramsForReports, !!sprints?.items.length && !!userId, hideWeekend);

  const isFetchingPrev = usePrevious(isFetching);

  if (activeTab === TabsType.pastSprints && reports && reports?.pages?.length < 8 && isFetchingPrev && !isFetching)
    fetchNextPage();

  const { mutate: removeReport } = useRemoveReport(organizationId, userId, paramsForReports, hideWeekend);

  const { mutate: sendMembershipReminder, isLoading: sendMembershipReminderIsLoading } =
    useSendMembershipReminder(organizationId);

  const { t } = useTranslation();

  const [isAddFormOpen, toggleAddForm] = useToggleState(false);
  const [isDeleteFormOpen, toggleDeleteForm] = useToggleState(false);
  const [reportToEdit, setReportToEdit] = React.useState<ReportEntity | null>(null);
  const [selectedReport, setReportSelected] = React.useState<ReportEntity | null>(null);
  const [workDayToAdd, setWorkDayToAdd] = React.useState<string | undefined>();

  const showEditForm = (report) => {
    setReportToEdit(report);
  };
  const closeEditForm = () => {
    setReportToEdit(null);
  };

  const showAddForm = (workDay: string) => {
    setWorkDayToAdd(workDay);
    toggleAddForm();
  };
  const closeAddForm = () => {
    toggleAddForm();
    setWorkDayToAdd(undefined);
  };

  const showDeleteForm = (report) => {
    setReportSelected(report);
    toggleDeleteForm();
  };
  const closeDeleteForm = () => {
    toggleDeleteForm();
    setReportSelected(null);
  };

  const handleDeleting = () => {
    if (selectedReport) {
      removeReport(selectedReport?.id);
    }
    closeDeleteForm();
  };

  const { mutate: createReport } = useCreateReport(userId, organizationId, paramsForReports, hideWeekend);

  const handleCreateReport = (report: ReportEntity) => {
    createReport(report);
    closeAddForm();
  };

  const { mutate: updateReport } = useUpdateReport(organizationId, userId, paramsForReports, hideWeekend);

  const handleUpdateReport = (report: ReportEntity) => {
    updateReport(report);
    closeEditForm();
  };

  const handleSendMembershipReminder = () => {
    sendMembershipReminder({ id: membership?.id });
  };

  const todayDate = useMemo(() => new Date(), []);

  if (membershipIsLoading) {
    return <Loader />;
  }

  return (
    <div className={s.page}>
      <Button
        to={membersReportingListPath(organizationId)}
        className={s.pageHeaderBtnBack}
        component={Link}
        size="small"
        variant="text"
        startIcon={<ArrowBack />}
      >
        {t('actions.backToMembersReporting')}
      </Button>

      <SprintHeader
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        productIds={productIds}
        setProductIds={setProductIds}
        products={products}
        handleSendMembershipReminder={handleSendMembershipReminder}
        sendMembershipReminderIsLoading={sendMembershipReminderIsLoading}
        user={membership?.user}
      />
      {/* Fix hasMore */}
      <InfiniteScroll
        dataLength={reports?.pages?.length || 0}
        next={() => fetchNextPage()}
        hasMore={activeTab !== tabs.currentSprint}
        loader={<div />}
      >
        <div className={s.pageContent}>
          {reports?.pages
            ? reports?.pages.map((page, index) => {
                const currentSprint = sprints?.items?.find(
                  (sprint) => fullDate(sprint.startDate) === page.workDaysData[page.workDaysData.length - 1].date,
                );

                const totalHours = toFixedHours(
                  page.workDaysData.reduce((pageSum, workDayTo) => pageSum + workDayTo.totalMinutes, 0) / 60,
                );

                return activeTab === tabs.pastSprints ? (
                  <Accordion
                    key={Number(index)}
                    containerClassName={s.pageContentReportWrap}
                    className={s.pageContentAccordionTitle}
                    titleContent={
                      <div className={s.pageContentHeader}>
                        <div className={s.pageContentHeaderSection}>
                          <div className={s.pageHeaderSection}>
                            <Typography
                              className={s.pageContentTitle}
                              variant="h6"
                              fontWeight={theme.typography.fontWeightBold}
                            >
                              {t('sprint')} {currentSprint?.number}
                            </Typography>
                            {currentSprint && (
                              <Typography variant="h6" color={theme.palette.grey[600]}>
                                {monthAndDate(currentSprint?.startDate)} - {monthAndDate(currentSprint?.endDate)}
                                {activeTab === tabs.pastSprints && <>, {year(currentSprint?.endDate)}</>}
                              </Typography>
                            )}

                            <Typography>
                              <Trans
                                defaults={t('sprintTotalHours', { totalHours })}
                                components={{
                                  bold: <strong />,
                                }}
                              />
                            </Typography>
                          </div>
                        </div>
                      </div>
                    }
                  >
                    <div className={s.pageHeaderSwitchWrapper}>
                      <Switch
                        value={!hideWeekend}
                        onChange={toggleHideWeekend}
                        text={t('titles.showWeekend')}
                        className={s.switchWrapper}
                      />
                    </div>

                    {page.workDaysData.map((item) => (
                      <SprintDayReport
                        key={item.date}
                        workDay={item}
                        todayDate={todayDate}
                        showAddForm={showAddForm}
                        showEditForm={showEditForm}
                        showDeleteForm={showDeleteForm}
                      />
                    ))}
                  </Accordion>
                ) : (
                  <div key={Number(index)} className={cx(s.pageContentOpen, s.pageContentReportWrap)}>
                    <div className={s.pageContentOpenHeader}>
                      <div className={s.pageContentHeaderSection}>
                        <div className={s.pageHeaderSection}>
                          <Typography
                            className={s.pageContentTitle}
                            variant="h6"
                            fontWeight={theme.typography.fontWeightBold}
                          >
                            {t('sprint')} {currentSprint?.number}
                          </Typography>

                          {currentSprint && (
                            <Typography variant="h6" color={theme.palette.grey[600]}>
                              {monthAndDate(currentSprint?.startDate)} - {monthAndDate(currentSprint?.endDate)}
                              {activeTab === tabs.pastSprints && <>, {year(currentSprint?.endDate)}</>}
                            </Typography>
                          )}

                          <Typography>
                            <Trans
                              defaults={t('sprintTotalHours', { totalHours })}
                              components={{
                                bold: <strong />,
                              }}
                            />
                          </Typography>
                        </div>
                      </div>
                    </div>
                    <div className={s.pageHeaderSwitchWrapper}>
                      <Switch
                        value={!hideWeekend}
                        onChange={toggleHideWeekend}
                        text={t('titles.showWeekend')}
                        className={s.switchWrapper}
                      />
                    </div>

                    {page.workDaysData.map((item) => (
                      <SprintDayReport
                        key={item.date}
                        workDay={item}
                        todayDate={todayDate}
                        showAddForm={showAddForm}
                        showEditForm={showEditForm}
                        showDeleteForm={showDeleteForm}
                      />
                    ))}
                  </div>
                );
              })
            : [...new Array(10)].map((x, i) => (
                <div key={Number(i)} className={s.pageContentWorkDay}>
                  <div className={s.pageContentWorkDayHeader} />
                </div>
              ))}
        </div>
      </InfiniteScroll>

      <ReportModal
        title={t('titles.editEntry')}
        show={!!reportToEdit}
        onHide={closeEditForm}
        initialData={reportToEdit}
        onSubmit={handleUpdateReport}
        disabledDays={{ after: new Date() }}
        userId={userId}
      />

      <ReportModal
        title={t('titles.addEntry')}
        show={isAddFormOpen}
        onHide={closeAddForm}
        onSubmit={handleCreateReport}
        disabledDays={{ after: new Date() }}
        date={workDayToAdd}
        userId={userId}
      />

      <RemoveReport show={isDeleteFormOpen} onHide={closeDeleteForm} onRemove={handleDeleting} />
    </div>
  );
};

export default MemberReporting;
