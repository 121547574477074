import {
  PmMembersInfiniteData,
  MembershipEntity,
  ListResponse,
  PmMembersParams,
  UserEntity,
  MembershipStatus,
} from '@data';
import { api } from '@data/store';

export const fetchMembership = (membershipId: string): Promise<MembershipEntity> =>
  api.get(`/memberships/${membershipId}`);

export const removeMembership = (membershipId: string): Promise<void> => api.delete(`/memberships/${membershipId}`);

export const fetchPmMembersInfinite = async (
  organizationId: string,
  params: PmMembersParams,
  { pageParam },
): Promise<PmMembersInfiniteData> => {
  const newParams = pageParam ? { ...params, ...pageParam } : { ...params };

  const data = await api.get(`organizations/${organizationId}/pm/memberships`, {
    params: newParams,
  });

  return {
    sprintNumber: newParams?.sprintNumber,
    sprintId: newParams?.sprintId,
    from: newParams?.from,
    to: newParams?.to,
    items: data.items,
  };
};
export const fetchPmMembers = async (
  organizationId: string,
  params: PmMembersParams,
): Promise<ListResponse<MembershipEntity>> => {
  const data = await api.get(`organizations/${organizationId}/pm/memberships`, {
    params,
  });

  return data;
};

export const sendMembershipReminder = async (membershipId?: string): Promise<void> => {
  await api.post(`memberships/${membershipId}/reminder`);
};

export const updateUser = (userId: string, activeStatus: MembershipStatus): Promise<UserEntity> =>
  api.patch(`/users/${userId}`, { activeStatus });
