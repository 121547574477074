/* eslint-disable react/display-name */
import React from 'react';
import AuthorizedComponent from './authorized';

interface WithAuthProps {
  redirectTo?: string | null;
  preloader?: React.ReactNode | null;
  allowedRole?: string | null;
  allowedRoles?: (string | undefined)[] | null;
}

const withAuth =
  ({ redirectTo = null, preloader = null, allowedRole = null, allowedRoles = null }: WithAuthProps) =>
  (Component) =>
  (props: any): any => (
    <AuthorizedComponent
      redirectTo={redirectTo}
      preloader={preloader}
      allowedRole={allowedRole}
      allowedRoles={allowedRoles}
    >
      <Component {...props} />
    </AuthorizedComponent>
  );

export default withAuth;
