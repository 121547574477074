import Button from '@mui/lab/LoadingButton';
import { productsReportingPath } from '@app/constants/url';
import { capitalize } from '@app/utils';
import {
  IError,
  ListResponse,
  MembershipEntity,
  ProductEntity,
  SendSprintFormValues,
  SprintProductEntity,
  SprintProductParamsType,
  SprintProductStatus,
} from '@data';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UseMutateFunction } from 'react-query';
import { Link, useLocation, useParams } from 'react-router-dom';
import Inventory2Outlined from '@mui/icons-material/Inventory2Outlined';
import ArrowBack from '@mui/icons-material/ArrowBack';
import MenuBook from '@mui/icons-material/MenuBook';
import Send from '@mui/icons-material/Send';
import ShoppingCart from '@mui/icons-material/ShoppingCart';
import TaskAlt from '@mui/icons-material/TaskAlt';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import s from './styles.module.scss';
import SendSprintPopup from '../sendSprint/SendSprintPopup';

export interface ProductHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  product?: ProductEntity;
  sprintProduct?: SprintProductEntity;
  createSprintProduct: UseMutateFunction<SprintProductEntity, IError, Partial<SprintProductEntity>, unknown>;
  updateSprintProduct: UseMutateFunction<SprintProductEntity, IError, Partial<SprintProductEntity>, unknown>;
  sendSprint: UseMutateFunction<SprintProductEntity, IError, SendSprintFormValues, unknown>;
  sprintProductClients?: ListResponse<MembershipEntity>;
}

const ProductHeader = ({
  product,
  sprintProduct,
  createSprintProduct,
  updateSprintProduct,
  sendSprint,
  sprintProductClients,
}: ProductHeaderProps): ReactElement => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { state } = useLocation();
  const { organizationId, sprintId, productId } = useParams<SprintProductParamsType>();
  const [isSendModalOpen, setSendModalOpen] = useState<boolean>(false);

  const handleStatusChange = (status: SprintProductStatus) => {
    if (sprintProduct) {
      updateSprintProduct({ id: sprintProduct?.id, status });
    } else {
      createSprintProduct({ status: SprintProductStatus.closed, sprintId, productId });
    }
  };

  const closeButton = (
    <Button
      variant="contained"
      key="closeButton"
      className={s.btn}
      onClick={() => handleStatusChange(SprintProductStatus.closed)}
      endIcon={<Inventory2Outlined />}
    >
      {t('actions.close')}
    </Button>
  );

  const reopenButton = (
    <Button
      variant="outlined"
      key="reopenButton"
      className={s.btn}
      onClick={() => handleStatusChange(SprintProductStatus.open)}
      endIcon={<MenuBook />}
    >
      {t('actions.reopen')}
    </Button>
  );

  const approveButton = (
    <Button
      variant="contained"
      className={s.btn}
      key="approveButton"
      onClick={() => handleStatusChange(SprintProductStatus.approved)}
      endIcon={<TaskAlt />}
    >
      {t('actions.approve')}
    </Button>
  );

  const sendButton = (
    <Button
      variant="contained"
      className={s.btn}
      key="sendButton"
      onClick={() => setSendModalOpen(true)}
      endIcon={<Send />}
    >
      {`${t('actions.send')} (${sprintProductClients?.count || 0})`}
    </Button>
  );

  const invoiceButton = (
    <Button
      variant="contained"
      className={s.btn}
      key="invoiceButton"
      onClick={() => handleStatusChange(SprintProductStatus.invoiced)}
      endIcon={<ShoppingCart />}
    >
      {t('actions.invoice')}
    </Button>
  );

  const buttonMap = {
    [SprintProductStatus.open]: [closeButton],
    [SprintProductStatus.closed]: [reopenButton, approveButton, sendButton],
    [SprintProductStatus.approved]: [invoiceButton, sendButton],
    [SprintProductStatus.invoiced]: [sendButton],
  };

  const backBtn = (
    <Button
      to={{
        pathname: productsReportingPath(organizationId),
        state,
      }}
      className={s.backBtn}
      component={Link}
      size="small"
      variant="text"
      startIcon={<ArrowBack />}
    >
      {t('back')}
    </Button>
  );

  return (
    <div>
      <div className={s.pageHeader}>
        {backBtn}

        <div className={s.row}>
          <div className={s.rowItem}>
            <Typography variant="h6" fontWeight={theme.typography.fontWeightBold}>
              {capitalize(product?.name)}
            </Typography>

            <div className={s.rowItemStatus}>{t(capitalize(sprintProduct?.status) || SprintProductStatus.open)}</div>
          </div>
          <div className={s.rowItem}>{buttonMap[sprintProduct?.status || SprintProductStatus.open]}</div>
        </div>
      </div>

      {isSendModalOpen && (
        <SendSprintPopup
          handleClick={() => setSendModalOpen(false)}
          sendSprint={sendSprint}
          sprintProduct={sprintProduct}
          sprintProductClients={sprintProductClients?.items}
        />
      )}
    </div>
  );
};
export default ProductHeader;
