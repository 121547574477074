import { useContext, ReactElement, ReactNode } from 'react';
import { jwtDecode } from 'jwt-decode';
import { useStore } from '@data/storages';
import { useOrganizations } from '@data/hooks/organizations';
import { ACCESS_TOKEN_STORAGE_KEY } from '@app/constants/app/storage';
import { UserEntity } from '../types';
import AuthContext from './context';

interface AuthProps {
  children?: ReactElement;
  redirectTo?: string | null;
  preloader?: ReactNode;
  allowedRole?: string | null;
  allowedRoles?: (string | undefined)[] | null;
}

const userHasAccess = (allowedRole, allowedRoles) => {
  const tokenFromStorage = localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY);
  let user: UserEntity | undefined;

  if (tokenFromStorage) {
    user = jwtDecode(tokenFromStorage as string);
  }
  const { data: organizations } = useOrganizations(user?.id);
  const { organization } = useStore();
  const role = organizations?.items.find((org) => org.id === organization?.id)?.role as string;

  if (allowedRoles) return allowedRoles?.includes(role);
  return role === allowedRole;
};

const AuthorizedComponent = ({ children, redirectTo, preloader, allowedRole, allowedRoles }: AuthProps): any => {
  const { redirect, getLocation } = useContext(AuthContext);

  if (userHasAccess(allowedRole, allowedRoles)) {
    return children;
  }

  if (redirectTo) {
    redirect(redirectTo, { location: getLocation() });
  }

  return preloader;
};

export default AuthorizedComponent;
