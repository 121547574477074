import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/lab/LoadingButton';
import { capitalize } from '@app/utils';
import { ApproveSprintFormValues, SprintProductEntity, SprintProductClientEntity, validationSchemas } from '@data';
import React, { ReactElement, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { OptionsType } from '@app/utils/types';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Autocomplete, { AutocompleteChangeDetails, createFilterOptions } from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import Skeleton from '@mui/material/Skeleton';

type FormOptionType = Required<OptionsType> & { isFixed: boolean };
type ValuesType = {
  emails: FormOptionType[];
  comment?: string;
};

export interface ApprovalPopupProps extends React.HTMLAttributes<HTMLDivElement> {
  sprintProduct: Partial<SprintProductClientEntity> | Partial<SprintProductEntity>;
  onClose: () => void;
  approveSprintProduct: (values: ApproveSprintFormValues) => void;
  sprintId: string;
  productId: string;
  isLoading?: boolean;
  isApproving: boolean;
  show?: boolean;
}

export const ApprovalPopup = ({
  sprintProduct,
  onClose,
  approveSprintProduct,
  productId,
  sprintId,
  isLoading,
  isApproving,
  show = true,
}: ApprovalPopupProps): ReactElement => {
  const { t } = useTranslation();

  const onApprove = (values: ValuesType) => {
    if (approveSprintProduct) {
      approveSprintProduct({
        productId,
        sprintId,
        sprintProductId: sprintProduct?.id,
        approvalRecipients: values.emails.map((x) => x.value),
        comment: values.comment,
      });
    }
  };

  const initialValues: ValuesType = useMemo(
    () => ({
      emails: [
        ...(sprintProduct?.clients?.map((x) => ({
          label: x.user.email,
          value: x.user.email,
          isFixed: true,
        })) ?? []),
      ],
      comment: '',
    }),
    [sprintProduct.clients],
  );

  const { control, handleSubmit, reset } = useForm<ValuesType>({
    defaultValues: initialValues,
    // @ts-expect-error: no need to explicitly provide the useForm type since @hookform/resolvers v3.1.1.
    // Though, removing the type here leads to multiple TS errors below. To be checked
    resolver: yupResolver(validationSchemas.SendSprintSchema),
  });

  useEffect(() => {
    reset(initialValues);
  }, [initialValues]);

  const filter = createFilterOptions<FormOptionType>();
  return (
    <Dialog fullWidth maxWidth="sm" scroll="body" open={show} onClose={onClose}>
      <DialogTitle>
        {!isLoading ? t('doYouWantToApprove', { name: capitalize(sprintProduct?.product?.name) }) : <Skeleton />}
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onApprove)}>
          <Box mb={3}>
            <TextField
              label={`${t('noticeApprovalWillSentTo')}:`}
              fullWidth
              value={sprintProduct?.sentBy?.email}
              InputProps={{
                readOnly: true,
              }}
            />
          </Box>

          <Box mb={3}>
            <Controller
              name="emails"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Autocomplete
                  {...field}
                  multiple
                  onChange={(
                    _event,
                    newValue,
                    reason,
                    details: AutocompleteChangeDetails<FormOptionType> | undefined,
                  ) => {
                    if (reason === 'selectOption') {
                      field.onChange([
                        ...field.value,
                        { value: details?.option.value, label: details?.option.value, isFixed: false },
                      ]);
                    }
                    if (reason === 'removeOption') {
                      field.onChange(field.value.filter((x) => x.value !== details?.option.value || x.isFixed));
                    }
                    if (reason === 'clear') {
                      field.onChange(field.value.filter((x) => x.isFixed));
                    }
                    if (reason === 'createOption') {
                      const isExisting = field.value.some(
                        (option) => option.value === (details?.option as unknown as string),
                      );

                      if (isExisting) {
                        return;
                      }

                      field.onChange([
                        ...field.value,
                        { value: details?.option, label: details?.option, isFixed: false },
                      ]);
                    }
                  }}
                  filterSelectedOptions
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    const { inputValue } = params;

                    const isExisting = field.value.some((option) => inputValue === option.value);

                    if (params.inputValue !== '' && !isExisting) {
                      filtered.push({
                        value: inputValue,
                        label: `Add "${inputValue}"`,
                        isFixed: false,
                      });
                    }
                    return filtered;
                  }}
                  renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => {
                      const { onDelete, ...restTagProps } = getTagProps({ index });
                      return (
                        <Chip
                          {...restTagProps}
                          key={option.value}
                          label={option.label}
                          onDelete={option.isFixed ? undefined : onDelete}
                        />
                      );
                    })
                  }
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  options={[]}
                  freeSolo
                  disabled={isApproving || isLoading}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={!!error}
                      helperText={Array.isArray(error) ? error[0]?.value?.message : error?.message}
                      label={`${t('copyWillBeSentTo')}:`}
                    />
                  )}
                />
              )}
            />
          </Box>

          <Box mb={3}>
            <Controller
              name="comment"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  error={!!error}
                  helperText={error?.message}
                  minRows={3}
                  fullWidth
                  label={t('titles.comment')}
                  multiline
                  disabled={isApproving || isLoading}
                />
              )}
            />
          </Box>

          <Box display="flex" justifyContent="flex-end" gap={2}>
            <Button color="primary" variant="outlined" onClick={onClose} disabled={isApproving || isLoading}>
              {t('actions.cancel')}
            </Button>
            <Button variant="contained" color="primary" type="submit" loading={isApproving} disabled={isLoading}>
              {`${t('actions.approve')}`}
            </Button>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
};
