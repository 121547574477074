export enum Language {
  English = 'en',
  Dutch = 'nl',
}

export enum Namespace {
  translation = 'translation',
}

export enum PopupSize {
  small = 'small',
  medium = 'medium',
}
