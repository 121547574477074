import { fullDate } from '@app/utils';
import {
  ReportEntity,
  SprintEntity,
  WorkDayProduct,
  SprintProductChapter,
  ListResponse,
  SprintProductReportsData,
} from '@data';

export const getDaysArray = (hideWeekend: boolean, from?: string, to?: string): string[] => {
  const daysArray: string[] = [];
  const startDate = from ? new Date(fullDate(from)) : new Date();

  const endDate = to ? new Date(to) : new Date();

  for (endDate; endDate >= startDate; endDate.setDate(endDate.getDate() - 1)) {
    if (!hideWeekend || (hideWeekend && ![0, 6].includes(endDate.getUTCDay()))) {
      daysArray.push(fullDate(new Date(endDate)));
    }
  }

  return daysArray;
};

export const getDetailedReports = (
  items: ReportEntity[],
  hideWeekend: boolean,
  sprint?: SprintEntity,
): WorkDayProduct[] => {
  const daysArray = getDaysArray(hideWeekend, sprint?.startDate, sprint?.endDate);
  const daysReports: WorkDayProduct[] = daysArray.map((day) => {
    const foundReports = items.filter((report) => fullDate(report.workday) === day);
    const chapters = Array.from(new Set(items.map((x) => x.involvement.role)));
    return {
      date: day,
      chapters: chapters.map((chapter) => ({
        name: chapter,
        reports: foundReports.filter((x) => x.involvement.role === chapter),
        totalMinutes: foundReports
          .filter((x) => x.involvement.role === chapter)
          .reduce((sum, report) => sum + report.minutes, 0),
      })),
      totalMinutes: foundReports.reduce((sum, report) => sum + report.minutes, 0),
    };
  });
  return daysReports;
};

export const getBriefReports = (items: ReportEntity[]): SprintProductChapter[] =>
  items.reduce((acc: SprintProductChapter[], { involvement: { membership, role }, minutes }: ReportEntity) => {
    const sprintProductChapter = acc.find((x) => x.chapter === role);
    if (sprintProductChapter) {
      sprintProductChapter.totalMinutes += minutes;
      const member = sprintProductChapter.members.find((x) => x.member.id === membership.id);
      if (member) {
        member.totalMinutes += minutes;
      } else {
        sprintProductChapter.members.push({ member: membership, totalMinutes: minutes });
      }
    } else {
      acc.push({
        chapter: role,
        totalMinutes: minutes,
        members: [{ member: membership, totalMinutes: minutes }],
      });
    }
    return acc;
  }, []);

export const transformSprintProductReportsData = (
  hideWeekend: boolean,
  sprint?: SprintEntity,
  reports?: ListResponse<ReportEntity>,
): SprintProductReportsData => ({
  detailed: reports?.items ? getDetailedReports(reports.items, hideWeekend, sprint) : null,
  brief: reports?.items ? getBriefReports(reports?.items) : null,
});
