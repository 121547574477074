import { productReportingPath, productsReportingPath } from '@app/constants/url';
import React, { ReactElement } from 'react';
import { Route, Switch } from 'react-router-dom';
import ProductReportingContainer from './productReporting';
import ProductsContainer from './productsReportingList';

const ProductsReportingRouter = (): ReactElement => (
  <Switch>
    <Route path={productReportingPath(':organizationId', ':productId', ':sprintId')}>
      <ProductReportingContainer />
    </Route>
    <Route path={productsReportingPath(':organizationId')}>
      <ProductsContainer />
    </Route>
  </Switch>
);

export default ProductsReportingRouter;
