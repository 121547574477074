import { SprintEntity } from '@data';
import { useQuery, useQueryClient, UseQueryResult } from 'react-query';

import * as api from '../api';
import * as queryKeys from '../constants/keys';

import type {
  IError,
  ListResponse,
  ReportEntity,
  SprintProductEntity,
  SprintProductReportsParams,
} from '../utils/types';

export const useSprint = (sprintId: string): UseQueryResult<SprintEntity, IError> => {
  const queryClient = useQueryClient();
  return useQuery([queryKeys.SPRINT, sprintId], () => api.fetchSprint(sprintId), {
    staleTime: 1000,
    placeholderData: () =>
      queryClient
        .getQueryData<ListResponse<SprintEntity>>(queryKeys.SPRINTS)
        ?.items.find((sprint) => sprint.id === sprintId),
  });
};

export const useListSprints = (params?: {
  from?: string | Date;
  to?: string | Date;
  offset?: number;
  limit?: number;
}): UseQueryResult<{ items: SprintEntity[]; count: number }, IError> =>
  useQuery([queryKeys.SPRINTS, params], () => api.fetchSprints(params));

export const useSprintProductReports = (
  params: SprintProductReportsParams,
): UseQueryResult<ListResponse<ReportEntity>, IError> =>
  useQuery([queryKeys.PM_SPRINT_PRODUCT_REPORTS, params], () => api.fetchSprintProductReports(params), {
    enabled: !!params.sprint,
  });

export const useListSprintProducts = (params: {
  sprintId: string | undefined;
  productId: string | undefined;
}): UseQueryResult<ListResponse<SprintProductEntity>, IError> =>
  useQuery([queryKeys.PM_SPRINT_PRODUCTS, params], () => api.fetchSprintProducts(params), {
    enabled: !!params.productId && !!params.sprintId,
  });
