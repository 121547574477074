import { SprintProductClientEntity, ClientReportWithProductsEntity } from '@data';

export const addProductsToClientEntityFn = (
  arr: ClientReportWithProductsEntity[],
  curr: SprintProductClientEntity,
): void => {
  const sameSprintReport = arr.find((item) => curr.sprint.id === item.sprint.id);

  if (sameSprintReport) {
    // we aren't mutating array because we create it in a place where we call function
    sameSprintReport.products.push(curr.product);
  } else {
    arr.push({ ...curr, products: [curr.product] });
  }
};
