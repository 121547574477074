import React from 'react';
import { ApprovalPopup } from '@app/components/core';
import cx from 'classnames';
import { clientReportingPath } from '@app/constants/url';
import { formatDateString } from '@app/utils';
import {
  SprintProductClientEntity,
  ClientProductEntity,
  useApproveSprintProduct,
  useListSprintProducts,
  ApproveSprintFormValues,
} from '@data';
import { Language } from '@app/constants/enums';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import s from './styles.module.scss';

type ClientReportEntity = SprintProductClientEntity & { products: ClientProductEntity[] };

const Row = ({ children, handleAction }: { children: React.ReactNode; handleAction: () => void }) => (
  <div className={s.row} role="button" tabIndex={0} onClick={handleAction} onKeyPress={handleAction}>
    {children}
  </div>
);

const ClientReportCard = ({
  report,
  isNew,
  organizationId,
}: {
  report: ClientReportEntity;
  isNew?: boolean;
  organizationId?: string;
}): JSX.Element => {
  const theme = useTheme();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { sprint, products } = report;
  const [sprintProductInfo, setApproveInfo] = React.useState<null | ClientProductEntity>(null);
  const { mutate: approveSprintProduct, isLoading: isApproving } = useApproveSprintProduct();
  const resetApproveInfo = () => setApproveInfo(null);

  const handleApproveSprintProduct = (values: ApproveSprintFormValues) => {
    approveSprintProduct(values, {
      onSuccess: () => {
        resetApproveInfo();
      },
    });
  };

  const { data: sprintProduct, isLoading } = useListSprintProducts({
    sprintId: sprint.id,
    productId: sprintProductInfo?.id,
  });

  const history = useHistory();

  const approveAction = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent> | React.KeyboardEvent<HTMLDivElement>,
    p: ClientProductEntity,
  ) => {
    e.stopPropagation();
    setApproveInfo(p);
  };

  return (
    <>
      <div className={cx(s.card, isNew && s.cardNew)}>
        {products.map((p, index) => {
          const productItem = (
            <div className={s.rowItem}>
              <Link className={s.product} to={clientReportingPath(organizationId, p.id, sprint.id)}>
                {p?.name}
              </Link>
              {isNew ? (
                <div
                  className={s.approveBtn}
                  key={`approve-${p.id}`}
                  onKeyPress={(e) => approveAction(e, p)}
                  onClick={(e) => approveAction(e, p)}
                  role="button"
                  tabIndex={0}
                >
                  {t('approve')}
                </div>
              ) : (
                <div key={`empty-${p.id}`} />
              )}
            </div>
          );

          const handleAction = () => {
            history.push(clientReportingPath(organizationId, p.id, sprint.id));
          };

          const sprintItem = (
            <div className={s.rowItem}>
              <div className={s.cardHeader}>
                <Typography fontWeight={theme.typography.fontWeightBold}>
                  {`${t('sprint')} ${sprint.number}`}
                </Typography>
                {isNew && <Typography className={s.cardHeaderFlag}>{t('new')}</Typography>}
              </div>

              <div className={s.cardSprintDuration}>
                {`${formatDateString(sprint.startDate, language as Language, 'dd MMM')} -
                  ${formatDateString(sprint.endDate, language as Language, 'dd MMM yyyy')}`}
              </div>
            </div>
          );

          if (index === 0) {
            return (
              <Row key={`${p.id}-${sprint.id}`} handleAction={handleAction}>
                {sprintItem}
                {productItem}
              </Row>
            );
          }

          return (
            <Row key={`${p.id}-${sprint.id}`} handleAction={handleAction}>
              <div className={s.rowEmptyItem} />
              {productItem}
            </Row>
          );
        })}
      </div>
      {sprintProductInfo && (
        <ApprovalPopup
          approveSprintProduct={handleApproveSprintProduct}
          sprintProduct={{ ...sprintProduct?.items[0] }}
          productId={sprintProductInfo.id}
          sprintId={sprint.id}
          onClose={resetApproveInfo}
          isLoading={isLoading}
          isApproving={isApproving}
        />
      )}
    </>
  );
};

export default ClientReportCard;
