import { organizationsPath, organizationPath } from '@app/constants/url';
import { UserEntity } from '@data';
import React, { ReactElement } from 'react';
import { Route, Switch } from 'react-router-dom';
import { OrganizationsInit } from './init';
import Organization from './organization';

export interface OrganizationsProps {
  user: UserEntity;
}

const Organizations = ({ user }: OrganizationsProps): ReactElement => (
  <Switch>
    <Route path={organizationPath(':organizationId')}>
      <Organization user={user} />
    </Route>
    <Route path={organizationsPath}>
      <OrganizationsInit user={user} />
    </Route>
  </Switch>
);

export default Organizations;
