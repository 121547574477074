export enum Roles {
  admin = 'admin',
  productManager = 'productManager',
  teamMember = 'teamMember',
  client = 'client',
}

export type OptionsType = { value?: string; label?: string };

export enum TabsType {
  pastSprints = 'PAST-SPRINTS',
  currentSprint = 'CURRENT-SPRINT',
}

export type TabType = TabsType.currentSprint | TabsType.pastSprints;

export enum ProductTabsType {
  brief = 'BRIEF',
  detailed = 'DETAILED',
}

export enum ReportTabsType {
  new = 'NEW',
  approved = 'APPROVED',
}

export type TabNavState = {
  activeTab: TabsType;
};

export interface ProductsReportingNavState extends TabNavState {
  productsFilter: string[];
  chapterFilter: string;
  statusFilter: string;
}

export type ReportTabNavState = {
  activeTab: ReportTabsType;
};

export enum TimeValueType {
  fullDay = 8,
  halfDay = 4,
}
