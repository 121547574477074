import { useState } from 'react';
import { ACCESS_TOKEN_STORAGE_KEY } from '@app/constants/app/storage';

export const useAISummary = () => {
  const [aiSummary, setAISummary] = useState<string>('');
  const [isAISummaryGenerating, setIsAISummaryGenerating] = useState<boolean>(false);

  const generateAISummary = async (sprintProductId: string): Promise<void> => {
    const token = localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY);
    if (!token) return;

    setAISummary('');
    setIsAISummaryGenerating(true);

    const response = await fetch(`${import.meta.env.VITE_API_BASE_URL}/sprintproducts/${sprintProductId}/summary`, {
      method: 'get',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        'X-Auth': token,
      },
    });

    if (!response.ok || !response.body) throw response.statusText;

    const reader = response.body.getReader();
    const decoder = new TextDecoder();
    const loopRunner = true;

    while (loopRunner) {
      // eslint-disable-next-line no-await-in-loop
      const { value, done } = await reader.read();
      if (done) {
        setIsAISummaryGenerating(false);
        break;
      }
      const decodedChunk = decoder.decode(value, { stream: true });
      setAISummary((summary) => summary + decodedChunk);
    }
  };

  return { aiSummary, generateAISummary, isAISummaryGenerating };
};
