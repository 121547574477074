import { clientReportingPath, clientsReportingPath } from '@app/constants/url';
import React, { ReactElement } from 'react';
import { Route, Switch } from 'react-router-dom';

import SprintProductContainer from './sprintProduct';
import SprintProductsContainer from './sprintProducts';

const ClientReportingRouter = (): ReactElement => (
  <Switch>
    <Route exact path={clientReportingPath(':organizationId', ':productId', ':sprintId')}>
      <SprintProductContainer />
    </Route>
    <Route path={clientsReportingPath(':organizationId')}>
      <SprintProductsContainer />
    </Route>
  </Switch>
);

export default ClientReportingRouter;
