import { apiStore } from '@data';
import * as Sentry from '@sentry/browser';
import React, { ReactElement, useCallback, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { debounce } from './helpers/debounce';

const NotificationProvider = (): ReactElement => {
  const dispatchErrorNotification = useCallback(() => {
    const { error } = apiStore.getState();
    Sentry.captureException(error?.detail);
    toast.error(error?.detail);
  }, []);

  const debounceSuccess = debounce(toast.success, 1000);

  const dispatchSuccessNotification = useCallback(() => {
    const { success } = apiStore.getState();
    debounceSuccess(success?.message);
  }, []);

  useEffect(() => {
    const unsubFromError = apiStore.subscribe(dispatchErrorNotification, (state) => state.error);

    const unsubFromSuccess = apiStore.subscribe(dispatchSuccessNotification, (state) => state.success);

    return () => {
      unsubFromError();
      unsubFromSuccess();
      apiStore.destroy();
    };
  }, []);

  return <ToastContainer style={{ marginTop: 50 }} position="bottom-right" closeOnClick theme="light" />;
};

export default NotificationProvider;
