import { SprintProductEntity, api } from '@data';
import {
  ApproveSprintFormValues,
  ListResponse,
  MembershipEntity,
  SendSprintFormValues,
  SprintProductStatus,
  SprintProductClientEntity,
  ClientProductEntity,
} from '@data/utils/types';

export const createSprintProduct = (
  organizationId: string,
  values: Partial<SprintProductEntity>,
): Promise<SprintProductEntity> => api.post('/sprintproducts', values);

export const updateSprintProduct = (values: Partial<SprintProductEntity>): Promise<SprintProductEntity> =>
  api.patch(`/sprintproducts/${values.id}`, values);

export const sendSprintProduct = (values: SendSprintFormValues): Promise<SprintProductEntity> =>
  api.patch(`/sprintproducts/${values.sprintProductId}/send`, values);

export const approveSprintProduct = (values?: ApproveSprintFormValues): Promise<ClientProductEntity> =>
  api.patch(`/sprintproducts/${values?.sprintProductId}/approve`, values);

export const fetchClientSprintProducts = (
  organizationId?: string,
  params?: {
    statuses?: Partial<SprintProductStatus>[];
  },
): Promise<ListResponse<SprintProductClientEntity>> =>
  api.get(`/organizations/${organizationId}/client/sprintproducts`, {
    params,
  });

export const fetchSprintProductClients = (sprintProductId?: string): Promise<ListResponse<MembershipEntity>> =>
  api.get(`/sprintproducts/${sprintProductId}/clients`);

export const fetchSprintProductClientUrl = (sprintProductId?: string, clientId?: string): Promise<{ url: string }> =>
  api.get(`/sprintproducts/${sprintProductId}/clients/${clientId}/url`);

export const removeSprintProductClient = (sprintProductId?: string, clientId?: string): Promise<void> =>
  api.delete(`/sprintproducts/${sprintProductId}/clients/${clientId}`);

export const resendSprintProduct = (sprintId?: string, productId?: string): Promise<void> =>
  api.patch(`/sprints/${sprintId}/products/${productId}/resend`);
