import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Button from '@mui/lab/LoadingButton';
import { debounce } from '@app/utils/helpers/debounce';
import { Roles } from '@app/utils/types';
import { MembershipStatus, OrganizationParamsType, PmMembersParams } from '@data';
import { useListPmMembers, useUpdatePmMembers } from '@data/hooks/membership';
import { useOrganization, useOrganizationChapters } from '@data/hooks/organization';
import React, { ReactElement, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Search, Inventory2Outlined, TaskAlt } from '@mui/icons-material';
import Stack from '@mui/material/Stack';
import { membershipTabs } from '@app/constants/app';

import MembersListHeader from './MembersListHeader';
import { MembersProps } from '../MembersRouter';

const MembersList = ({ user }: MembersProps): ReactElement => {
  const theme = useTheme();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const { organizationId } = useParams<OrganizationParamsType>();
  const [search, setSearch] = useState<string | undefined>();
  const [activeTab, setActiveTab] = React.useState<MembershipStatus>(
    (params.get('tab') as MembershipStatus) || membershipTabs[0],
  );

  const { data: chapters } = useOrganizationChapters(organizationId);
  const { data: organization } = useOrganization(organizationId);
  const isAdmin = organization?.memberships?.find((m) => m.id === user.id)?.role === Roles.admin;

  const membersPmParams: PmMembersParams = {
    search,
    userStatus: activeTab,
  };

  const { data: pmMembers, isLoading: pmMembersIsLoading } = useListPmMembers(organizationId, membersPmParams);
  const { mutate: updateUser } = useUpdatePmMembers(organizationId, membersPmParams);

  const { t } = useTranslation();

  const columns: GridColDef[] = [
    {
      field: 'member',
      headerName: t('member'),
      flex: 3,
    },
    {
      field: 'chapters',
      headerName: t('chapters'),
      flex: 5,
    },
    {
      field: 'product',
      headerName: `# ${t('products')}`,
      flex: 2,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      sortable: false,
      flex: 1,
      minWidth: 180,
      renderCell: (params) => {
        const handleClick = (newStatus: MembershipStatus) => () =>
          updateUser({
            userId: params.row.user.id,
            activeStatus: newStatus,
          });
        return activeTab === MembershipStatus.active ? (
          <Button
            size="small"
            startIcon={<Inventory2Outlined />}
            onClick={handleClick(MembershipStatus.deactivated)}
            disabled={!isAdmin}
          >
            {t('actions.deactivate')}
          </Button>
        ) : (
          <Button
            size="small"
            startIcon={<TaskAlt />}
            onClick={handleClick(MembershipStatus.active)}
            disabled={!isAdmin}
          >
            {t('actions.activate')}
          </Button>
        );
      },
    },
  ];

  const rows = useMemo(
    () =>
      pmMembers?.items
        .filter((x) => x.role !== Roles.client && x.user.status === activeTab)
        .map((x) => ({
          ...x,
          member: `${x.user.firstName || ''} ${x.user.lastName || ''}`,
          product: x.productsCount,
          chapters: x.chapters.map((chapter) => chapters?.items?.find((x) => x.role === chapter)?.text).join(', '),
        })) || [],
    [pmMembers, chapters, activeTab],
  );

  const debouncedSetSearch = debounce(setSearch, 500);

  return (
    <>
      <MembersListHeader tabs={membershipTabs} activeTab={activeTab} setActiveTab={setActiveTab} />

      <Box marginBottom={2} maxWidth={400}>
        <TextField
          label={t('search')}
          placeholder={t('search')}
          InputProps={{
            startAdornment: <Search htmlColor={theme.palette.grey[500]} />,
          }}
          onChange={(e) => debouncedSetSearch(e.target.value)}
        />
      </Box>

      <Box marginBottom={3}>
        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
          <TableContainer component={Paper}>
            <DataGrid
              loading={pmMembersIsLoading}
              rows={rows}
              columns={columns}
              autoHeight
              paginationModel={{ page: 0, pageSize: rows.length }}
              disableColumnFilter
              disableColumnMenu
              disableColumnSelector
              disableDensitySelector
              disableRowSelectionOnClick
              initialState={{
                sorting: {
                  sortModel: [{ field: 'member', sort: 'asc' }],
                },
              }}
            />
          </TableContainer>
        </Box>
        <Box sx={{ display: { xs: 'block', md: 'none' } }}>
          <Stack spacing={2}>
            {pmMembers?.items
              ?.filter((x) => x.role !== Roles.client)
              ?.map((x) => (
                <Paper key={x.id}>
                  <Box padding={2} bgcolor={theme.palette.grey[100]}>
                    <Typography variant="h6" fontWeight={theme.typography.fontWeightBold}>
                      {`${x.user.firstName || ''} ${x.user.lastName || ''}`}
                    </Typography>
                    <Typography variant="body1">{`# ${t('products')}: ${x.productsCount}`}</Typography>
                    <Typography variant="body1">
                      {x.chapters.map((chapter) => chapters?.items?.find((x) => x.role === chapter)?.text).join(', ')}
                    </Typography>
                  </Box>
                </Paper>
              ))}
          </Stack>
        </Box>
      </Box>
    </>
  );
};

export default MembersList;
