import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { copyToClipboard } from '@app/utils';
import {
  IError,
  MembershipEntity,
  SendSprintFormValues,
  SprintProductEntity,
  useListProductClients,
  useRemoveSprintProductClient,
  validationSchemas,
} from '@data';
import * as api from '@data/api';
import { Formik, FormikHelpers } from 'formik';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UseMutateFunction } from 'react-query';
import cx from 'classnames';
import { toast } from 'react-toastify';
import Close from '@mui/icons-material/Close';
import ContentCopy from '@mui/icons-material/ContentCopy';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';
import s from './styles.module.scss';
import SendSprintForm from './SendSprintForm';

export interface SendSprintProps extends React.HTMLAttributes<HTMLDivElement> {
  handleClick: (close: boolean) => void;
  sendSprint: UseMutateFunction<SprintProductEntity, IError, SendSprintFormValues, unknown>;
  sprintProduct?: SprintProductEntity;
  sprintProductClients?: MembershipEntity[];
}

const copyClientSprintProductUrl = async (sprintProductId?: string, clientId?: string) => {
  const { url } = await api.fetchSprintProductClientUrl(sprintProductId, clientId);
  copyToClipboard(url)
    .then(() => {
      toast.success('Successfully copied to clipboard!');
    })
    .catch(() => {
      toast.error('Something went wrong!');
    });
};

const SendSprintPopup = ({
  handleClick,
  sendSprint,
  sprintProduct,
  sprintProductClients,
}: SendSprintProps): ReactElement => {
  const { t } = useTranslation();

  const { data: productClients } = useListProductClients(sprintProduct?.product?.id);

  const { mutate: removeClient } = useRemoveSprintProductClient(sprintProduct?.id);

  const [checkedEmails, setCheckedEmails] = useState<string[]>([]);

  const [newEmails, setNewEmails] = useState<string[]>([]);

  const updateCheckedEmails = (email: string) =>
    setCheckedEmails(
      checkedEmails.includes(email) ? checkedEmails.filter((x) => x !== email) : checkedEmails.concat(email),
    );

  const addCheckedEmails = (emails: string[]) => setCheckedEmails(checkedEmails.concat(emails));
  const removeCheckedEmails = (emails: string[]) => setCheckedEmails(checkedEmails.filter((x) => !emails.includes(x)));

  const onSubmit = (values, actions: FormikHelpers<SendSprintFormValues>) => {
    const emails = values.emails.map((x) => x.value);
    setNewEmails((newEmails) => newEmails.concat(emails));
    addCheckedEmails(emails);
    actions.resetForm();
  };

  const onSend = () => {
    sendSprint({ emails: checkedEmails, sprintProductId: sprintProduct?.id });
    handleClick(false);
  };

  const onRemove = (client: MembershipEntity) => {
    removeCheckedEmails([client.user.email]);
    if (client.id) {
      removeClient(client);
    } else {
      setNewEmails(newEmails.filter((email) => email !== client.user.email));
    }
  };

  const renderEmails = (clients) =>
    clients.map((x) => (
      <div key={x.user.email} className={s.popupClient}>
        <div>
          <Checkbox
            checked={checkedEmails?.includes(x.user.email)}
            onChange={() => updateCheckedEmails(x.user.email)}
          />
        </div>
        <div className={s.popupClientEmail}>{x.user.email}</div>
        <div className={s.popupClientActions}>
          <IconButton
            onClick={() => copyClientSprintProductUrl(sprintProduct?.id, x.id)}
            className={cx(!x.id && s.popupHidden)}
          >
            <ContentCopy />
          </IconButton>

          <IconButton onClick={() => onRemove(x)}>
            <Close />
          </IconButton>
        </div>
      </div>
    ));

  return (
    <Dialog fullWidth open scroll="body" onClose={() => handleClick(false)} title={t('titles.sendReport')}>
      <DialogTitle>{t('titles.sendReport')}</DialogTitle>
      <DialogContent>
        <div className={s.popupClients}>
          {sprintProductClients && renderEmails(sprintProductClients)}
          {newEmails && renderEmails(newEmails.map((email) => ({ user: { email } })))}
        </div>
        <Formik
          enableReinitialize
          onSubmit={onSubmit}
          validationSchema={validationSchemas.SendSprintSchema}
          initialValues={{ sprintProductId: sprintProduct?.id, emails: [] }}
        >
          {(formikProps) => (
            <SendSprintForm
              handleClick={() => handleClick(false)}
              clients={productClients?.items.filter((x) => !sprintProductClients?.some((y) => y.id === x.id))}
              checkedEmails={checkedEmails}
              onSend={onSend}
              {...formikProps}
            />
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default SendSprintPopup;
