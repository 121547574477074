import './i18n';
import 'react-toastify/dist/ReactToastify.css';
import './styles/global.module.scss';
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import { GoogleOAuthProvider } from '@react-oauth/google';

import { StyledEngineProvider, ThemeProvider as MUIThemeProvider } from '@mui/material/styles';
import { theme } from '@ui/utils/MUItheme';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';

import * as Sentry from '@sentry/browser';
import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Router } from 'react-router-dom';
import { Loader } from '@ui';
import TagManager from 'react-gtm-module';

import PanencoBot from './components';
import history from './history';
import NotificationProvider from './utils/notification-provider';
import AuthProvider from './utils/autorized-provider';

const rootElement = createRoot(document.getElementById('root') as HTMLElement);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: () => false,
    },
  },
});

if (import.meta.env.MODE && ['production', 'staging'].includes(import.meta.env.MODE)) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.MODE,
  });

  TagManager.initialize({ gtmId: import.meta.env.VITE_GTM_ID });
}

const App = () => (
  <QueryClientProvider client={queryClient}>
    <Router history={history}>
      <AuthProvider>
        <Suspense fallback={<Loader />}>
          <StyledEngineProvider injectFirst>
            <MUIThemeProvider theme={theme}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <GoogleOAuthProvider clientId={import.meta.env.VITE_GOOGLE_OAUTH_CLIENT_ID}>
                  <PanencoBot />
                  <NotificationProvider />
                </GoogleOAuthProvider>
              </LocalizationProvider>
            </MUIThemeProvider>
          </StyledEngineProvider>
        </Suspense>
      </AuthProvider>
    </Router>
    <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
  </QueryClientProvider>
);

rootElement.render(<App />);
