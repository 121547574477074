import { api } from '@data/store';
import { OrganizationEntity } from '@data';

export const fetchOrganizations = (
  userId?: string,
  params?: {
    from?: string;
    to?: string;
    offset?: number;
    limit?: number;
  },
): Promise<{ items: OrganizationEntity[]; count: number }> =>
  api.get(`/users/${userId}/organizations`, {
    params,
  });

export const createOrganization = (values: Partial<OrganizationEntity>): Promise<OrganizationEntity> =>
  api.post('/organizations', values);

export const leaveOrganization = (userId?: string, organizationId?: string): Promise<void> =>
  api.delete(`/organizations/${organizationId}/users/${userId}`);
