import { fullDate } from '@app/utils';
import { getDaysArray } from '@data/api/utils/helpers';
import { ReportEntity, WorkDayUserEntity } from './types';

export const transformUserReportsData = (
  reports: ReportEntity[],
  { from, to }: { from?: string; to?: string },
  hideWeekend: boolean,
): WorkDayUserEntity[] => {
  const daysArray = getDaysArray(hideWeekend, from, to);
  const daysReports = daysArray.map((day) => {
    const foundReports = reports.filter((report) => fullDate(report.workday) === day);
    return {
      date: day,
      reports: foundReports,
      totalMinutes: foundReports.reduce((sum, report) => sum + report.minutes, 0),
    };
  });
  return daysReports;
};
