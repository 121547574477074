import { format } from 'date-fns';
import { Language } from '@app/constants/enums';
import { dateFnsLocaleMap } from '@app/constants/app';

export const fullDate = (date) =>
  format(new Date(new Date(date).valueOf() + new Date(date).getTimezoneOffset() * 60 * 1000), 'yyyy-MM-dd');
export const shortDate = (date) =>
  format(new Date(new Date(date).valueOf() + new Date(date).getTimezoneOffset() * 60 * 1000), 'eee, dd MMMM');
export const monthAndDate = (date) => format(new Date(date), 'dd MMMM');
export const year = (date) => format(new Date(date), 'yyyy');

export const renderDate = (date: Date | string) => {
  const today = shortDate(new Date());
  const yesterday = shortDate(new Date(Date.now() - 24 * 60 * 60 * 1000));
  const propDate = shortDate(date);

  if (propDate === today) return `Today, ${monthAndDate(date)}`;
  if (propDate === yesterday) return `Yesterday, ${monthAndDate(date)}`;
  return propDate;
};

export const toFixedHours = (hours: number): number => (hours % 1 === 0 ? hours : Number(hours.toFixed(2)));
export const hoursToMinutes = (minutes?: number): number => (minutes || 0) * 60 - (((minutes || 0) * 60) % 1) || 0;

export const addDays = (date: Date, days: number): Date => {
  if (days === 0) {
    return date;
  }
  const copy = new Date(Number(date));
  copy.setDate(date.getDate() + days);
  return copy;
};

export const formatDateString = (
  date: string | number | Date | undefined | null,
  language: Language,
  dateFormat?: string,
): string => {
  if (!date) return '';

  return format(date instanceof Date ? date : new Date(date), dateFormat || 'dd-MMM-yyyy', {
    locale: dateFnsLocaleMap[language],
  });
};
